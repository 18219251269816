import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useAppContext } from "../../lib/contextLib";
import { Colors } from '../../lib/colors';
import {
    getVendorName,
    getCatName,
    getSupplierName
} from "../../services/shared";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SearchAppBar({ type, onChange, data, color = Colors.gold}) {
    const {
        vendors,
        productCategories,
        suppliers
    } = useAppContext();

    function handleInputChange(event) {
        let { name, value } = event.target;
        let query = value.toLowerCase();
        if (type == 'orders') {

            const newOrders = data.filter(element => {
                return element.orderNumber.toString().includes(query)
                    || element.orderStatus && element.orderStatus.toLowerCase().includes(query)
                    || element.fullName && element.fullName.toLowerCase().includes(query)
                    || element.phoneNumber && element.phoneNumber.toLowerCase().includes(query);
            });

            onChange(newOrders);//
        } else if (type == 'products') {//products
            const newProducts = data.filter(element => {
                return element.productName && element.productName.toLowerCase().includes(query)
                    || element.blNumber && element.blNumber.toLowerCase().includes(query)
                    || element.productOrigin && element.productOrigin.toLowerCase().includes(query)
                    || element.receivedDate && element.receivedDate.toLowerCase().includes(query)
                    || element.productBarCode && element.productBarCode.toLowerCase().includes(query)
                    || element.productSKU && element.productSKU.toLowerCase().includes(query)
                    || element.productDescription && element.productDescription.toLowerCase().includes(query)
                    || element.vendorId && getVendorName(vendors, element.vendorId).toLocaleLowerCase().includes(query)
                    || element.categoryId && getCatName(productCategories, element.categoryId).toLocaleLowerCase().includes(query);
            });

            onChange(newProducts);
        }else if (type == 'pendingProducts') {//pending products
            const newProducts = data.filter(element => {
                return element.product.productName && element.product.productName.toLowerCase().includes(query)
                    || element.dispatch.blNumber && element.dispatch.blNumber.toLowerCase().includes(query)
                    || element.dispatch.status && element.dispatch.status.toLowerCase().includes(query)
                    || element.dispatch.quantity && element.dispatch.quantity.toLowerCase().includes(query)
                    || element.product.productBarCode && element.product.productBarCode.toLowerCase().includes(query)
                    || element.product.productSKU && element.product.productSKU.toLowerCase().includes(query)
                    || element.product.productDescription && element.product.productDescription.toLowerCase().includes(query)
                    || element.product.vendorId && getVendorName(vendors, element.vendorId).toLocaleLowerCase().includes(query)
                    || element.product.categoryId && getCatName(productCategories, element.categoryId).toLocaleLowerCase().includes(query);
            });

            onChange(newProducts);
        }else if (type == 'productsReceived') {
            const newProductsReceived = data.filter(element => {
                return element.receiveStatus && element.receiveStatus.toLowerCase().includes(query)
                    || element.packagingNumber && element.packagingNumber.toLowerCase().includes(query)
                    || element.receivedDate && element.receivedDate.toLowerCase().includes(query)
                    || element.productDescription && element.productDescription.toLowerCase().includes(query)
                    || element.productOrigin && getSupplierName(suppliers, element.productOrigin).toLocaleLowerCase().includes(query);

            });

            onChange(newProductsReceived);
        } else if (type == 'productsDispatch') {
            const newProducts = data.filter(element => {
                return element.productName && element.productName.toLowerCase().includes(query)
                    || element.blNumber && element.blNumber.toLowerCase().includes(query)
                    || element.receivedDate && element.receivedDate.toLowerCase().includes(query)
                    || element.productDescription && element.productDescription.toLowerCase().includes(query)
                    || element.vendorId && getVendorName(vendors, element.vendorId).toLocaleLowerCase().includes(query)
                    || element.categoryId && getCatName(productCategories, element.categoryId).toLocaleLowerCase().includes(query);
            });

            onChange(newProducts);
        } else if (type == 'customers') {
            const customers = data.filter(element => {
                return element.fullName && element.fullName.toLowerCase().includes(query)
                    || element.phoneNumber && element.phoneNumber.toLowerCase().includes(query)
                    || element.email && element.email.toLowerCase().includes(query)
            });

            onChange(customers);
        }else if (type == 'users') {
            const users = data.filter(element => {
                return element.fullName && element.fullName.toLowerCase().includes(query)
                    || element.phoneNumber && element.phoneNumber.toLowerCase().includes(query)
                    || element.email && element.email.toLowerCase().includes(query)
            });

            onChange(users);
        }else if (type == 'roles') {
            const users = data.filter(element => {
                return element.roleName && element.roleName.toLowerCase().includes(query)
                    || element.shortDescription && element.shortDescription.toLowerCase().includes(query)
            });

            onChange(users);
        }else if (type == 'permissions') {
            const permissions = data.filter(element => {
                return element.permissionName && element.permissionName.toLowerCase().includes(query)
                    || element.permissionValue && element.permissionValue.toLowerCase().includes(query)
                    || element.permissionDesc && element.permissionDesc.toLowerCase().includes(query)
            });

            onChange(permissions);
        }else if (type == 'suppliers') {
            const suppliers = data.filter(element => {
                return element.supplierName && element.supplierName.toLowerCase().includes(query)
                    || element.email && element.email.toLowerCase().includes(query)
                    || element.phoneNumber && element.phoneNumber.toLowerCase().includes(query)
            });

            onChange(suppliers);
        }else if (type == 'accounts') {
            const bankAccounts = data.filter(element => {
                return element.bankCode && element.bankCode.toLowerCase().includes(query)
                    || element.agencyCode && element.agencyCode.toLowerCase().includes(query)
                    || element.accountNumber && element.accountNumber.toLowerCase().includes(query)
                    || element.accountName && element.accountName.toLowerCase().includes(query)
                    || element.ribKey && element.ribKey.toLowerCase().includes(query)
                    || element.swiftAddress && element.swiftAddress.toLowerCase().includes(query)
                    || element.bankName && element.bankName.toLowerCase().includes(query)
                    || element.bankPhoneNumber && element.bankPhoneNumber.toLowerCase().includes(query)
                    || element.bankEmail && element.bankEmail.toLowerCase().includes(query)
                    || element.bankContactPerson && element.bankContactPerson.toLowerCase().includes(query)
                    
            });

            onChange(bankAccounts);
        }else if (type == 'spendingCats') {
            const spendingCats = data.filter(element => {
                return element.spendingCategoryName && element.spendingCategoryName.toLowerCase().includes(query)
                    || element.spendingCategoryNote && element.spendingCategoryNote.toLowerCase().includes(query)
            });

            onChange(spendingCats);
        } else if (type == 'deposits') {
            const newData = data.filter(element => {
                return element.vendorId && getVendorName(vendors, element.vendorId).toLocaleLowerCase().includes(query);
            });
            onChange(newData);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }} style={{ background: color }}>

            <Toolbar>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        name={'search'}
                        onChange={handleInputChange}
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Toolbar>

        </Box>
    );
}
