import { API } from "aws-amplify";

/**
* Function for getting a Setting
* @param {function} getSetting
*/
export const getSetting = async () => {

    let response;
//dev-a1-vendors-AdminsTB_avp_v1

    try {

        response = await API.get("adminAPI", `/admin/settings/${'general'}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a Setting
* @param {function} updateSetting
*/
export const updateSetting = async (data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/settings/${'general'}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a Setting
* @param {object} data  
* @param {function} createSettings
*/
export const createSettings = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/settings", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for reseting data
* @param {function} resetSytemData
*/
export const resetSytemData = async () => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/settings/reset_data`);

    } catch (err) {
        throw 'Cannot connect';
    }

    console.log('response',response);

    return response;
};