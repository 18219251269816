import { API } from "aws-amplify";
import {
    updateSetting,
    getSetting
} from "./settings";
import {
    updateProduct,
    getProduct
} from "./products";
import {
    getAllVendorDeposits,
} from "./deposits";
import {
    getAllVendorSpendings,
} from "./spendings";
import {
    updateDispatch,
} from "./products_dispatches";
import {
    getVendor,
    updateVendor
} from "./vendors";

var moment = require('moment');
/**
* Function for getting all orders 
* @param {function} getAllOrders
*/
export const getAllOrders = async (companyId) => {

    let response;

    try {
        //add cid
        response = await API.get("adminAPI", "/admin/orders/" + companyId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove order
* @param {function} removeOrder
*/
export const removeOrder = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/orders/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting last order
* @param {function} getLastOrder
*/
export const getLastOrder = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/orders/get_last_order");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for getting a order
* @param {function} getOrder
*/
export const getOrder = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/order/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting vendor order
* @param {function} getVendorOrders
*/
export const getVendorOrders = async (id) => {

    let response = {
        statistics: null,
        orders: null,
        cashierData: null,
        ordersToday: null
    };

    try {

        let resData = await API.get("adminAPI", `/admin/orders/vendors/${id}`);
        let orders = resData.orders;
        let statistics = await calculateOrderStatistics(orders);
        let deposits = await getAllVendorDeposits(id);
        let spendings = await getAllVendorSpendings(id);
        let cashierData = await getVendorCashierData(orders, deposits, spendings);
        let ordersToday = await getVendorTodayOrders(orders);

        cashierData.customerCredits = resData.customerCredits;
        response.orders = orders;
        response.statistics = statistics;
        response.cashierData = cashierData;
        response.ordersToday = ordersToday;

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for getting vendor order
* @param {function} getVendorCashierData
*/
export const getVendorCashierData = async (orders, deposits, spendings) => {

    let response = {
        cashierOrders: null,
        vendorDeposits: deposits,
        vendorSpendings: spendings,
        customerCredits: null,
        counts: {
            total_amount: 0,
            payment_total: 0,
            deposit_total: 0,
            spending_total: 0,
            cashier_balance: 0,
            total_credit: 0,
            shipping_total: 0
        }
    };
    //Things to be subtracted from total
    let totalOutputs = 0;

    try {
        //Only get orders that should be in Cashier
        response.cashierOrders = orders.filter(item => (item.orderPlaced == 1 && item.cancelled != true));
        for (let i = 0, n = response.cashierOrders.length; i < n; ++i) {

            // taxIncludedTotal: "",
            // taxTotal: "",
            //Order tatal
            response.counts.total_amount += +response.cashierOrders[i].subtotal;
            response.counts.total_amount += +response.cashierOrders[i].taxIncludedTotal;//add tax included
            response.counts.shipping_total += +response.cashierOrders[i].shippingPrice;//add tax included
            
            //what the customer own from an order.
            response.counts.total_credit += +response.cashierOrders[i].orderCredit;
        }

        //Add up vendor deposits
        for (let i = 0, n = deposits.length; i < n; ++i) {
            if (deposits[i].depositStatus === 'a') {
                response.counts.deposit_total += +deposits[i].depositedAmount;
            }
        }

        //Add up vendor spendings
        for (let i = 0, n = spendings.length; i < n; ++i) {
            response.counts.spending_total += +spendings[i].spendingAmount;
        }
        //Totals to be subtracted
        totalOutputs = +response.counts.total_credit + +response.counts.spending_total + +response.counts.deposit_total;

        //Total after subtraction
        response.counts.cashier_balance = +response.counts.total_amount - +totalOutputs;

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for getting customer order
* @param {function} getCustomerOrders
*/
export const getCustomerOrders = async (id) => {

    let response = {
        statistics: null,
        orders: null
    };

    try {

        let res = await API.get("adminAPI", `/admin/orders/customers/${id}`);
        let statistics = await calculateOrderStatistics(res.orders);

        response.orders = res.orders;
        response.statistics = statistics;

    } catch (err) {
        //throw 'Cannot connect';
    }

    return response;
};

export const calculateOrderStatistics = async (orders) => {

    let response = {
        totalClosed: 0,
        totalDraft: 0,
        totalOrders: orders.length,
        totalSalesToday: 0.00,
        totalSalesThisMonth: 0.00,
        totalAmount: 0.00
    };

    if (orders.length <= 0) {
        return response;
    }

    let today = moment(new Date()).format('YYYY-MM-DD');
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 999);

    const firstDayOfMonth = moment(new Date(start.getFullYear(), start.getMonth(), 1)).format('YYYY-MM-DD');

    for (let i = 0, n = orders.length; i < n; ++i) {
        let order = orders[i];

        //totalAmount
        if (order.orderPlaced || order.closed) {
            if (order.cancelled != true) {
                if (order.grandTotal != null && order.grandTotal > 0) {
                    response.totalAmount = response.totalAmount + order.grandTotal;
                }
            }
        }

        //totalClosed
        if (order.closed && moment(order.closedDate).format('YYYY-MM-DD') >= today) {
            response.totalClosed = response.totalClosed + 1;
        }

        //totalDraft
        if (order.draft) {
            response.totalDraft = response.totalDraft + 1;
        }

        if (order.paidDate && moment(order.paidDate).format('YYYY-MM-DD') == today) {
            if (order.orderPlaced || order.closed) {
                if (order.cancelled != true) {
                    if (order.grandTotal != null && order.grandTotal > 0) {
                        response.totalSalesToday = response.totalSalesToday + order.grandTotal;
                    }
                }
            }
        }

        //totalSalesThisMonth
        if (order.paidDate && moment(order.paidDate).format('YYYY-MM-DD') >= firstDayOfMonth) {
            if (order.orderPlaced || order.closed) {
                if (order.cancelled != true) {
                    if (order.grandTotal && order.grandTotal > 0) {
                        response.totalSalesThisMonth = response.totalSalesThisMonth + order.grandTotal;
                    }
                }

            }
        }

    }

    return response;
};

/**
* Function for updating a order
* @param {function} updateOrder
*/
export const updateOrder = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/orders/${id}`, {
            body: data
        });

        if (response.status === false) {
            throw response.error;
        }

    } catch (err) {
        if (response.status === false) {
            throw response.error;
        } else {
            throw 'Cannot connect';
        }
    }

    return response;
};

/**
* Function for saving  a order
* @param {object} data  
* @param {function} saveOrder 
*/
export const saveOrder = async (data) => {

    let response;

    try {
        let vendor = await getVendor(data.vendorId);

        //Get the last order number
        if (vendor.lastOrderNumber) {
            data.orderNumber = +vendor.lastOrderNumber + 1;
            vendor.lastOrderNumber = data.orderNumber;
        }

        response = await API.post("adminAPI", "/admin/orders", {
            body: data
        });
        await updateVendor(data.vendorId, { ...vendor });

    } catch (err) {
        throw 'Cannot connect';
    }
    return response;
};


/**
* Function for creating order items 
* @param {array} orderItems  
* @param {object} item   
* @param {function} updateOrderItems 
*/
export const updateOrderItems = async (orderItems, item, vendorId) => {

    let response = {
        orderItems: orderItems,
        success: true,
        errorMessage: null
    };
    let isExist = false;
    let index = null;
    let today = moment(new Date()).format('YYYY-MM-DD');
    let vendorDispatch = item;

    item = await getProduct(item.productId);

    try {

        if (vendorDispatch.quantity > 0) {//Determind if product is available

            response.orderItems.forEach((element, i) => {
                if (element.product.productId == item.productId) {//Determind if product is already added to the order
                    isExist = true;
                    index = i;
                }
            });

            if (isExist) {//
                let element = response.orderItems[index];

                if (item.productSalesPrice != null && item.productDateSaleFrom != null && item.productDateSaleTo != null) {

                    if ((item.productDateSaleFrom <= today) && (item.productDateSaleTo >= today)) {//Product Discount Check

                        if (item.productPrice != null) {
                            element.price = +item.productSalesPrice;
                            element.total = +element.total + +item.productSalesPrice;
                            element.totalDiscount = +element.totalDiscount + (+item.productPrice - +item.productSalesPrice);
                            element.product = item;
                            element.quantity = +element.quantity + 1;

                        } else {
                            response.success = false;
                            response.errorMessage = 'The selected product do not have a price.';
                        }

                    } else {//Duplicated No Discount for product Date Passed/Not Yet

                        if (item.productPrice != null) {
                            element.price = +item.productPrice;
                            element.total = +element.total + +item.productPrice;
                            element.product = item;
                            element.quantity = +element.quantity + 1;
                        } else {
                            response.success = false;
                            response.errorMessage = 'The selected product do not have a price.';
                        }
                    }

                } else {// Duplicated No Discount for product
                    if (item.productPrice != null) {
                        element.price = +item.productPrice;
                        element.total = +element.total + +item.productPrice;
                        element.product = item;
                        element.quantity = +element.quantity + 1;
                    } else {
                        response.success = false;
                        response.errorMessage = 'The selected product do not have a price.';
                    }
                }
                response.orderItems[index] = element;

                return response;
            }


            if (!isExist) {//Use for adding a new item.

                let obj = {
                    placedBack: false,
                    quantity: 1,
                    price: 0.00,
                    total: 0.00,
                    totalDiscount: 0.00,
                    product: {}
                };

                if (item.productSalesPrice != null && item.productDateSaleFrom != null && item.productDateSaleTo != null) {

                    if ((item.productDateSaleFrom <= today) && (item.productDateSaleTo >= today)) {//Product Discount Check
                        if (item.productPrice != null) {
                            obj.price = +item.productSalesPrice;
                            obj.total = +item.productSalesPrice;
                            obj.totalDiscount = +item.productPrice - +item.productSalesPrice;
                            obj.product = item;
                            response.orderItems.push(obj);
                        } else {
                            response.success = false;
                            response.errorMessage = 'The selected product do not have a price.';
                        }

                    } else {//No Discount for product Date Passed/Not Yet

                        if (item.productPrice != null) {
                            obj.price = +item.productPrice;
                            obj.total = +item.productPrice;
                            obj.product = item;
                            response.orderItems.push(obj);
                        } else {
                            response.success = false;
                            response.errorMessage = 'The selected product do not have a price.';
                        }
                    }

                } else {// No Discount for product
                    if (item.productPrice != null) {
                        obj.price = +item.productPrice;
                        obj.total = +item.productPrice;
                        obj.product = item;
                        response.orderItems.push(obj);
                    } else {
                        response.success = false;
                        response.errorMessage = 'The selected product do not have a price.';
                    }
                }
            }

        } else {
            response.success = false;
            response.errorMessage = 'Product is not avaible.';
        }

    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function for removing order items 
* @param {array} orderItems  
* @param {object} item   
* @param {function} removeOrderItem 
*/
export const removeOrderItem = (orderItems, item) => {

    let response = {
        orderItems: orderItems,
        success: true,
        errorMessage: null
    };

    try {

        const index = response.orderItems.indexOf(item);
        if (index > -1) {
            response.orderItems.splice(index, 1);
        } else {
            response.success = false;
            response.errorMessage = 'Product was not found';
        }

    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function for updating order quantity 
* @param {array} orderItems  
* @param {object} item 
* @param {object} input    
* @param {function} updatOrderQuantity 
*/
export const updatOrderQuantity = async (orderItems, item, input, vendorId, products) => {

    let response = {
        orderItems: orderItems,
        success: true,
        errorMessage: null
    };
    const index = response.orderItems.indexOf(item);
    let product = {};
    let vendorDispatch = {};

    product = await getProduct(item.product.productId);

    //Get Dispatch for vendor
    products.filter(element => element.productId == product.productId)
        .map(element => vendorDispatch = element);

    let availableQuantity = +vendorDispatch.quantity - +input.quantity;
    let discount = 0.00;
    try {
        if (availableQuantity >= 0) {

            if (index > -1) {
                if (+input.quantity > 0) {
                    if (product.productPrice > input.price) {
                        discount = +product.productPrice - input.price;
                        //+response.orderItems[index].totalDiscount / +item.quantity;
                    }

                    response.orderItems[index].product = product;
                    response.orderItems[index].quantity = input.quantity;
                    response.orderItems[index].price = input.price;
                    response.orderItems[index].totalDiscount = +discount * +input.quantity;
                    response.orderItems[index].total = +input.price * +input.quantity;
                    //response.orderItems[index].total = +item.price * +input.quantity;
                }

            } else {
                response.success = false;
                response.errorMessage = 'Product is was not found';
            }
        } else {
            response.success = false;
            response.errorMessage = 'We do not have enough in stock.';
        }

    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function for calculating order totals 
* @param {object} order    
* @param {function} calculateOrderPrices 
*/
export const calculateOrderPrices = (order) => {

    order.subtotal = 0.00;
    order.discountTotal = 0.00;

    try {
        order.orderItems.forEach((element, i) => {//Add up all products
            order.subtotal = order.subtotal + +element.total;
            order.discountTotal = order.discountTotal + +element.totalDiscount;
        });

        order.grandTotal = order.subtotal;

        if (+order.shippingPrice > 0) { //Calculate Shipping price
            order.grandTotal = order.grandTotal + +order.shippingPrice
        }

        if (order.itemTaxIncluded == 'yes') {//Calculate product tax
            let tax = +order.taxRate / 100;
            tax = +order.subtotal * tax;
            order.grandTotal = +order.grandTotal + tax;
            order.taxIncludedTotal = tax;
        } else {
            order.grandTotal = +order.grandTotal - order.taxIncludedTotal;
            order.taxIncludedTotal = 0.00;
        }

    } catch (err) {
        throw err;
    }

    return order;
};



/**
* Function for placing order
* @param {object} order    
* @param {function} placeOrder 
*/
export const placeOrder = (order, orderItems, products) => {

    let response = {
        order: order,
        orderItems: orderItems,
        success: true,
        errorMessage: null
    };

    try {

        let isValid = placeOrderValidate(orderItems, order.vendorId, products);

        if (isValid) {
            response.orderItems.forEach(async (element, i) => {//Check if all products are still available

                let product = element.product;
                product = await getProduct(element.product.productId);
                let vendorDispatch = {};

                products.filter((item) => item.productId == product.productId)
                    .map(item => vendorDispatch = item);

                vendorDispatch.quantity = vendorDispatch.quantity - +element.quantity;
                vendorDispatch.soldQuantity = vendorDispatch.soldQuantity + +element.quantity;

                let availableQty = +product.availableQuantity - +element.quantity;

                const index = response.orderItems.indexOf(element);

                response.orderItems[index].product = product;
                product.availableQuantity = availableQty;
                product.soldQuantity = +product.soldQuantity + +element.quantity;

                await updateDispatch(vendorDispatch.productDispatchId, {
                    ...vendorDispatch
                });
                let res = await updateProduct(product.productId, product);
            });
        } else {
            response.success = false;
            response.errorMessage = 'Not all products are available.';
            return response;
        }

        // let obj = {//Create order transaction
        //     paymentMethod: order.paymentMethod,
        //     total: order.grandTotal,
        //     paymentDate: Date.now()
        // };

        // response.order.orderTransactions.push(obj);
        response.order.draft = false;
        response.order.orderPlaced = true;
        response.order.orderCredit = order.grandTotal;
        //response.order.paidDate = Date.now();

    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function to check if order exist
* @param {object} orderItems 
* @param {string} vendorId 
* @param {function} placeOrderValidate 
*/

export const placeOrderValidate = (orderItems, vendorId, products) => {
    let response = true;

    try {
        orderItems.forEach(async (element, i) => {//Check if all products are still available
            let product = await getProduct(element.product.productId);
            let vendorDispatch = {};

            products.filter(item => item.productId == product.productId)
                .map(item => vendorDispatch = item);

            let availableQty = +vendorDispatch.quantity - +element.quantity;

            if (availableQty < 0) {
                response = false;
                return response;
            }

        });

    } catch (err) {
        response = false;
        return response;
    }

    return response;
};

/**
* Function to add products back to the main prod
* @param {object} order 
* @param {object} orderItems 
* @param {function} cancelOrder 
*/
export const cancelOrder = (order, orderItems, products) => {

    let response = {
        order: order,
        orderItems: orderItems,
        success: true,
        errorMessage: null
    };

    try {

        response.orderItems.forEach(async (element, i) => {//Check if all products are still available
            if (element.placedBack) {//
            } else {
                let product = await getProduct(element.product.productId);
                let vendorDispatch = {};

                products.filter((item) => item.productId == product.productId)
                    .map(item => vendorDispatch = item);

                const DisIndex = product.productDispatch.indexOf(vendorDispatch);
                vendorDispatch.quantity = vendorDispatch.quantity + +element.quantity;
                vendorDispatch.soldQuantity = vendorDispatch.soldQuantity - +element.quantity;

                product.availableQuantity = +product.availableQuantity + +element.quantity;//Quantity from all stores
                product.soldQuantity = +product.soldQuantity - +element.quantity;

                await updateDispatch(vendorDispatch.productDispatchId, {
                    ...vendorDispatch
                });

                let res = await updateProduct(product.productId, product);
                if (res.status) {
                    element.placedBack = true;
                    response.orderItems[i] = element.placedBack;
                    response.order.orderItems[i] = element.placedBack;
                }
            }

        });

    } catch (err) {
        throw err;
    }

    return response;
};



/**
* Function for creating order Transactions
* @param {boolean} fullyPaid 
* @param {object} transactions    
* @param {order} order     
* @param {function} saveTransactions 
*/
export const saveTransactions = (fullyPaid, transactions, order, userId) => {
    let response = order;

    try {
        if (fullyPaid) {//Used for one time payment

            let obj = {//Create order transaction
                orderTransactionId: 'unique' + Date.now(),
                paymentMethod: order.paymentMethod,
                transactionAmount: order.grandTotal,
                createdBy: userId,
                paymentDate: new Date()
            };

            transactions.push(obj);
            response.orderCredit = 0;
            response.paidDate = Date.now();
            response.paid = true;

            response = {
                ...order,
                ['orderTransactions']: transactions
            };

        } else {//Used for multiple transactions

            if (transactions.length > 0) {
                let res = calculateCustomerCredit(transactions, order.grandTotal);

                if (res.isError === 0) {

                    if (res.credit === 0) {
                        response.paidDate = Date.now();
                    }

                    response.orderCredit = res.credit;
                    response.paid = true;
                    response = {
                        ...order,
                        ['orderTransactions']: transactions
                    };
                } else {
                    return false;
                }
            } else {
                response.orderCredit = order.grandTotal;
                response.paid = false;
                response = {
                    ...order,
                    ['orderTransactions']: []
                };
            }
        }


    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function for calculating Transactions
* @param {object} transactions    
* @param {order} grandTotal     
* @param {function} calculateCustomerCredit 
*/
export const calculateCustomerCredit = (transactions, grandTotal) => {
    let response = {
        isError: 0,
        credit: 0,
        totalTransaction: 0
    }

    try {
        if (transactions.length > 0) {

            transactions.forEach(async (element, i) => {
                response.totalTransaction += +element.transactionAmount;
            });

            response.credit = +grandTotal - +response.totalTransaction;
        }

        if (response.credit < 0) {
            response.isError = 1;
            return response;
        }


    } catch (err) {
        throw err;
    }

    return response;
};

/**
* Function for getting orders today   
* @param {object} orders     
* @param {function} getVendorTodayOrders 
*/
export const getVendorTodayOrders = async (orders) => {

    let response = []

    if (orders.length <= 0) {
        return response;
    }

    let today = moment(new Date()).format('YYYY-MM-DD');

    for (let i = 0, n = orders.length; i < n; ++i) {
        let order = orders[i];

        //today's orders
        if (moment(order.createdAt).format('YYYY-MM-DD') >= today) {
            response.push(order);
        }

    }

    return response;
};
