import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import UserRolesList from "../../components/tables/UserRolesTable";

import "./UserRoles.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function UserRoles() {
    const { isAuthenticated } = useAppContext();
    const { store, actions, roles } = useAppContext();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            try {
                //actions.onSetIsLoading(true);
                //actions.onGetUserRoles();
            } catch (e) {
                onError(e);
            }
            actions.onSetIsLoading(false);
        }
        onLoad();
    }, [isAuthenticated]);

    useEffect(() => {
        actions.onSetSelected('user_roles');
    }, []);

    function renderUserRolesList() {
        return (
            <>
                <LinkContainer to="/a_portal/user_roles/create">
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold">Add New Role</span>
                    </ListGroup.Item>
                </LinkContainer>
                <UserRolesList rolesData={roles}/>
            </>
        );
    }

    return (
        <div className="user_roles">
            <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom">User Roles Page</h2>
                <ListGroup>{renderUserRolesList()}</ListGroup>
            </Box>
        </div>
    );
}