import { API } from "aws-amplify";

/**
* Function for getting all vendors 
* @param {function} getAllVendors 
*/
export const getAllVendors = async (companyId) => {

    let response;

    try {
//ADD CID
        response = await API.get("adminAPI", `/admin/vendors/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a vendor 
* @param {function} getVendor 
*/
export const getVendor = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/vendor/${id}`);

    } catch (err) {

        throw err;// 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a vendor 
* @param {function} updateVendor 
*/
export const updateVendor = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/vendors/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a vendor
* @param {object} data  
* @param {function} saveVendor 
*/
export const saveVendor = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/vendors", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove vendor
* @param {function} removeVendor
*/
export const removeVendor = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/vendors/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};