import * as React from 'react';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import PermissionsTable from './tables/PermissionsTable';
import { useAppContext } from "../../lib/contextLib";

export default function Permissions() {

    const {
        permissions
    } = useAppContext();

    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <h4 className="pb-3 mb-3 border-bottom" style={{marginLeft: 10}}> {'User Permissions'}</h4>
                        <PermissionsTable permissionsData={permissions} />
                    </Grid>
                </Grid>

            </List>

        </div>
    );
}
