import * as React from 'react';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import { Avatar, Badge, Button, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";
import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { Text } from '../../services/translator';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '',
    boxShadow: 24,
    p: 4
};

var moment = require('moment'); // require

export default function PersonalInfo({ onHandleChange, onSave }) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        reset
    } = useForm();

    const onSubmit = async (data) => {
        await changePassword(data.oldPassword, data.newPassword);
    };

    const passwordMatchValidator = (value) => {
        const password = getValues('newPassword');
        return password === value || 'Les mots de passe ne correspondent pas';
    };

    const passwordLengthValidator = (value) => {
        return value.length >= 6 || 'Le mot de passe doit contenir au moins 6 caractères';
    };

    const changePassword = async (oldPassword, newPassword) => {
        try {
            setLoading(true);

            const user = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(user, oldPassword, newPassword);
            if (data) {
                reset();
                toast.success(<Text>Change password</Text>);
            } else {
                toast.error(<Text>Wrong password</Text>);
            }

        } catch (err) {
            console.log(err);
            toast.error("Error");
        } finally {
            setLoading(false);
        }
    }

    const {
        userHasAuthenticated,
        store,
        actions,
        orderStatus,
        shippingMethods,
        paymentMethods,
        customers,
        permissions,
        states,
        user
    } = useAppContext();
    const { id } = useParams();
    const history = useHistory();

    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid item>
                            <Typography textAlign="center" variant="h4" gutterBottom>
                                <Text>Account Information</Text>
                            </Typography>
                        </Grid>
                        <Grid item container alignItems="center" justifyContent="center">
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={<div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: '#4CAF50' }} />}
                            >
                                <Avatar
                                    alt="User Profile Image"
                                    src={user.profileImage || '/avatar.png'}
                                    sx={{ width: 100, height: 100 }}
                                />
                            </Badge>
                        </Grid>
                        <Typography variant="body1">
                            <span className='font-bold text-blue-700'><Text>Name</Text> : </span>
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography variant="body1">
                            <span className='font-bold text-blue-700'><Text>User Email</Text> :</span> {user.email}
                        </Typography>
                        <Typography variant="body1">
                            <span className='font-bold text-blue-700'><Text>Phone</Text>: </span> {user.phoneCountryCode} {user.phoneNumber}
                        </Typography>
                        <Button onClick={handleOpen} variant="contained" color="primary" >
                            <Text>Change password</Text>
                        </Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    <Text>Password change</Text>
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
                                        <TextField
                                            type='password'
                                            label=<Text>Current password</Text>
                                    variant='outlined'
                                    margin='normal'
                                    {...register('oldPassword', { required: true })}
                                    error={!!errors.oldPassword}
                                    helperText={errors.oldPassword?.type === 'required' && <Text>Current password is required</Text>}
                                        />
                                    <TextField
                                        type='password'
                                        label=<Text>New password</Text>
                                variant='outlined'
                                margin='normal'
                                {...register('newPassword', { required: true, validate: passwordLengthValidator })}
                                error={!!errors.newPassword}
                                helperText={errors.newPassword?.type === 'required' && <Text>Password is required</Text>}
                                        />
                                <TextField
                                    type='password'
                                    label=<Text>Confirm password</Text>
                            variant='outlined'
                            margin='normal'
                            {...register('confirmPassword', { required: true, validate: passwordMatchValidator })}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword?.type === 'required' && <Text>Passwords are not the same</Text>}
                                        />
                            <Button
                                style={{ marginTop: 12, padding: 16 }}
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={!!errors.oldPassword || !!errors.newPassword || !!errors.confirmPassword}
                            >
                                {loading ? <CircularProgress color='white' size={24} /> : <Text>Validate</Text>}
                            </Button>
                                    </form>
                                </Typography>
                            </Box>
                        </Modal>
                    </Grid>
                </Grid >

            </List >

        </div >
    );
}
