import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useAppContext } from "../../../lib/contextLib";
import { Text } from '../../../services/translator';
import DepositsDialog from "../dialogs/DepositsDialog";
import {
  priceFormat,
  getBankAccountName,
  getUserName
} from "../../../services/shared";

var moment = require('moment'); // require

export default function DepositsList({ deposits }) {
  const { 
    bankAccounts, 
    userPermissions,
    users 
  } = useAppContext();
  return (
    <List sx={{
      width: '100%',
      maxWidth: 360,
      bgcolor: 'background.paper',
      position: 'relative',
      overflow: 'auto',
      maxHeight: 360
    }}>
      {userPermissions.admin || userPermissions.deposit_write || userPermissions.deposit_read ? <>
        {deposits.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((dep, index) => (
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={"Deposit# " + (index + 1)}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Amount</Text>:</b>
                  </Typography>
                  {' ' + priceFormat(dep.depositedAmount)}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                   <b> <Text>Deposited Date</Text>:</b>
                  </Typography>
                  {' ' + moment(dep.depositedDate).format('L')}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Deposited To</Text>:</b>
                  </Typography>
                  {' ' + getBankAccountName(bankAccounts, dep.accountId)}
                  <br />

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Deposited By</Text>:</b>
                  </Typography>
                  {' ' + dep.depositedBy}
                  <br />

                  {dep.approvedBy != null &&
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <b><Text>Approver</Text>:</b>
                      </Typography>
                      {' ' + getUserName(users, dep.approvedBy)}
                      <br />
                    </>
                  }
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Note</Text>:</b>
                  </Typography>
                  {' ' + dep.depositNote}
                </React.Fragment>
              }
            />
            <ListItemAvatar>
              {dep.depositStatus === 'p' ?
                <DepositsDialog
                  btColor={'primary'}
                  btTitle={<Text>Manage</Text>}
                  id={dep.depositId}
                  deposit={dep}
                />
                : dep.depositStatus === 'a' ?
                  <Chip label="Accepted" color="success" size="small" />
                  : dep.depositStatus === 'd' ?
                    <Chip label="Declined" color="error" size="small" />
                    : null
              }

            </ListItemAvatar>
          </ListItem>
        ))}
      </> : null}
    </List>

  );
}
