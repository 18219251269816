import { API } from "aws-amplify";

/**
* Function for getting all product cats 
* @param {function} getAllProductCats 
*/
export const getAllProductCats = async (companyId) => {

    let response;

    try {
//Add CID
        response = await API.get("adminAPI", `/admin/product_categories/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product cat 
* @param {function} getProductCat 
*/
export const getProductCat = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/product_category/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a product cat 
* @param {function} updateProductCat 
*/
export const updateProductCat = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/product_categories/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a product cat
* @param {object} data  
* @param {function} saveProductCat 
*/
export const saveProductCat = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/product_categories", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function to remove product cat
* @param {function} removeProduct
*/
export const removeProductCat = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/product_categories/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};