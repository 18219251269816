import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoaderButton from "../../../components/LoaderButton";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { Text } from '../../../services/translator';
import ConfirmDelete from "../../../components/dialogs/ConfirmDelete";
import OutputsList from "../../orders/list/OutputsList";
import {
    saveSpending,
    updateSpending,
    removeSpending,
    getOrderSpendings
} from '../../../services/spendings';
import { Colors } from '../../../lib/colors';

export default function DepositsDialog({
    btColor,
    btTitle,
    id = 0,
    spending = null,
    orderId = null,
    orderNumber = null,
    showForm = true
}) {

    const {
        actions,
        vendor,
        bankAccounts,
        spendingCategories,
        activeUsers,
        user,
        spendings,
        paymentMethods,
        spendingTypes,

    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [orderSpendings, setOrderSpendings] = React.useState([]);
    const [formInput, setFormInput] = React.useState({
        active: true,
        vendorId: '',
        orderId: orderId,
        orderNumber: orderNumber,
        spendingCategoryId: '',
        spendingAmount: '',
        spendingDate: new Date(),
        spendingStatus: 'p',
        spendingPayee: '',
        spendingType: 'vendor',
        loanFor: null,
        createdBy: '',
        paymentMethod: '',
        spendingNote: '',
        lastUpdatedBy: '',
    });

    const [errors, setErrors] = React.useState({
        spendingCategoryId: '',
        spendingAmount: '',
        spendingDate: '',
        spendingStatus: '',
        paymentMethod: '',
        loanFor: ''
    });

    React.useEffect(async () => {

        if (orderId !== null && spendings.length > 0) {
            let res = getOrderSpendings(spendings, orderId);
            setOrderSpendings(res);
        }

    }, [spendings, orderId]);

    React.useEffect(() => {
        if (id !== 0) {
            setFormInput(spending);
        }
    }, [spending, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        if (name == 'spendingType' && (value == 'vendor' || value == 'vehicle')) {
            newFormInput.loanFor = null;
        }

        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.spendingDate === '') {
            newErrors.spendingDate = 'Spent date is required.';
        } else {
            newErrors.spendingDate = '';
        }

        if (formInput.spendingCategoryId === '') {
            newErrors.spendingCategoryId = 'Category by is required.';
        } else {
            newErrors.spendingCategoryId = '';
        }

        if (formInput.spendingAmount === '') {
            newErrors.spendingAmount = 'Amount is required.';
        } else {
            newErrors.spendingAmount = '';
        }

        if (formInput.paymentMethod === '') {
            newErrors.paymentMethod = 'Payment Method is required.';
        } else {
            newErrors.paymentMethod = '';
        }

        if (formInput.spendingType === 'loan') {
            if (formInput.loanFor === '' || formInput.loanFor === null) {
                newErrors.loanFor = 'Field is required.';
            } else {
                newErrors.loanFor = '';
            }
        }

        setErrors(newErrors);

        if (
            newErrors.spendingDate.length > 0 ||
            newErrors.spendingCategoryId.length > 0 ||
            newErrors.spendingAmount.length > 0 ||
            newErrors.paymentMethod.length > 0 ||
            newErrors.loanFor.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {

        let isValid = validateForm();
        if (isValid) {
            setSubmitting(true);
            let response = null;

            try {
                if (id === 0) {
                    formInput.vendorId = vendor.vendorId;
                    formInput.createdBy = user.userId;
                    formInput.lastUpdatedBy = user.userId;

                    response = await saveSpending({ ...formInput });

                    actions.onSetFeedback(true, 'Data was successfully saved.', 'success');
                } else {
                    formInput.lastUpdatedBy = user.userId;
                    response = await updateSpending(id, { ...formInput });
                    actions.onSetFeedback(true, 'Data was successfully updated.', 'success');
                }

                actions.onGetOrders(vendor.vendorId);
                setOpen(false);
                setSubmitting(false);
            } catch (e) {
                onError(e);
            }

        }

    }

    async function handleRemove() {

        let response = null;
        try {
            setSubmitting(true);
            response = await removeSpending(id);

            actions.onSetFeedback(true, 'Data was successfully removed.', 'success');

            actions.onGetOrders(vendor.vendorId);
            setOpen(false);
            setSubmitting(false);
        } catch (e) {
            onError(e);
        }

    }
    return (
        <div>
            {showForm === false && orderSpendings.length > 0 &&
                <Button variant="contained" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10, backgroundColor: Colors.lightBlue }}>
                    {btTitle}
                </Button>
            }
            {showForm === true &&
                <Button variant="contained" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10, backgroundColor: Colors.lightBlue }}>
                    {btTitle}
                </Button>
            }
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {id !== 0 ? <Text>Update Output</Text> : <Text>Add Output</Text>}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">

                        <Divider />

                        {showForm &&
                            <Grid
                                container
                                spacing={2}
                                style={{ marginTop: 2 }}
                            >

                                {orderNumber == null &&
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><Text>Spending Type</Text></InputLabel>
                                            <Select
                                                disabled={id === 0 ? false : true}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="spendingType"
                                                value={formInput.spendingType}
                                                label={<Text>Spending Type</Text>}
                                                onChange={(data) => {
                                                    handleInputChange(data);
                                                }}
                                            >
                                                {spendingTypes.map((row, index) =>
                                                    <MenuItem key={index} value={row.value}>{row.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }

                                {formInput.spendingType == 'loan' && formInput.orderNumber == null &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><Text>Loan For</Text></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="loanFor"
                                                value={formInput.loanFor}
                                                helperText={errors.loanFor}
                                                label={<Text>Loan For</Text>}
                                                onChange={handleInputChange}
                                            >
                                                {activeUsers.map((u, index) =>
                                                    <MenuItem key={index + u.userId} value={u.userId}>{u.firstName} {u.lastName}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }

                                {formInput.orderNumber && formInput.orderNumber !== null &&
                                    <Grid item xs={12} sm={12} md={12} style={{
                                        //marginTop: 15,
                                        color: Colors.blue,
                                        fontSize: 20
                                    }}>
                                        <Text>Order Number</Text>: {formInput.orderNumber}
                                    </Grid>
                                }
                                {/* <Grid item xs={12} sm={6} md={6}>
                               <FormControl fullWidth>
                                   <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                   <Select
                                       disabled={id !== 0 ? false : true}
                                       labelId="demo-simple-select-label"
                                       id="demo-simple-select"
                                       name="spendingStatus"
                                       value={formInput.spendingStatus}
                                       label={'Status'}
                                       onChange={handleInputChange}
                                   >
                                       {transStatuses.map((s, index) =>
                                           <MenuItem key={index} value={s.value}>{s.name}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           </Grid> */}

                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Category</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="spendingCategoryId"
                                            value={formInput.spendingCategoryId}
                                            label={<Text>Category</Text>}
                                            onChange={handleInputChange}
                                        >
                                            {spendingCategories.map((cat, index) =>
                                                <MenuItem key={index} value={cat.spendingCategoryId}>{cat.spendingCategoryName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        id="outlined-error"
                                        name="spendingAmount"
                                        label={<Text>Amount</Text>}
                                        value={formInput.spendingAmount}
                                        helperText={errors.spendingAmount}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        name="spendingDate"
                                        id="date"
                                        type={'date'}
                                        label={<Text>Date</Text>}
                                        value={formInput.spendingDate}
                                        helperText={errors.spendingDate}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Payment Method</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="paymentMethod"
                                            value={formInput.paymentMethod}
                                            label={<Text>Payment Method</Text>}
                                            onChange={handleInputChange}
                                        >
                                            {paymentMethods.map((m, index) =>
                                                <MenuItem key={index} value={m}>{m}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="spendingNote"
                                        name="spendingNote"
                                        label={<Text>Note</Text>}
                                        multiline
                                        maxRows={4}
                                        minRows={2}
                                        fullWidth
                                        defaultValue={formInput.spendingNote}
                                        onChange={handleInputChange}
                                    />
                                </Grid>

                                {id !== 0 && <Grid item xs={12} sm={12} md={12}>
                                    <ConfirmDelete
                                        btTitle={<Text>Remove</Text>}
                                        dlTitle={<Text>Confirm Remove</Text>}
                                        message={<Text>Are you sure</Text>}
                                        onConfirm={handleRemove}
                                    />
                                </Grid>}

                            </Grid>
                        }

                        {orderId != null &&
                            <OutputsList
                                rows={orderSpendings}
                            />
                        }
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                onClick={handleClose}
                            ><Text>Cancel</Text></Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                        }}>

                            {showForm === true &&
                                <LoaderButton
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                    // block
                                    type="submit"
                                    size="sm"
                                    variant="primary"
                                    isLoading={submitting}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    <Text>Save</Text>
                                </LoaderButton>
                            }

                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
