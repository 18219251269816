import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Categories from "../../containers/product_categories/selectList";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ProductCats({onClose}) {
    const theme = useTheme();
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const [showSave, setShowSave] = React.useState(false);
    const [item, setItem] = React.useState({});
    const { userHasAuthenticated, store, actions, productCategories, vendors } = useAppContext();

    const handleClose = () => {
        onClose();
       // setOpen(false);
    };

    const handleChange = (data) => {
        history.push(`/a_portal/product_categories/${data.categoryId}`);
    };


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen
                style={{
                    //minWidth: 200, 
                    maxWidth: 400
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Manage Categories
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <LinkContainer to="/a_portal/product_categories/create">
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold">New Category</span>
                    </ListGroup.Item>
                </LinkContainer>
                    <Categories
                        onSelect={(item) => {
                            handleChange(item);
                        }}
                        selectedId={''}
                    />
                </DialogContent>
               
            </BootstrapDialog>

        </>
    );
}
