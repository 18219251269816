import * as React from 'react';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import BusinessYear from './dialogs/BusinessYear'
import EmailInfo from "./EmailInfo";
import { Text } from '../../services/translator';
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";

var moment = require('moment'); // require

export default function General({ onHandleChange, onSave }) {

    const {
        userHasAuthenticated,
        store,
        actions,
        userPermissions,
        states,
        settings,
        user
    } = useAppContext();
    const { id } = useParams();
    const history = useHistory();


    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <h4 className="pb-3 mb-3 border-bottom" style={{marginLeft: 10}}> {'General Information'} - {settings.currentYear}</h4>
                        {userPermissions.admin && <BusinessYear
                            btColor={'primary'}
                            btTitle={<Text>New Business Year</Text>}
                        />}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <EmailInfo />
                    </Grid>

                </Grid>

            </List>

        </div>
    );
}
