import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import ConfirmDelete from "../../../components/dialogs/ConfirmDelete";

import {
    saveBankAccount,
    updateBankAccount,
    removeBankAccount
} from "../../../services/bank_accounts";

export default function BankAccountForm({ btColor, btTitle, id = 0, bank_account = null }) {

    const {
        actions,
        companyId
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        active: true,
        bankCode: '',
        agencyCode: '',
        accountNumber: '',
        accountName: '',
        ribKey: '',
        swiftAddress: '',
        accountType: '',
        accountNote: '',
        bankName: '',
        bankAddressLine1: '',
        bankAddressLine2: '',
        bankCity: '',
        bankState: '',
        bankZipCode: '',
        bankZipCountry: 'SN',
        bankPhoneNumber: '',
        bankEmail: '',
        bankContactPerson: '',
    });

    const [errors, setErrors] = React.useState({
        bankCode: '',
        agencyCode: '',
        accountNumber: '',
        accountName: '',
        ribKey: '',
        swiftAddress: '',
        accountType: '',
        accountNote: '',
        bankName: '',
        bankAddressLine1: '',
        bankAddressLine2: '',
        bankCity: '',
        bankState: '',
        bankZipCode: '',
        bankZipCountry: 'SN',
        bankPhoneNumber: '',
        bankEmail: '',
        bankContactPerson: '',
    });

    React.useEffect(() => {
        if (id != 0) {
            setFormInput(bank_account);
        }
    }, [bank_account, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.accountName === '') {
            newErrors.accountName = 'Account Name is required.';
        } else {
            newErrors.accountName = '';
        }

        if (formInput.accountNumber === '') {
            newErrors.accountNumber = 'Account Number is required.';
        } else {
            newErrors.accountNumber = '';
        }

        if (formInput.bankName === '') {
            newErrors.bankName = 'Bank Name is required.';
        } else {
            newErrors.bankName = '';
        }

        if (formInput.bankCode === '') {
            newErrors.bankCode = 'Bank Code is required.';
        } else {
            newErrors.bankCode = '';
        }

        if (formInput.agencyCode === '') {
            newErrors.agencyCode = 'Agency Code is required.';
        } else {
            newErrors.agencyCode = '';
        }

        if (formInput.ribKey === '') {
            newErrors.ribKey = 'RIB Key Code is required.';
        } else {
            newErrors.ribKey = '';
        }


        setErrors(newErrors);

        if (
            newErrors.accountName.length > 0 ||
            newErrors.accountNumber.length > 0 ||
            newErrors.bankName.length > 0 ||
            newErrors.bankCode.length > 0 ||
            newErrors.agencyCode.length > 0 ||
            newErrors.ribKey.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {

        let isValid = validateForm();
        if (isValid) {
            let response = null;
            try {
                if (id == 0) {
                    response = await saveBankAccount({ ...formInput, companyId });

                    actions.onSetFeedback(true, 'Bank Account was successfully saved.', 'success');
                } else {

                    response = await updateBankAccount(id, { ...formInput });

                    actions.onSetFeedback(true, 'Bank Account was successfully updated.', 'success');
                }

                actions.onGetBankAccounts(companyId);
                setOpen(false);
            } catch (e) {
                onError(e);
            }
        }

    }

    async function handleRemove() {

        let response = null;
        try {
            response = await removeBankAccount(id);

            actions.onSetFeedback(true, 'Bank Account was successfully removed.', 'success');

            actions.onGetBankAccounts(companyId);
            setOpen(false);
        } catch (e) {
            onError(e);
        }

    }
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10 }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {id !== 0 ? "RIB: " + formInput.bankCode + formInput.agencyCode + formInput.accountNumber + formInput.ribKey : "Bank Accounts Form"}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >
                            {/* <InputLabel id="demo-simple-select-label">Customer Info</InputLabel> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="accountName"
                                    label="Account Name *"
                                    value={formInput.accountName}
                                    helperText={errors.accountName}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="bankCode"
                                    label="Bank Code *"
                                    value={formInput.bankCode}
                                    helperText={errors.bankCode}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="agencyCode"
                                    label="Agency Code *"
                                    value={formInput.agencyCode}
                                    helperText={errors.agencyCode}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="accountNumber"
                                    label="Account Number *"
                                    value={formInput.accountNumber}
                                    helperText={errors.accountNumber}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="ribKey"
                                    label="RIB Key *"
                                    value={formInput.ribKey}
                                    helperText={errors.ribKey}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="swiftAddress"
                                    label="Swift Address"
                                    value={formInput.swiftAddress}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="bankName"
                                    label="Bank Name *"
                                    value={formInput.bankName}
                                    helperText={errors.bankName}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="bankContactPerson"
                                    label="Bank Contact Person"
                                    value={formInput.bankContactPerson}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>

                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.bankPhoneNumber}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={handleInputChange}
                                >
                                    {() =>
                                        // <TextField
                                        //     id="outlined-error"
                                        //     name="phoneNumber"
                                        //     label="Customer Phone Number"
                                        //     fullWidth />

                                        <TextField
                                            id="outlined-error"
                                            name="bankPhoneNumber"
                                            label="Bank Phone Number"
                                            fullWidth
                                        />
                                    }
                                </InputMask>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="bankEmail"
                                    label="Bank Email"
                                    value={formInput.bankEmail}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            
                            {id !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <ConfirmDelete
                                    btTitle={'Remove'}
                                    dlTitle={'Confirm Remove'}
                                    message={'Are you sure you want to remove this record.'}
                                    onConfirm={handleRemove}
                                />
                            </Grid>}

                        </Grid>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleSubmit();
                                }}
                            //autoFocus
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
