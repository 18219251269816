import { API } from "aws-amplify";
import {
    getApproval,
    saveApproval,
    updateApproval,
    removeApproval
} from "./approvals";

/**
* Function for getting a deposit
* @param {function} getDeposit
*/
export const getDeposit = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/deposit/" + id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all deposits 
* @param {function} getAllDeposits 
*/
export const getAllDeposits = async (companyId) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/deposits/" + companyId);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all vendor deposits 
* @param {function} getAllVendorDeposits 
*/
export const getAllVendorDeposits = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/deposits/vendors/" + id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a deposit
* @param {object} data  
* @param {function} saveDeposit 
*/
export const saveDeposit = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/deposits", {
            body: data
        });

        //Create Approval
        await saveApproval({
            vendorId: response.vendorId,
            parentId: response.depositId,
            approvalType: 'deposits',
            createdBy: response.createdBy,
            approvalData: response
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a deposit 
* @param {function} updateDeposit 
*/
export const updateDeposit = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/deposits/${id}`, {
            body: data
        });

        if (response.status && response.data.depositStatus === 'p') {
            
                let res = await getApproval(response.data.depositId);
                
                if (res.status === true) {
                    await updateApproval(response.data.depositId, {
                        approvalData: response.data
                    });
                }else{
                    await saveApproval({
                        vendorId: response.data.vendorId,
                        parentId: response.data.depositId,
                        approvalType: 'deposits',
                        createdBy: response.data.createdBy,
                        approvalData: response.data
                    });
                }
           
        } else {
            //Remove if accepted or declined
            if (response.status && (response.data.depositStatus === 'a' || response.data.depositStatus === 'd')) {
                await removeApproval(id);
            }
        }

    } catch (err) {
        console.log('Error Vendor', err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for removing a deposit 
* @param {function} removeDeposit 
*/
export const removeDeposit = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/deposits/${id}`);

        if (response.status) {
            await removeApproval(id);
        }
    } catch (err) {
        console.log('Error Vendor', err);
        throw 'Cannot connect';
    }

    return response;
};