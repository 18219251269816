import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HistoryIcon from '@mui/icons-material/History';
import { useAppContext } from "../../lib/contextLib";
import OrdersTable from './tables/OrdersTable';
//import ProductsTable from './tables/ProductsTable';
import ProductsDispatchTable from './tables/ProductsDispatchTable';
import ProductsDispatchHistoryTable from './tables/ProductsDispatchHistoryTable';
import ProductTransferHistory from './tables/ProductTransferHistory';
import UsersTable from './tables/UsersTable';
import ProductsTable from "../../components/tables/ProductsTable";
import { Text } from '../../services/translator';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(
  {
    products,
    dispatches,
    dispatchesHistory,
    orders,
    users,
    vendor,
    productTransferHistory
  }) {
  const { store, actions, allProducts, pendingProducts } = useAppContext();
  const [value, setValue] = React.useState(0);


  useEffect(async () => {

  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

        <Tabs value={value} onChange={handleChange} aria-label="icon tabs example">
          <Tab label={<Text>Orders</Text>} icon={<ShoppingBasketIcon />} aria-label="orders" {...a11yProps(0)} />
          {/* <Tab label="Products" icon={<LocalOfferIcon />} aria-label="products" {...a11yProps(1)} /> */}
          <Tab label={<Text>Products</Text>} icon={<LocalOfferIcon />} aria-label="products" {...a11yProps(1)} />
          <Tab label={<Text>Dispatch</Text>} icon={<HistoryIcon />} aria-label="dispatch" {...a11yProps(2)} />
          <Tab label={<Text>Users</Text>} icon={<SupervisedUserCircleIcon />} aria-label="users" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrdersTable ordersData={orders} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {vendor.isMainVendor == true ?
          <ProductsTable
            pageTitle={<Text>Vendor Products</Text>}
            showCategory={false}
            productsData={allProducts}
          /> :
          <ProductsDispatchTable products={products} dispatchesData={dispatches} />
        }

      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductsDispatchHistoryTable dispatchesData={dispatchesHistory} />

        <ProductTransferHistory
          productsData={productTransferHistory}
          pageTitle={<Text>Product Transfer History</Text>}
        />

      </TabPanel>
      <TabPanel value={value} index={3}>
        <UsersTable usersData={users} />
      </TabPanel>
    </Box>
  );
}
