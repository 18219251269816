import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Text } from '../../services/translator';
import {
    saveProductCat,
    updateProductCat,
    getProductCat
} from "../../services/product_categories";
import Categories from "../../containers/product_categories/selectList";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CategorySelect({ onSave, selectedId, isDisabled }) {
    const theme = useTheme();
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [showSave, setShowSave] = React.useState(false);
    const [item, setItem] = React.useState({});
    const { userPermissions } = useAppContext();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        onSave(item);
        setOpen(false);
    };

    const handleChange = (data) => {
        setItem(data);
        setShowSave(true);
    };

    React.useEffect(() => {

        async function onLoad() {
            try {
                if(selectedId){
                    const productCat = await getProductCat(selectedId);
                   
                    if(productCat.categoryName){
                        setSelectedCategory(productCat.categoryName);
                    }
    
                }
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [selectedId]);

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<ModeEditIcon />}
                onClick={handleClickOpen}
                disabled={isDisabled}
            >
                {selectedCategory.length > 0 ? selectedCategory : <Text>Select Category</Text>}
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen
                style={{
                    //minWidth: 200, 
                    maxWidth: 350
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Text>Select Category</Text>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Categories
                        onSelect={(item) => {
                            handleChange(item);
                        }}
                        selectedId={selectedId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSave} disabled={showSave ? false : true}>
                        <Text>Save</Text>
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </>
    );
}
