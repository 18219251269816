import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import {
    saveUserPermission,
    updateUserPermission,
} from "../../../services/user_permissions";

export default function PermissionsFormDialog({ btColor, btTitle, id = 0, permission = null}) {

    const {
        actions
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        permissionName: '',
        permissionValue: '',
        permissionDesc: '',
    });

    const [errors, setErrors] = React.useState({
        permissionName: '',
        permissionValue: '',
        permissionDesc: '',
    });

    React.useEffect(() => {
        if (id !== 0) {
            setFormInput(permission);
        }
    }, [permission, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.permissionName === '') {
            newErrors.permissionName = 'Name is required.';
        } else {
            newErrors.permissionName = '';
        }

        if (formInput.permissionValue === '') {
            newErrors.permissionValue = 'Value is required.';
        } else {
            newErrors.permissionValue = '';
        }

        setErrors(newErrors);

        if (
            newErrors.permissionName.length > 0 || newErrors.permissionValue.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {
        let isValid = validateForm();
        if (isValid) {
            let response = null;
            try {
                if (id === 0) {
                    response = await saveUserPermission({ ...formInput });

                    actions.onSetFeedback(true, 'Permission was successfully saved.', 'success');
                }else{
                    
                    response = await updateUserPermission(id, { ...formInput });

                    actions.onSetFeedback(true, 'Permission was successfully updated.', 'success');
                }

                actions.onGetPermissions();
                setOpen(false);
                //history.push(`/settings/`);
            } catch (e) {
                onError(e);
            }
        }

    }
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10 }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Permissions Form"}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >

                            {/* <InputLabel id="demo-simple-select-label">Customer Info</InputLabel> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="permissionName"
                                    label="Name"
                                    value={formInput.permissionName}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="permissionValue"
                                    label="Value"
                                    value={formInput.permissionValue}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="outlined-error"
                                    name="permissionDesc"
                                    label="Description"
                                    value={formInput.permissionDesc}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>


                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleSubmit();
                        }}
                        autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
