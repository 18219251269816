import { API } from "aws-amplify";

/**
* Function for getting all users 
* @param {function} getAllUsers 
*/
export const getAllUsers = async (companyId) => {

    let response;

    try {
//ADD CID
        response = await API.get("adminAPI", "/admin/users/"+companyId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a vendor
* @param {object} data  
* @param {function} saveUser 
*/
export const saveUser = async (data) => {

    let response;

    try {

        delete data.confirmPassword;
        delete data.password;
        
        response = await API.post("adminAPI", "/admin/users", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a user 
* @param {function} updateUser 
*/
export const updateUser = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/users/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a user
* @param {function} getProduct
*/
export const getUser = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/user/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting vendor users
* @param {function} getVendorUsers
*/
export const getVendorUsers = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/users/vendors/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};