const config = {
    STRIPE_KEY: "pk_test_51KfPwRAXPYEP9HHCPcnQawjANJsrPYTsuuw7mGKpMOrtKxacbQ3G9ObICxjYgKL4gxARblsO9KS4CjyvgzN22GLt005kUKt9tZ",
    MAX_ATTACHMENT_SIZE: 5000000,
    // Backend config
    s3: {
        REGION: process.env.REACT_APP_REGION,
        BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },

    // s3: {
    //     REGION: process.env.VITE_REGION,
    //     BUCKET: process.env.VITE_BUCKET,
    // },
    // apiGateway: {
    //     REGION: process.env.VITE_REGION,
    //     URL: process.env.VITE_API_URL,
    // },
    // cognito: {
    //     REGION: process.env.VITE_REGION,
    //     USER_POOL_ID: process.env.VITE_IDENTITY_POOL_ID,
    //     APP_CLIENT_ID: process.env.VITE_USER_POOL_CLIENT_ID,
    //     IDENTITY_POOL_ID: process.env.VITE_IDENTITY_POOL_ID,
    // },
};
export default config;

// environment: {
//     VITE_API_URL: api.url,
//     VITE_REGION: app.region,
//     VITE_BUCKET: bucket.bucketName,
//     VITE_USER_POOL_ID: auth.userPoolId,
//     VITE_USER_POOL_CLIENT_ID: auth.userPoolClientId,
//     VITE_IDENTITY_POOL_ID: auth.cognitoIdentityPoolId || "",
//   },