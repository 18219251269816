import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Text } from '../../services/translator';

export default function BasicSelect({ val, onChange, rows, title}) {
    const [value, setValue] = React.useState('');
    const [data, setData] = React.useState([]);

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
        onChange(event.target.value);
    };

    React.useEffect(() => {
        setValue(val as string);
        setData(rows as (string | number)[]);

    }, [val, rows]);

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ marginTop: 1, backgroundColor: 'white', borderRadius: 2, borderColor: 'white' }}>
                {/* <InputLabel id="demo-simple-select-label">{title}</InputLabel> */}
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={title}
                    onChange={handleChange}
                >
                    {/* <MenuItem key={'-0494kk'} value={null}>{<Text>My Store</Text>}</MenuItem> */}
                    {data.map((v, index) =>
                        <MenuItem key={index} value={v.vendorId}>{v.vendorName} - <small>{v.vendorAddress.city}, {v.vendorAddress.state}</small></MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}