import { Storage } from "aws-amplify";
export async function s3Upload(file) {
    const filename = `${Date.now()}-${file.name}`;
    const stored = await Storage.vault.put(filename, file, {
        level: 'public',
        contentType: file.type,
    });
    //let url = await Storage.vault.get(stored.key, { level: 'public' });
    //console.log('stored ',url);
    return stored.key;
    //return url;
}

export async function s3Remove(file) {
    
    const removed = await Storage.remove(file, { level: 'public' }); 
    //await Storage.remove(file);
    return removed;
}
