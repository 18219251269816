import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import LoaderButton from "../../components/LoaderButton";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function ConfirmDelete({ onConfirm, btTitle, dlTitle, message }) {
    const [open, setOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        setSubmitting(true);
        onConfirm();
        setSubmitting(false);
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <LoaderButton
                style={{ color: 'red' }} 
                // block
                type="submit"
                size="sm"
                variant="outlined"
                isLoading={submitting}
                onClick={handleClickOpen}
            >
                {btTitle}
            </LoaderButton>
            {/* <Button variant="outlined" style={{ color: 'red' }} onClick={handleClickOpen}>
                {btTitle}
            </Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {dlTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                        }}>
                            <Button
                                style={{
                                    //marginLeft: '-15px',
                                    color: 'red',
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleConfirm();
                                }}
                            //autoFocus
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}