import React, { useRef, useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import FeedBack from "../../components/shared/FeedBack";
import SpendingsDialog from "../cashier/dialogs/SpendingsDialog";
import { Text } from '../../services/translator';
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";
import {
    saveCustomer
} from "../../services/customers";
import {
    saveOrder,
    updateOrder,
    getOrder,
    updateOrderItems,
    calculateOrderPrices,
    removeOrderItem,
    updatOrderQuantity,
    placeOrder,
    cancelOrder,
    removeOrder,
    saveTransactions
} from "../../services/orders";
import ProductSearch from "../../components/dialogs/ProductSearch";
import ButtonSelectList from "../../components/buttons/ButtonSelectList";
import OrderInfo from "./OrderInfo";
import FeesForm from "./modals/FeesForm";

import OrderInvoiceDetails from "./OrderInvoiceDetails";


const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function OrderDetailsForm({ formData = null, onHandleSubmit, isLoading = false, onSetIsLoading }) {
    //const file = useRef(null);
    let componentRef = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [orderActions, setOrderActions] = useState([])
   // const [isLoading, setIsLoading] = useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        products,
        customers,
        user,
        taxRate,
        userPermissions,
        vendor,
        companyId
    } = useAppContext();

    const [formInput, setFormInput] = useState({
        vendorId: '',
        customerId: '',
        shippingMethodId: '',
        paymentMethodId: '',
        orderPlaced: false,
        createdDate: new Date(),
        closedDate: '',
        paidDate: '',
        cancelledDate: '',
        orderNumber: '',
        orderStatus: '',
        shippingStatus: '',
        orderItems: [],
        orderTransactions: [],
        shippingAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: 'Dakar',
            zipCode: '',
            country: 'SN'
        },
        billingAddress: {},
        taxRate: taxRate,
        shippingTax: '',
        shippingDiscount: '',
        shippingPrice: '',
        shippingTaxIncluded: '',
        itemTaxIncluded: '',
        closed: false,
        cancelled: false,
        delivered: false,
        paid: false,
        orderHold: false,
        draft: true,
        fullName: '',
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        note: '',
        comments: [],
        trackingNumber: Date.now(),
        coupon: '',
        paymentMethod: '',
        shippingMethod: '',
        browser: {},
        tags: '',
        weightTotal: '',
        discountTotal: '',
        subtotal: '',
        taxIncludedTotal: '',
        taxTotal: '',
        shippingTotal: '',
        grandTotal: '',
        orderCredit: '',
        active: true,
    });

    const [errors, setErrors] = useState({
        vendorId: '',
        customerId: '',
        shippingMethodId: '',
        paymentMethodId: '',
        createdDate: '',
        closedDate: '',
        paidDate: '',
        cancelledDate: '',
        orderNumber: '',
        orderStatus: '',
        shippingStatus: '',
        orderItems: [],
        orderTransactions: [],
        shippingAddress: {},
        billingAddress: {},
        shippingTax: '',
        shippingDiscount: '',
        shippingPrice: '',
        shippingTaxIncluded: '',
        itemTaxIncluded: '',
        closed: false,
        cancelled: false,
        delivered: false,
        paid: false,
        orderHold: false,
        draft: true,
        fullName: '',
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        note: '',
        comments: '',
        trackingNumber: '',
        coupon: '',
        paymentMethod: '',
        shippingMethod: '',
        browser: '',
        tags: '',
        weightTotal: '',
        discountTotal: '',
        subtotal: '',
        taxIncludedTotal: '',
        taxTotal: '',
        shippingTotal: '',
        grandTotal: '',
        active: true,

    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function handleInputChange(event) {
        let { name, value } = event.target;
        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        updateErrors(name);
    }

    function handleCustomerChange(formData) {
        setFormInput(formData);
    }

    useEffect(() => {
        onLoad(formData);
    }, [formData]);

    useEffect(() => {

        let newOrderActions = [];

        if (formInput.draft && !formInput.orderHold && !formInput.cancelled) {
            newOrderActions.push({ name: 'Hold order', value: 'hold' });
        }

        if (formInput.draft && formInput.orderHold && !formInput.cancelled) {
            newOrderActions.push({ name: 'Resume order', value: 'resume' });
        }

        if (formInput.orderPlaced && !formInput.closed && !formInput.cancelled) {
            newOrderActions.push({ name: 'Close order', value: 'close' });
        }

        if (!formInput.closed && !formInput.cancelled) {
            newOrderActions.push({ name: 'Cancel order', value: 'cancel' });
        }

        if (!formInput.closed && !formInput.orderHold && !formInput.orderPlaced) {
            newOrderActions.push({ name: 'Delete order', value: 'delete' });
        }

        setOrderActions(newOrderActions);

    }, [formInput]);

    async function onLoad(orderData) {
        try {
            onSetIsLoading(true);
            let newFormInput = { ...formInput };
            let order = null;
            if (orderData === undefined) {
                order = await getOrder(id);
            } else {
                order = orderData;
            }

            const {
                vendorId,
                customerId,
                shippingMethodId,
                paymentMethodId,
                orderPlaced,
                createdDate,
                closedDate,
                paidDate,
                cancelledDate,
                orderNumber,
                orderStatus,
                shippingStatus,
                orderItems,
                orderTransactions,
                shippingAddress,
                billingAddress,
                shippingTax,
                shippingDiscount,
                shippingPrice,
                shippingTaxIncluded,
                itemTaxIncluded,
                closed,
                cancelled,
                delivered,
                paid,
                orderHold,
                draft,
                fullName,
                email,
                phoneCountryCode,
                phoneNumber,
                note,
                comments,
                trackingNumber,
                coupon,
                paymentMethod,
                shippingMethod,
                browser,
                tags,
                weightTotal,
                discountTotal,
                subtotal,
                taxIncludedTotal,
                taxTotal,
                shippingTotal,
                grandTotal,
                orderCredit,
                active,
            } = order;

            newFormInput.vendorId = vendorId;
            newFormInput.customerId = customerId;
            newFormInput.shippingMethodId = shippingMethodId;
            newFormInput.paymentMethodId = paymentMethodId;
            newFormInput.orderPlaced = orderPlaced;
            newFormInput.createdDate = createdDate;
            newFormInput.closedDate = closedDate;
            newFormInput.paidDate = paidDate;
            newFormInput.cancelledDate = cancelledDate;
            newFormInput.orderNumber = orderNumber;
            newFormInput.orderStatus = orderStatus;
            newFormInput.shippingStatus = shippingStatus;
            newFormInput.orderItems = orderItems || [];
            newFormInput.orderTransactions = orderTransactions || [];
            newFormInput.shippingAddress = shippingAddress || newFormInput.shippingAddress;
            newFormInput.billingAddress = billingAddress || newFormInput.billingAddress;
            newFormInput.taxRate = taxRate;
            newFormInput.shippingTax = shippingTax;
            newFormInput.shippingDiscount = shippingDiscount;
            newFormInput.shippingPrice = shippingPrice;
            newFormInput.shippingTaxIncluded = shippingTaxIncluded;
            newFormInput.itemTaxIncluded = itemTaxIncluded;
            newFormInput.closed = closed;
            newFormInput.cancelled = cancelled;
            newFormInput.delivered = delivered;
            newFormInput.paid = paid;
            newFormInput.orderHold = orderHold;
            newFormInput.draft = draft;
            newFormInput.fullName = fullName;
            newFormInput.email = email;
            newFormInput.phoneCountryCode = phoneCountryCode || '';
            newFormInput.phoneNumber = phoneNumber;
            newFormInput.note = note;
            newFormInput.comments = comments || [];
            newFormInput.trackingNumber = trackingNumber;
            newFormInput.coupon = coupon;
            newFormInput.paymentMethod = paymentMethod;
            newFormInput.shippingMethod = shippingMethod;
            newFormInput.browser = browser || {};
            newFormInput.tags = tags;
            newFormInput.weightTotal = weightTotal;
            newFormInput.discountTotal = discountTotal;
            newFormInput.subtotal = subtotal;
            newFormInput.taxIncludedTotal = taxIncludedTotal;
            newFormInput.taxTotal = taxTotal;
            newFormInput.shippingTotal = shippingTotal;
            newFormInput.grandTotal = grandTotal;
            newFormInput.orderCredit = orderCredit;
            newFormInput.active = active;

            setFormInput(newFormInput);
            actions.onGetProducts(vendor.vendorId, companyId);
            onSetIsLoading(false);

        } catch (e) {
            onError(e);
        }
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        updateErrors(name);

    }

    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...formInput.shippingAddress,
            [name]: value
        };

        setFormInput({
            ...formInput,
            newAddressInput
        });

    }

    async function handleOrderItems(item) {
        try {

            let response = await updateOrderItems(formInput.orderItems, item, vendor.vendorId);

            let { success, errorMessage, orderItems } = response;

            if (success) {
                let newFormInput = {
                    ...formInput,
                    ['orderItems']: orderItems
                };

                let newOrder = await calculateOrderPrices(newFormInput);

                setFormInput(newOrder);

                onHandleSubmit(newOrder);

            } else {
                onError(errorMessage);
            }

        } catch (e) {
            onError(e);
        }

    }


    async function updatOrderQTY(item, input) {
        try {

            let response = await updatOrderQuantity(formInput.orderItems, item, input, vendor.vendorId, products);

            let { success, errorMessage, orderItems } = response;

            if (success) {
                let newFormInput = {
                    ...formInput,
                    ['orderItems']: orderItems
                };

                let newOrder = await calculateOrderPrices(newFormInput);

                setFormInput(newOrder);

                onHandleSubmit(newOrder);

            } else {
                onError(errorMessage);
            }

        } catch (e) {
            onError(e);
        }

    }

    async function updatOrderInfo() {
        try {

            let newOrder = await calculateOrderPrices(formInput);

            setFormInput(newOrder);

            onHandleSubmit(newOrder);

        } catch (e) {
            onError(e);
        }

    }

    async function removeOrderItems(item) {
        try {

            let response = await removeOrderItem(formInput.orderItems, item);

            let { success, errorMessage, orderItems } = response;

            if (success) {
                let newFormInput = {
                    ...formInput,
                    ['orderItems']: orderItems
                };

                let newOrder = await calculateOrderPrices(newFormInput);

                setFormInput(newOrder);

                onHandleSubmit(newOrder);

            } else {
                onError(errorMessage);
            }

        } catch (e) {
            onError(e);
        }

    }

    async function placeOrderNow() {
        try {

            let response = await placeOrder(formInput, formInput.orderItems, products);

            let { success, errorMessage, order, orderItems } = response;

            if (success) {
                let newFormInput = {
                    ...order,
                    ['orderItems']: orderItems
                };

                setFormInput(newFormInput);

                onHandleSubmit(newFormInput);
                actions.onGetOrders(vendor.vendorId);
                actions.onGetProducts(vendor.vendorId, companyId);

            } else {
                onError(errorMessage);
            }

        } catch (e) {
            onError(e);
        }

    }

    async function onSaveTransactions(fullyPaid, transactions) {
        try {

            let response = await saveTransactions(fullyPaid, transactions, formInput, user.userId);

            if (response === false) {
                onError('Please double check the amount.');
                return false;
            } else {
                onHandleSubmit(response);
            }

        } catch (e) {
            onError(e);
        }

    }

    async function linkCustomer(data) {
        await actions.onGetCustomers(companyId);

        let customerId = null;
        customers.filter(element => element.customerId === data.customerId)
            .map(element => customerId = element);

        if (customerId == null) {
            let response = await saveCustomer({
                companyId,
                fullName: data.fullName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                totalSpent: 0,
                totalOrders: 0,
                active: true,
            });

            customerId = response;
        }

        return customerId;
    }

    async function orderStatusUpdate(data, type) {

        try {
            if (type == 'close') {
                data.closed = true;
                data.closedDate = Date.now();

                onHandleSubmit(data);
            } else if (type == 'cancel') {

                if (data.orderPlaced) {
                    let res = await cancelOrder(data, data.orderItems, products);
                    if (res.success) {
                        res.order.draft = false;
                        res.order.cancelled = true;
                        res.order.cancelledDate = Date.now();

                        onHandleSubmit(res.order);
                    }
                } else {
                    data.draft = false;
                    data.cancelled = true;
                    data.cancelledDate = Date.now();

                    onHandleSubmit(data);
                }

            } else if (type == 'hold') {
                data.orderHold = true;
                await onHandleSubmit(data);
            } else if (type == 'resume') {
                data.orderHold = false;
                await onHandleSubmit(data);
            } else if (type == 'delete') {

                if (data.orderPlaced) {

                    let res = await cancelOrder(data, data.orderItems, products);
                    if (res.success) {
                        let response = await removeOrder(id);
                        if (response.status) {
                            actions.onSetFeedback(true, 'Order was successfully removed.', 'success');
                            actions.onGetOrders(vendor.vendorId);
                            actions.onGetProducts(vendor.vendorId, companyId);
                            history.push(`/a_portal/orders`);
                        }
                    }

                } else {
                    let response = await removeOrder(id);
                    if (response.status) {
                        actions.onSetFeedback(true, 'Order was successfully removed.', 'success');

                        // setShowFeedback(true);
                        // setFeedbackMessage('Order was successfully removed.');
                        // setFeedbackType('success');
                        actions.onGetOrders(vendor.vendorId);
                        actions.onGetProducts(vendor.vendorId, companyId);
                        history.push(`/a_portal/orders`);

                    }
                }
            }
        } catch (e) {
            onError(e);
        }

    }
    useEffect(() => {
        actions.onSetSelected('orders');
    }, []);

    return (
        <div className="orderDetailsForm" >
            {/* <Divider />
            <Divider>ORDER PAGE</Divider>
            <Divider /> */}

            {isLoading == false ?
                <Grid
                    container
                    spacing={2}
                    style={{
                        //marginTop: 5
                    }}>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            flexGrow: 1,
                            marginTop: 2,
                            // marginBottom: 2
                        }}
                            style={styles.whiteBox}
                        >
                            <h2 className="pb-3 mb-3 border-bottom"> {<Text>Order Details Page</Text>}</h2>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    //marginTop: 5
                                }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={1}>
                                        {formInput.draft ? <Chip label={<Text>Draft</Text>} /> : null}
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    {formInput.cancelled ? <Chip label={<Text>Cancelled</Text>} color="error" /> :
                                        formInput.closed ? <Chip label={<Text>Closed</Text>} color="success" /> :
                                            formInput.orderHold ? <Chip label={<Text>Hold</Text>} /> :
                                                formInput.orderPlaced ? <Chip label={<Text>Placed</Text>} color="primary" /> :
                                                    !formInput.orderPlaced ?
                                                        <ProductSearch
                                                            buttonName={<Text>Add Product</Text>}
                                                            cancelButtonName={<Text>Cancel</Text>}
                                                            saveButtonName={<Text>Add</Text>}
                                                            onSave={(pro) => {
                                                                handleOrderItems(pro);
                                                            }}
                                                        />
                                                        : null
                                    }


                                </Grid>
                                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', marginBottom: 10 }}>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">

                                        {!formInput.closed && !formInput.cancelled && !formInput.orderHold && formInput.orderItems.length > 0 && (userPermissions.admin || userPermissions.deposit_write) ?
                                            <SpendingsDialog
                                                btColor={'primary'}
                                                btTitle={<Text>Manage Outputs</Text>}
                                                orderId={id}
                                                orderNumber={formInput.orderNumber}
                                            />
                                            : null}

                                        {formInput.orderPlaced && !formInput.cancelled && !formInput.orderHold &&
                                            <FeesForm
                                                order={formInput}
                                                style={{ marginRight: '10px' }}
                                                rows={formInput.orderTransactions}
                                                onSave={onSaveTransactions}
                                            />
                                        }

                                        {!formInput.orderPlaced && !formInput.cancelled && !formInput.orderHold && formInput.orderItems.length > 0 && <Button
                                            variant="contained"
                                            onClick={() => {
                                                placeOrderNow();
                                            }}
                                        >
                                            Place order
                                        </Button>}
                                        {orderActions.length > 0 &&
                                            <ButtonSelectList
                                                options={orderActions}
                                                onOptionClick={(value) => {
                                                    orderStatusUpdate(formInput, value);
                                                }}
                                            />
                                        }

                                    </ButtonGroup>
                                </Grid>

                            </Grid>

                            <Divider />
                            <Divider />

                            <OrderInfo
                                //key={privateKey}
                                formInput={formInput}
                                onHandleCustomerChange={handleCustomerChange}
                                onHandleChange={handleInputChange}
                                onHandleAddressChange={handleAddressChange}
                                onSave={async () => {
                                    let newOrder = await calculateOrderPrices(formInput);

                                    setFormInput(newOrder);

                                    onHandleSubmit(newOrder);
                                }}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>

                        <Box sx={{
                            flexGrow: 1,
                            //marginTop: 2,
                            marginBottom: 2
                        }}
                            style={styles.whiteBox}
                        >
                            <Grid item xs={12} sm={12} md={12}>

                                <div ref={el => (componentRef = el)} >
                                    <OrderInvoiceDetails
                                        formInput={formInput}
                                        onRemove={(item) => {
                                            removeOrderItems(item);
                                        }}
                                        onHandleSave={(inputs, item) => {
                                            updatOrderQTY(item, inputs);
                                        }}
                                    />

                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <ReactToPrint
                                    trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return (
                                            <Button
                                                //color="primary"
                                                style={{ flex: 1, marginLeft: 20 }}
                                                color="success"
                                                variant="outlined"
                                                size="small"
                                                startIcon={<PrintIcon />}
                                            >
                                                {<Text>Print Out Invoice</Text>}
                                            </Button>
                                        );
                                    }}
                                    content={() => componentRef}
                                />

                            </Grid>


                        </Box>
                    </Grid>

                </Grid>
                : null}
            {showFeedback &&
                <FeedBack
                    message={feedbackMessage}
                    type={feedbackType}
                />
            }
        </div>
    );
}
