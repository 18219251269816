import React, { useRef, useState, useEffect, useCallback } from "react";
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";
import { Text } from '../../services/translator';
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";


export default function ProductTagsInput(props) {
    const { onChange, id } = props;
    const [productTags, setProductTags] = useState([]);
    const { userHasAuthenticated, store, actions, userPermissions } = useAppContext();

    useEffect(() => {
        async function onLoadTags() {
            try {

                const product = await getProduct(id);

                const {
                    productTags
                } = product;

                setProductTags(productTags || []);

            } catch (e) {
                onError(e);
            }
        }

        onLoadTags();
    }, [id]);

    const onTagsChange = (event, values) => {
        setProductTags(values);
        onChange(values);
    }

    return (
        <Autocomplete
            key={new Date()}
            filterSelectedOptions
            multiple
            id="tags-filled"
            options={productTags.map((option) => option)}
            defaultValue={productTags}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            onChange={onTagsChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={<Text>Tags</Text>}
                    placeholder={<Text>Tags</Text>}
                    disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                />
            )}
        />
    );
};
