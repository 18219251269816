import { API } from "aws-amplify";
import {
    updateSetting,
    getSetting
} from "./settings";

/**
* Function for getting all Customers 
* @param {function} getAllCustomers
*/
export const getAllCustomers = async (companyId) => {

    let response;

    try {
        
        response = await API.get("adminAPI", "/admin/customers/" + companyId);

    } catch (err) {
        throw 'getAllCustomers - Cannot connect';
    }

    return response;
};

/**
* Function for getting a Customer
* @param {function} getCustomer
*/
export const getCustomer = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/customer/${id}`);

    } catch (err) {
        throw 'getCustomer - Cannot connect';
    }

    return response;
};

/**
* Function for updating a Customer
* @param {function} updateCustomer
*/
export const updateCustomer = async (id, data) => {
    let response;

    try {

        response = await API.put("adminAPI", `/admin/customers/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'updateCustomer - Cannot connect';
    }

    return response;
};

/**
* Function for saving  a Customer
* @param {object} data  
* @param {function} saveCustomer
*/
export const saveCustomer = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/customers", {
            body: data
        });

        let settings = await getSetting();
        settings.lastCustomerNumber = +settings.lastCustomerNumber + 1;
        await updateSetting({ ...settings });
        
    } catch (err) {
        throw 'saveCustomer - Cannot connect';
    }

    return response;
};
