import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/users/Login";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";
import Settings1 from "./containers/Settings";
import Signup from "./containers/users/Signup";
import AdminSignup from "./containers/users/AdminSignup";

import ConfirmAccount from "./containers/users/ConfirmAccount";
import ForgotPass from "./containers/users/ForgotPass";
import NewPass from "./containers/users/NewPass";

import Users from "./containers/users/Users";
import Vendors from "./containers/vendors/Vendors";
import VendorForm from "./containers/vendors/VendorForm";
import VendorDetails from "./containers/vendors/VendorDetails";
import CreateRoleForm from "./containers/user_roles/CreateRoleForm";
import UserRoles from "./containers/user_roles/UserRoles";
import Products from "./containers/products/Products";
import ProductForm from "./containers/products/ProductForm";
import ProductDetailsForm from "./containers/products/ProductDetailsForm";
import ProductCategories from "./containers/product_categories/ProductCats";
import ProductCatEditForm from "./containers/product_categories/ProductCatEditForm";
import ProductCatForm from "./containers/product_categories/ProductCatForm";
import Orders from "./containers/orders/Orders";
import Cashier from "./containers/cashier/Cashier";
import OrderDetailsForm from "./containers/orders";
import Settings from "./containers/settings/Settings";

import Customers from "./containers/customers/Customers";
import CustomerDetailsForm from "./containers/customers/CustomerDetailsForm";

import Suppliers from "./containers/suppliers/Suppliers";
import PandingApprovals from "./containers/approvals";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
    return (
        <Switch>

            <AuthenticatedRoute exact path="/a_portal">
                <Home />
            </AuthenticatedRoute>
            <UnauthenticatedRoute exact path="/a_portal/login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/a_portal/confirm-account">
                <ConfirmAccount />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/a_portal/forgot-password">
                <ForgotPass />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signup">
                <Signup />
            </UnauthenticatedRoute>

            <UnauthenticatedRoute exact path="/a_portal/new-password">
                <NewPass />
            </UnauthenticatedRoute>
            <AuthenticatedRoute exact path="/a_portal/settings1">
                <Settings1 />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/notes/new">
                <NewNote />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/notes/:id">
                <Notes />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/vendors/form">
                <VendorForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/vendors">
                <Vendors />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/vendors/:id">
                <VendorDetails />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/user_roles/create">
                <CreateRoleForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/user_roles">
                <UserRoles />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/user_roles/:id">
                <CreateRoleForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/users/signup">
                <Signup />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/a_portal/users/admin_signup">
                <AdminSignup />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/users">
                <Users />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/users/:id">
                <Signup />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/products">
                <Products />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/products/create">
                <ProductForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/products/:id">
                <ProductDetailsForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/product_categories">
                <ProductCategories />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/product_categories/create">
                <ProductCatForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/product_categories/:id">
                <ProductCatEditForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/orders">
                <Orders />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/orders/:id">
                <OrderDetailsForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/cashier">
                <Cashier />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/settings">
                <Settings />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/customers">
                <Customers />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/customers/:id">
                <CustomerDetailsForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/suppliers">
                <Suppliers />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/a_portal/pending_approvals">
                <PandingApprovals />
            </AuthenticatedRoute>
            
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}