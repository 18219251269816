// Create our number formatter.


  export const priceFormat = (price) => {

    var formatter = new Intl.NumberFormat('fr', {
        style: 'currency',
        currency: 'CFA',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
     
    return formatter.format(price);
};

export function getVendorName(vendors, vendorId) {
  let name = '';
  if (vendors && vendorId) {
      vendors
          .filter(item => item.vendorId == vendorId)
          .map(item => {
              //  alert(item.categoryName);
              name = item.vendorName;
          });
  }
  return name;
}

export function getCatName(productCategories, categoryId) {
  let name = '';
  if (productCategories && categoryId) {
      productCategories
          .filter(item => item.categoryId == categoryId)
          .map(item => {
              //  alert(item.categoryName);
              name = item.categoryName;
          });
  }
  return name;
}

export function getUserName(users, userId) {
  let name = '';
  if (users && userId) {
    users.filter(item => item.userId == userId)
          .map(item => {
              name = item.firstName +' '+ item.lastName;
          });
  }
  return name;
}

export function getSpendingCatName(cats, spendingId) {
  let name = '';
  if (cats && spendingId) {
    cats.filter(item => item.spendingCategoryId == spendingId)
          .map(item => {
              name = item.spendingCategoryName;
          });
  }
  return name;
}

export function getSupplierName(suppliers, supplierId) {
  let name = '';
  if (suppliers && supplierId) {
    suppliers.filter(item => item.supplierId == supplierId)
          .map(item => {
              name = item.supplierName;
          });
  }
  return name;
}

export function getBankAccountName(bankAccounts, accountId){
  let name = '';
  if (bankAccounts && accountId) {
    bankAccounts
      .filter(item => item.accountId == accountId)
      .map(item => {
        name =  item.bankName + ' - ' + item.accountName;
      });
  }
  return name;
};

export function getCusAccountNumber(customers, customerId){
  let value = '';
  if (customers && customerId) {
    customers
      .filter(item => item.customerId == customerId)
      .map(item => {
        value = item.customerNumber;
      });
  }
  return value;
};



