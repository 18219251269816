import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import "./ConfirmAccount.css";

export default function ConfirmAccount() {
    const history = useHistory();
    const location = useLocation();
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmationCode: "",
    });

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(location.state.email);
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.confirmSignUp(location.state.email, fields.confirmationCode);

            history.push("/a_portal/login");
            setIsLoading(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="Login">

            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId="confirmationCode" size="lg">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <Form.Text muted>Please check your email for the code.</Form.Text>
                </Form.Group>

                <div className="d-grid gap-2">
                    <Button
                        block
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                            resendConfirmationCode();
                        }}
                    >
                        Resend Code
                    </Button>
                    <LoaderButton
                        block
                        size="lg"
                        type="submit"
                        variant="success"
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm()}
                    >
                        Verify
                    </LoaderButton>

                </div>
                {/* <Button variant="outline-primary">Resend Code</Button> */}
            </Form>
        </div>
    );
}