import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
// import Avatar from '@mui/material/Avatar';
// import Chip from '@mui/material/Chip';
// import CardMedia from '@mui/material/CardMedia';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Text } from '../../../services/translator';
import { Colors } from '../../../lib/colors';
import {
    priceFormat
} from "../../../services/shared";
import CustomerCreditsDialog from "../dialogs/CustomerCreditsDialog";
// import { Divider } from '@mui/material';

export default function Statistics({ stats, customerCredits }) {
    const [openDialog, setOpenDialog] = React.useState(false);
    // const theme = useTheme();

    return (

        <Grid container item spacing={1} style={{ textAlign: 'center' }}>

            <Grid item xs={12} sm={12} md={12}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.green,
                        color: Colors.white
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                <Text>Cashier Balance</Text>
                            </Typography>

                            <Typography variant="subtitle1" color="white" component="div" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                {priceFormat(stats.cashier_balance)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={6} md={6}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.darkBlue,
                        color: Colors.white
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                <Text>Amounts</Text>
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {priceFormat(stats.total_amount)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={6} md={6}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.blue,
                        color: Colors.white
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                <Text>Exits</Text>
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {/* { priceFormat(stats.totalSalesToday)} */}
                                {priceFormat(stats.spending_total)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={6} md={6}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <CustomerCreditsDialog
                        btTitle={<Text>Total Credit</Text>}
                        customerCredits={customerCredits}
                        onClose={() => {
                            setOpenDialog(false);
                        }}
                        openDialog={openDialog}
                    />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.red,
                        color: Colors.white
                    }}
                        onClick={() => {
                         //   setOpenDialog(true);
                        }}
                    >
                        <CardContent sx={{ flex: '1 0 auto' }}>

                            <Typography component="div" variant="h6">
                                <Text>Total Credit</Text>
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {/* { priceFormat(stats.totalSalesThisMonth)} */}
                                {priceFormat(stats.total_credit)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={6} md={6}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.blueGrey300,
                        color: Colors.white
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                <Text>DEPOSITS</Text>
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {/* { priceFormat(stats.totalSalesThisMonth)} */}
                                {priceFormat(stats.deposit_total)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={12} md={12}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.blueGrey300,
                        color: Colors.white
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                <Text>Shipping Cost</Text>
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {/* { priceFormat(stats.totalSalesThisMonth)} */}
                                {priceFormat(stats.shipping_total)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>


        </Grid>


    );
}
