import { API } from "aws-amplify";

/**
* Function for getting a bank account
* @param {function} getBankAccount 
*/
export const getBankAccount = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/bank_account/"+id);
    } catch (err) {
        throw 'getBankAccount - Cannot connect';
    }

    return response;
};

/**
* Function for getting all bank accounts 
* @param {function} getAllBankAccounts 
*/
export const getAllBankAccounts = async (companyId) => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/bank_accounts/"+companyId);

    } catch (err) {
        throw 'getAllBankAccounts - Cannot connect';
    }

    return response;
};

/**
* Function for saving a bank account
* @param {object} data  
* @param {function} saveBankAccount 
*/
export const saveBankAccount = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/bank_accounts", {
            body: data
        });

    } catch (err) {
        throw 'saveBankAccount - Cannot connect';
    }

    return response;
};

/**
* Function for updating a bank account 
* @param {function} updateBankAccount 
*/
export const updateBankAccount = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/bank_accounts/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'updateBankAccount - Cannot connect';
    }

    return response;
};

/**
* Function for removing a bank account 
* @param {function} removeBankAccount 
*/
export const removeBankAccount = async (id, data) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/bank_accounts/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'removeBankAccount - Cannot connect';
    }

    return response;
};

/**
* Function for getting bank account name 
* @param {function} getBankAccountName 
*/
export const getBankAccountName = async (accountId, bankAccounts) => {

    let name = '';
    if (bankAccounts && accountId) {
      bankAccounts
        .filter(item => item.accountId == accountId)
        .map(item => {
          name =  item.bankName + ' - ' + item.accountName;
        });
    }
    return name;
};

