import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";


// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
// ];

export default function ProductAttributesTable({ rows, onSaveAttribute, onRemoveAttribute, isDisabled = false }) {
    const {
        userPermissions
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        name: '',
        value: '',
    });

    const handleClickOpen = () => {
        let newFormInput = {
            ...formInput,
            ['name']: '',
            ['value']: ''
        };

        setFormInput(newFormInput);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {

        onSaveAttribute(formInput);

        setOpen(false);
    };

    const handleRemove = (item) => {

        onRemoveAttribute(item);

        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);

    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <caption> <div>
                        {!isDisabled && <Button variant="outlined" onClick={handleClickOpen} disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}>
                            <Text>ADD NEW ATTRIBUTE</Text>
                        </Button>}
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle><Text>Add Attribute</Text></DialogTitle>
                            <DialogContent>

                                <TextField
                                    autoFocus
                                    id="name"
                                    name="name"
                                    label={<Text>Attribute Name</Text>}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={formInput.name}
                                    onChange={handleInputChange}
                                    disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                />

                                <TextField
                                    //autoFocus
                                    id="value"
                                    name="value"
                                    label={<Text>Attribute Value</Text>}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={formInput.value}
                                    onChange={handleInputChange}
                                    disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}><Text>Cancel</Text></Button>
                                <Button
                                    disabled={formInput.name.length > 0 && formInput.value.length > 0 ? false : true}
                                    onClick={handleSave}
                                ><Text>Save</Text></Button>
                            </DialogActions>
                        </Dialog>
                    </div></caption>
                    <TableHead>
                        <TableRow>
                            <TableCell><Text>Attribute Name</Text></TableCell>
                            <TableCell align="right"><Text>Attribute Value</Text></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                                <TableCell align="right">
                                    {!isDisabled && <IconButton
                                        color="error"
                                        aria-label="remove attribute"
                                        component="span"
                                        onClick={() => {
                                            handleRemove(row);
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}
