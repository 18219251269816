import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoaderButton from "../../../components/LoaderButton";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { Text } from '../../../services/translator';
import ConfirmDelete from "../../../components/dialogs/ConfirmDelete";
import { Colors } from '../../../lib/colors';
import {
    saveDeposit,
    updateDeposit,
    removeDeposit
} from '../../../services/deposits';

export default function DepositsDialog({ btColor, btTitle, id = 0, deposit = null }) {

    const {
        actions,
        vendor,
        bankAccounts,
        deposits,
        user,
        paymentMethods,
        transStatuses,
        userPermissions,
        companyId,
        companyYear
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        active: true,
        vendorId: '',
        accountId: '',
        depositedAmount: '',
        depositedDate: new Date(),
        depositStatus: 'p',
        depositedBy: '',
        createdBy: '',
        depositType: '',
        depositMethod: '',
        depositNote: '',
        lastUpdatedBy: '',
    });

    const [errors, setErrors] = React.useState({
        vendorId: '',
        accountId: '',
        depositedDate: '',
        depositedBy: '',
        depositedAmount: '',
        createdBy: '',
        depositType: '',
        depositMethod: '',
        depositNote: '',
        lastUpdatedBy: '',
    });

    React.useEffect(() => {
        if (id !== 0) {
            setFormInput(deposit);
        }
    }, [deposit, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.depositedDate === '') {
            newErrors.depositedDate = 'Deposited date is required.';
        } else {
            newErrors.depositedDate = '';
        }

        if (formInput.depositedBy === '') {
            newErrors.depositedBy = 'Deposited by is required.';
        } else {
            newErrors.depositedBy = '';
        }

        if (formInput.depositedAmount === '') {
            newErrors.depositedAmount = 'Deposited amount is required.';
        } else {
            newErrors.depositedAmount = '';
        }

        if (formInput.depositMethod === '') {
            newErrors.depositMethod = 'Method is required.';
        } else {
            newErrors.depositMethod = '';
        }

        setErrors(newErrors);

        if (
            newErrors.depositedDate.length > 0 ||
            newErrors.depositedBy.length > 0 ||
            newErrors.depositedAmount.length > 0 ||
            newErrors.depositMethod.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {

        let isValid = validateForm();
        if (isValid) {
            setSubmitting(true);
            let response = null;

            try {
                if (id === 0) {
                    formInput.vendorId = vendor.vendorId;
                    formInput.createdBy = user.userId;
                    formInput.lastUpdatedBy = user.userId;

                    response = await saveDeposit({ ...formInput });

                    actions.onSetFeedback(true, 'Deposit was successfully saved.', 'success');
                } else {
                    formInput.lastUpdatedBy = user.userId;
                    response = await updateDeposit(id, { ...formInput });

                    actions.onSetFeedback(true, 'Deposit was successfully updated.', 'success');
                }

                actions.onGetOrders(vendor.vendorId);
                actions.onGetApprovals(companyId, companyYear);
                setOpen(false);
                setSubmitting(false);
            } catch (e) {
                onError(e);
            }

        }

    }

    async function handleRemove() {

        let response = null;
        try {
            setSubmitting(true);
            response = await removeDeposit(id);

            actions.onSetFeedback(true, 'Bank Account was successfully removed.', 'success');

            actions.onGetOrders(vendor.vendorId);
            setOpen(false);
            setSubmitting(false);
        } catch (e) {
            onError(e);
        }

    }
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10, backgroundColor: Colors.blueGrey300  }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {id !== 0 ? <Text>Update Deposit</Text> : <Text>Add Deposit</Text>}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Status</Text></InputLabel>
                                    <Select
                                        disabled={true}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="depositStatus"
                                        value={formInput.depositStatus}
                                        label={<Text>Status</Text>}
                                        onChange={handleInputChange}
                                    >
                                        {transStatuses.map((s, index) =>
                                            <MenuItem key={index} value={s.value}>{s.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Deposit Method re</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="depositMethod"
                                        value={formInput.depositMethod}
                                        label={<Text>Deposit Method re</Text>}
                                        onChange={handleInputChange}
                                    >
                                        {paymentMethods.map((m, index) =>
                                            <MenuItem key={index} value={m}>{m}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {formInput.depositMethod == 'Bank Deposit' &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Bank Account</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="accountId"
                                            value={formInput.accountId}
                                            label={<Text>Bank Account</Text>}
                                            onChange={handleInputChange}
                                        >
                                            {bankAccounts.map((bank, index) =>
                                                <MenuItem key={index} value={bank.accountId}>{bank.accountName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="depositedAmount"
                                    label={<Text>Deposited Amount re</Text>}
                                    value={formInput.depositedAmount}
                                    helperText={errors.depositedAmount}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    //id="outlined-error"
                                    name="depositedDate"
                                    id="date"
                                    type={'date'}
                                    label={<Text>Deposited Date re</Text>}
                                    value={formInput.depositedDate}
                                    helperText={errors.depositedDate}
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="depositedBy"
                                    label={<Text>Deposited By re</Text>}
                                    value={formInput.depositedBy}
                                    helperText={errors.depositedBy}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="depositNote"
                                    name="depositNote"
                                    label={<Text>Note</Text>}
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    fullWidth
                                    defaultValue={formInput.depositNote}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            {id !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <ConfirmDelete
                                    btTitle={<Text>Remove</Text>}
                                    dlTitle={<Text>Confirm Remove</Text>}
                                    message={<Text>Are you sure</Text>}
                                    onConfirm={handleRemove}
                                />
                            </Grid>}

                        </Grid>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button onClick={handleClose}><Text>Cancel</Text></Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                        }}>

                            <LoaderButton
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                // block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={submitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                <Text>Save</Text>
                            </LoaderButton>

                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
