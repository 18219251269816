import { API } from "aws-amplify";

/**
* Function for getting a spending category
* @param {function} getSpendingCategory
*/
export const getSpendingCategory= async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/spending_category/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all spending categorys 
* @param {function} getAllSpendingCategories 
*/
export const getAllSpendingCategories = async (companyId) => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/spending_categories/"+companyId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a spending category
* @param {object} data  
* @param {function} saveSpendingCategories
*/
export const saveSpendingCategories= async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/spending_categories", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a spending category 
* @param {function} updateSpendingCategories
*/
export const updateSpendingCategories= async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/spending_categories/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for removing a spending category 
* @param {function} removeSpendingCategories
*/
export const removeSpendingCategories= async (id, data) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/spending_categories/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting spending category name 
* @param {function} getSpendingCategoryName 
*/
export const getSpendingCategoryName = async (spendingCategoryId, categories) => {

    let name = '';
    if (categories && spendingCategoryId) {
        categories
        .filter(item => item.spendingCategoryId == spendingCategoryId)
        .map(item => {
          name =  item.spendingCategoryName;
        });
    }
    return name;
};

