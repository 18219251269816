import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../../lib/contextLib";
import { Text } from '../../../services/translator';
import DepositsDialog from "../dialogs/DepositsDialog";
import SpendingsDialog from "../dialogs/SpendingsDialog";
import {
  priceFormat,
  getSpendingCatName,
  getUserName
} from "../../../services/shared";
import { Colors } from '../../../lib/colors';

var moment = require('moment'); // require

export default function SpendingsList({ spendings }) {
  const history = useHistory();
  const { users, spendingCategories, userPermissions } = useAppContext();
  return (
    <List sx={{
      width: '100%',
      maxWidth: 360,
      bgcolor: 'background.paper',
      position: 'relative',
      overflow: 'auto',
      maxHeight: 360
    }}>
      {userPermissions.admin || userPermissions.deposit_write | userPermissions.deposit_read ? <>
        {spendings.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((spe, index) => (
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={"Spending# " + (index + 1)}
              secondary={
                <React.Fragment>

                  {spe.orderNumber && spe.orderNumber !== null &&
                    <>
                      <Typography
                        sx={{ display: 'inline', color: Colors.blue, fontSize: 20 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >

                        <b style={{}}><b><Text>Order Number</Text>: </b><a href="#" onClick={() => {
                          history.push(`/a_portal/orders/${spe.orderId}`);
                        }}> {' ' + spe.orderNumber}</a></b>
                      </Typography>

                      <br />
                    </>
                  }


                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b> <Text>Spending Type</Text>:</b>
                  </Typography>
                  {' '} {
                  spe.spendingType === 'loan' ? <Text>loan</Text> :
                  spe.spendingType === 'vendor' ? <Text>vendor</Text> : 
                  spe.spendingType === 'vehicle' ? <Text>vehicle</Text> :
                  ' '}
                  <br />

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b> <Text>Category</Text>:</b>
                  </Typography>
                  {' ' + getSpendingCatName(spendingCategories, spe.spendingCategoryId)}
                  <br />

                  {spe.spendingType === 'loan' &&
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <b><Text>Loan For</Text>:</b>
                      </Typography>
                      {' ' + getUserName(users, spe.loanFor)}
                      <br />
                    </>
                  }

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b> <Text>Amount</Text>:</b>
                  </Typography>
                  {' ' + priceFormat(spe.spendingAmount)}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Payment Method</Text>:</b>
                  </Typography>
                  {' ' + spe.paymentMethod}
                  <br />

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Date</Text>:</b>
                  </Typography>
                  {' ' + moment(spe.spendingDate).format('L')}
                  <br />

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Created By</Text>:</b>
                  </Typography>
                  {' ' + getUserName(users, spe.createdBy)}
                  <br />

                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b><Text>Last Updated By</Text>:</b>
                  </Typography>
                  {' ' + getUserName(users, spe.lastUpdatedBy)}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b> <Text>Note</Text>:</b>
                  </Typography>
                  {' ' + spe.spendingNote}
                </React.Fragment>
              }
            />
            <ListItemAvatar>
              <SpendingsDialog
                btColor={'primary'}
                btTitle={<Text>Manage</Text>}
                id={spe.spendingId}
                spending={spe}
              />
            </ListItemAvatar>
          </ListItem>
        ))}
      </> : null}
    </List>

  );
}
