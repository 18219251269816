import { API } from "aws-amplify";

/**
* Function for getting a spending
* @param {function} getDeposit
*/
export const getSpending = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/spending/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all spendings
* @param {function} getAllDeposits 
*/
export const getAllSpendings = async (vendorId, companyId) => {

    let response;
//Add /{companyId}
    try {
        response = await API.get("adminAPI", `/admin/spendings/${vendorId}/${companyId}`);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all vendor spendings
* @param {function} getAllVendorSpendings 
*/
export const getAllVendorSpendings = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/spendings/vendors/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a spending
* @param {object} data  
* @param {function} saveSpending 
*/
export const saveSpending = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/spendings", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a spending 
* @param {function} updateSpending
*/
export const updateSpending = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/spendings/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for removing a spending 
* @param {function} removeSpending
*/
export const removeSpending = async (id, data) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/spendings/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting order spending 
* @param {function} getOrderSpendings
*/
export function getOrderSpendings(spendings, orderId) {
    let data = [];
    if (spendings && orderId) {
        spendings
            .filter(item => item.orderId !== undefined && item.orderId == orderId)
            .map(item => {

                data.push(item);

            });
    }
    return data;
};

/**
* Function for calculating spending total for a list of spendings
* @param {function} removeSpending
*/
export const calculateSpendings = async (data) => {

    let response = 0.00;

    data.forEach((element, i) => {
        response += +element.spendingAmount;
    });

    return response;
};