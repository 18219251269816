import * as React from 'react';
import { useHistory, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import LoaderButton from "../../../components/LoaderButton";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAppContext } from "../../../lib/contextLib";
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../../services/products";
import {
    saveProductReceived,
    updateProductReceived,
    receiveProduct
} from "../../../services/products_received";

import {
    getVendorName,
    getUserName,
    getSupplierName,
    priceFormat
} from "../../../services/shared";
import { Text } from '../../../services/translator';

var moment = require('moment');

export default function ProductReceivedialog({ onSave, product = null, productId = null, id = 0, action = 'add' }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        suppliers,
        user,
        users,
        vendor,
        companyId,
        company
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [showSave, setShowSave] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        productId: null,
        categoryId: null,
        productDescription: '',
        productOrigin: '',
        packagingNumber: '',
        receivedDate: null,
        productQuantity: '',
        productQuantityType: '',
        receiveStatus: 'pending',
        productUnitPrice: 0,
        receivedBy: null,
        createdBy: null,
    });

    React.useEffect(() => {
        const newFormInput = { ...formInput };

        if (id == 0) {//add product data
            newFormInput.categoryId = product.categoryId;
            newFormInput.productDescription = product.productDescription;
            newFormInput.productQuantityType = product.productQuantityType;
            newFormInput.createdBy = user.userId;
            setFormInput(newFormInput);
        } else {
            setFormInput({ ...product });
        }

    }, [product]);


    const handleReceive = async (data) => {

        const newData = { ...data };
        const productId = newData.productId;

        if (newData.productId == null) {
            return;
        }
        setIsLoading(true);

        let response = await receiveProduct(id, productId, user.userId, newData, company.inventoryCostChangeMethod);

        if (response.status) {
            actions.onSetFeedback(true, 'Product was successfully received.', 'success');
            actions.onGetReceiveProducts(companyId);
            actions.onGetProducts(vendor.vendorId, companyId);
            setIsLoading(false);
        }else{
            actions.onSetFeedback(true, 'Product receive failed.', 'error');
        }


        // newData.receiveStatus = 'received';
        // newData.receivedBy = user.userId;
        // newData.receivedDate = new Date();

        // const updateRes = await updateProductReceived(id, { ...newData });

        // if (updateRes.status) {
        //     const product = await getProduct(productId);
            
        //     //Add Quantity to product
        //     product.productQuantity = +product.productQuantity + +data.productQuantity;
        //     product.availableQuantity = +product.availableQuantity + +data.productQuantity; //Available quantity from all vendors

        //     product.receivedDate = new Date();
        //     product.productOrigin = data.productOrigin;

        //     let response = await updateProduct(productId, { ...product });

        //     actions.onSetFeedback(true, 'Product was successfully received.', 'success');

        //     if (response.status) {
        //         actions.onGetReceiveProducts(companyId);
        //         actions.onGetProducts(vendor.vendorId, companyId);
        //         setIsLoading(false);
        //     }
        // }

    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSave = async () => {
        let newFormInput = { ...formInput };

        newFormInput.productId = productId;
        setIsLoading(true);
        setShowSave(false);
        let res = null;
        if (id == 0) {
            res = await saveProductReceived({ ...newFormInput });
            actions.onSetFeedback(true, 'Product receive was successfully create.', 'success');
        } else {
            res = await updateProductReceived(id, { ...newFormInput });
            actions.onSetFeedback(true, 'Product receive was successfully updated.', 'success');

        }
        actions.onGetReceiveProducts(companyId);
        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
        //onSave();
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);

    }

    return (
        <div>
            {action == 'edit' ?
                <Button variant="outlined" onClick={handleClickOpen} color={'primary'} style={{ marginRight: 10 }}>
                    <Text>Edit</Text>
                </Button> : action == 'accept' ?
                    <Button variant="outlined" onClick={handleClickOpen} color={'primary'} style={{ marginRight: 10 }}>
                        <Text>Receive</Text>
                    </Button> : action == 'view' ? <Button variant="outlined" onClick={handleClickOpen} color={'primary'} style={{ marginRight: 10 }}>
                        <Text>View</Text>
                    </Button> : <Button variant="contained" onClick={handleClickOpen} color={'warning'} style={{ marginRight: 10 }}>
                        <Text>Receive Product</Text>
                    </Button>
            }

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle><Text>Product Receivable</Text></DialogTitle>
                <DialogContent>

                    {(action == 'add' || action == 'edit') ?

                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={12} md={12}>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Origin</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="productOrigin"
                                            value={formInput.productOrigin}
                                            label={<Text>Origin</Text>}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                        >
                                            {suppliers.map((s, index) =>
                                                <MenuItem key={index} value={s.supplierId}>{s.supplierName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>

                                <TextField
                                    id="packagingNumber"
                                    name="packagingNumber"
                                    label={<Text>Packaging Number</Text>}
                                    fullWidth
                                    //variant="standard"
                                    defaultValue={formInput.packagingNumber}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="productQuantity"
                                    name="productQuantity"
                                    label={<Text>Quantity</Text>}
                                    fullWidth
                                    type={'number'}
                                    //variant="standard"
                                    defaultValue={formInput.productQuantity}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="productQuantity"
                                    name="productUnitPrice"
                                    label={<Text>Unit Price</Text>}
                                    fullWidth
                                    type={'number'}
                                    defaultValue={formInput.productUnitPrice}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                        </Grid>
                        : (action == 'accept' || action == 'view') &&
                        <Grid container spacing={3} >
                            <Box sx={{ width: '100%', maxWidth: 560, minWidth: 460, bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Packaging Number</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {formInput.packagingNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Product Description</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {formInput.productDescription}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Quantity</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {formInput.productQuantity} {formInput.productQuantityType}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Unit Price</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {priceFormat(formInput.productUnitPrice)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Total Cost</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {priceFormat(formInput.productTotal)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Origin</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getSupplierName(suppliers, formInput.productOrigin)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Created Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(formInput.createdAt).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getUserName(users, formInput.receivedBy) || 'N/A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {formInput.receivedDate != null ? moment(formInput.receivedDate).format('L') : 'N/A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Box>
                                <Divider variant="middle" />

                            </Box>
                        </Grid>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}><Text>Cancel</Text></Button>

                    {(action == 'add' || action == 'edit') ?
                        < LoaderButton
                            //block
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isLoading}
                            disabled={
                                showSave &&
                                    formInput.productUnitPrice > 0 &&
                                    formInput.productOrigin.length > 0 &&
                                    formInput.packagingNumber.length > 0 &&
                                    formInput.productQuantity.length > 0 ? false : true}
                            onClick={handleSave}
                        >
                            <Text>Save</Text>
                        </LoaderButton> : action == 'accept' ? < LoaderButton
                            //block
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isLoading}
                            disabled={showSave ? false : true}
                            onClick={() => {
                                handleReceive(formInput);
                            }}
                        >
                            <Text>Receive</Text>
                        </LoaderButton> : null
                    }

                </DialogActions>
            </Dialog>
        </div >
    );
}
