import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
// import { TabsList } from '@mui/base/TabsList';
// import { buttonClasses } from '@mui/base/Button';
// import { tabClasses } from '@mui/base/Tab';
import DepositsDialog from "../dialogs/DepositsDialog";
import SpendingsDialog from "../dialogs/SpendingsDialog";
import Statistics from './Details';
import DepositsList from "../lists/DepositsList.tsx";
import SpendingsList from "../lists/SpendingsList.tsx";
import { Colors } from '../../../lib/colors';
import { Text } from '../../../services/translator';
import { useAppContext } from "../../../lib/contextLib";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ cashierCounts, deposits, spendings, customerCredits }) {
  const theme = useTheme();
  const { actions, userPermissions } = useAppContext();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static" sx={{
        borderRadius: 2
      }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            background: Colors.navBlue,
            borderRadius: 2
          }}
        >
          <Tab label={<Text>Cashier Totals</Text>} {...a11yProps(0)} />
          <Tab label={<Text>Deposits</Text>} {...a11yProps(1)} />
          {/* <Tab label={<Text>Outputs</Text>} {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>

      <StyledTabPanel value={value} index={0} dir={theme.direction}>
        <Statistics stats={cashierCounts} customerCredits={customerCredits} />
      </StyledTabPanel>
      <StyledTabPanel value={value} index={1} dir={theme.direction}>
        {userPermissions.admin || userPermissions.deposit_write ?
          <DepositsDialog
            btColor={'primary'}
            btTitle={<Text>Add Deposit</Text>}
          />
          : null}
        <br />
        {userPermissions.admin || userPermissions.deposit_write | userPermissions.deposit_read ? <>
          <Divider component="div" />
          <DepositsList deposits={deposits} />
        </> : null}

      </StyledTabPanel>

      {/* <StyledTabPanel value={value} index={2} dir={theme.direction}>
        {userPermissions.admin || userPermissions.deposit_write ?
          <SpendingsDialog
            btColor={'primary'}
            btTitle={<Text>Add Output</Text>}
          />
          : null}
        <br />
        {userPermissions.admin || userPermissions.deposit_write | userPermissions.deposit_read ? <>
          <Divider component="div" />
          <SpendingsList spendings={spendings} />
        </> : null}

      </StyledTabPanel> */}

    </Box>
  );
}

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};


const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;

`,
);




