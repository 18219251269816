import { API } from "aws-amplify";
import {
    updateSetting,
    getSetting
} from "./settings";
import {
    getAllUserRoles,
    saveUserRole
} from "./user_roles";



/**
* Function for getting all Companies 
* @param {function} getAllCompanies
*/
export const getAllCompanies = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/companies");


    } catch (err) {
        throw 'getAllCompanies - Cannot connect';
    }

    return response;
};

/**
* Function for getting a Company
* @param {function} getCompany
*/
export const getCompany = async (id) => {
    let response;

    try {

        response = await API.get("adminAPI", `/admin/companies/${id}`);

    } catch (err) {
        throw 'getCompany - Cannot connect';
    }

    return response;
};

/**
* Function for updating a Company
* @param {function} updateCompany
*/
export const updateCompany = async (id, data) => {
    let response;

    try {

        response = await API.put("adminAPI", `/admin/companies/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'updateCompany - Cannot connect';
    }

    return response;
};

/**
* Function for saving  a Company
* @param {object} data  
* @param {function} saveCompany
*/
export const saveCompany = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/companies", {
            body: data
        });


        if(response.companyId){
           await createCompanySettings(response.companyId);
        }

    } catch (err) {
        throw 'saveCompany - Cannot connect';
    }

    return response;
};


/**
* Function for creating company settings
* @param {string} companyId  
* @param {function} createCompanySettings
*/
export const createCompanySettings = async (companyId) => {

    let response;

    try {

        let defualtRoles = await getAllUserRoles();

        defualtRoles.forEach( async (element) => {
            let object = {
                ...element,
                companyId
            };

            await saveUserRole(object);

        });
    
    } catch (err) {
        throw 'createCompanySettings - Cannot connect';
    }

    return response;
};