import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
    priceFormat,
    getUserName,
    getSpendingCatName,
} from "../../../services/shared";
import { Text } from '../../../services/translator';
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { Colors } from '../../../lib/colors';
import { Divider } from '@mui/material';
var moment = require('moment'); // require

export default function OutputsList({ rows }) {
    const {
        users,
        spendingCategories
    } = useAppContext();


    return (
        <div>
            
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
            >
                <Text>Outputs</Text>:
            </Typography>
            <Divider/>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                {rows.map((row, index) => (
                    <Grid
                        key={row.spendingId}
                        container
                        spacing={1}
                    >
                        <Grid item xs={3} sm={3} md={3}>
                            <ListItem>
                                <ListItemText primary={<b><Text>Type</Text></b>} secondary={getSpendingCatName(spendingCategories, row.spendingCategoryId)} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3}>
                            <ListItem>
                                <ListItemText primary={<b><Text>Amount</Text></b>} secondary={priceFormat(row.spendingAmount)} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3}>
                            <ListItem>
                                <ListItemText primary={<b><Text>Date</Text></b>} secondary={moment(row.spendingDate).format('L')} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3}>
                            <ListItem>
                                <ListItemText primary={<b><Text>Created By</Text></b>} secondary={getUserName(users, row.createdBy)} />
                            </ListItem>
                        </Grid>
                        <Divider />
                    </Grid>
                ))}

            </List>

        </div>
    );
}
