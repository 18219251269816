import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { API, Storage } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import {
    priceFormat
} from "../../services/shared";
import ButtonSelectList from "../../components/buttons/ButtonSelectList";
import UpdateOrderItem from './UpdateOrderItem';

export default function OrderItem({ order, orderItem, onRemove, onHandleSave }) {
    const history = useHistory();
    const [checked, setChecked] = React.useState([1]);
    const [updateItem, setUpdateItem] = React.useState(false);
    const [attachmentURL, setAttachmentURL] = React.useState('/no-image.jpeg');

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    React.useEffect(() => {

        async function onLoad() {
            try {
                if (orderItem.product && orderItem.product.mainImage.length > 0) {
                    //let newAttachmentURL = await Storage.vault.get(orderItem.product.mainImage);

                    setAttachmentURL(orderItem.product.mainImage);
                }
            } catch (e) {
                // onError(e);
            }
        }

        onLoad();

    }, [orderItem]);

    return (
        <>
            <ListItem

                key={orderItem.product.productId}
                secondaryAction={!order.orderPlaced &&
                    <span>
                        <ButtonSelectList

                            options={[
                                { name: 'Edit', value: 'edit' },
                                { name: 'Remove', value: 'remove' }
                            ]}
                            onOptionClick={(value) => {
                                if (value == 'remove') {
                                    onRemove(orderItem);
                                } else if (value == 'edit') {
                                    setUpdateItem(true);
                                }
                            }}
                        />
                    </span>

                }
                disablePadding
            >
                <ListItemButton
                    onClick={() => {
                        history.push(`/a_portal/products/${orderItem.product.productId}`);
                    }}>
                    {/* <Grid item xs={1} sm={1} md={1} >
                        <ListItemAvatar>
                            <Avatar
                                alt={`Avatar n°${orderItem.product.productId}`}
                                src={attachmentURL}
                            />
                        </ListItemAvatar>
                    </Grid> */}
                    <Grid item xs={5} sm={5} md={5} >
                        <ListItemText
                            sx={{
                                paddingLeft: 1
                            }}
                            id={`checkbox-list-secondary-label-${orderItem.product.productId}`}
                            primary={orderItem.product.productDescription}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} >
                        <ListItemText
                            sx={{
                                marginLeft: !order.orderPlaced ? 2 : 0,
                                paddingRight: 2,
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'right'
                            }}
                            id={`checkbox-list-secondary-label-${orderItem.product.productId}`}
                            primary={priceFormat(orderItem.price)} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} >
                        <ListItemText sx={{
                            marginLeft: !order.orderPlaced ? 2 : 0,
                            paddingRight: 2,
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right'
                        }}
                            id={`checkbox-list-secondary-label-${orderItem.product.productId}`}
                            primary={orderItem.quantity + 'x'} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} >
                        <ListItemText
                            sx={{
                                marginLeft: !order.orderPlaced ? 3 : 0,
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'right'
                            }}
                            id={`checkbox-list-secondary-label-${orderItem.product.productId}`}
                        >
                            <span>{priceFormat(orderItem.total)}</span>
                        </ListItemText>
                    </Grid>

                    {/* <ListItemAvatar>
                        <Avatar
                            alt={`Avatar n°${orderItem.product.productId}`}
                            src={attachmentURL}
                        />
                    </ListItemAvatar>
                    <ListItemText style={{ width: 100 }} id={`checkbox-list-secondary-label-${orderItem.product.productId}`} primary={orderItem.product.productDescription} />
                    <ListItemText style={{ width: 80 }} id={`checkbox-list-secondary-label-${orderItem.product.productId}`} primary={priceFormat(orderItem.price)} />
                    <ListItemText id={`checkbox-list-secondary-label-${orderItem.product.productId}`} primary={orderItem.quantity + 'x'} />
                    <ListItemText id={`checkbox-list-secondary-label-${orderItem.product.productId}`} primary={priceFormat(orderItem.total)} />
                 */}
                </ListItemButton>

            </ListItem>
            <UpdateOrderItem
                orderItem={orderItem}
                open={updateItem}
                onHandleSave={(input) => {
                    setUpdateItem(false);
                    onHandleSave(input);
                }}
                onHandleClose={() => {
                    setUpdateItem(false);
                }}
            />
        </>

    );
}

