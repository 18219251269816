import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import SuppliersTable from "./tables/SuppliersTable";
import SupplierForm from "./dialogs/SupplierForm";
import {Text} from '../../services/translator';

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Customers() {
    const history = useHistory();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { 
        store, 
        actions, 
        suppliers
    } = useAppContext();

    useEffect(() => {
        actions.onSetSelected('suppliers');
    }, []);


    return (
        <div className="suppliers">
            <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom">Suppliers</h2>

                <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                    {/* <BsPencilSquare size={17} /> */}
                    <SupplierForm
                        btColor={'primary'}
                        btTitle={'Create Supplier'}
                        
                    />
                </ListGroup.Item>

                <SuppliersTable suppliersData={suppliers} />

            </Box>
        </div>
    );
}