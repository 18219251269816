import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
// import { onError } from "../../lib/errorLib";
// import ActionAreaCard from "../../components/cards/ActionAreaCard";
// import LoadingDialog from "../../components/dialogs/Loading";
// import ProductsTable from "../../components/tables/ProductsTable";
import OrdersTable from "../../components/tables/OrdersTable";
import { Text } from '../../services/translator';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import Stack from '@mui/material/Stack';
import {
    getLastOrder,
    saveOrder
} from "../../services/orders";

import "./Orders.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Orders() {
    const history = useHistory();
    const { isAuthenticated, vendor } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { store, actions, products, settings, user, orders} = useAppContext();

    const createOrder = async () => {
        try {
            actions.onSetIsLoading(true);
            let data = {
                orderNumber: null,
                closed: false,
                cancelled: false,
                delivered: false,
                paid: false,
                hold: false,
                draft: true,
                orderPlaced: false,
                vendorId: vendor.vendorId
            }

            let order = await saveOrder(data);
            actions.onGetOrders(vendor.vendorId);
            actions.onSetIsLoading(false);
            history.push(`/a_portal/orders/${order.orderId}`);

        } catch (err) {
            actions.onSetIsLoading(false);
        }

    };

    useEffect(() => {
        actions.onSetSelected('orders');
    }, []);


    return (
        <div className="Orders">
             <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom"><Text>Orders</Text></h2>

                <LinkContainer to="#" onClick={() => {
                    createOrder();
                }}>
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold"><Text>Create New Order</Text></span>
                    </ListGroup.Item>
                </LinkContainer>
                <OrdersTable ordersData={orders}/>

            </Box>
        </div>
    );
}