
import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { LinkContainer } from "react-router-bootstrap";
import InputMask from "react-input-mask";
import CustomerStatistics from "../../components/shared/CustomerStatistics";
import ProductMultiFilesUpload from "../../components/forms/ProductMultiFilesUpload";
import LoaderButton from "../../components/LoaderButton";
import FeedBack from "../../components/shared/FeedBack";
import ProductAttributesTable from "../../components/tables/ProductAttributesTable";
import ProductDispatchTable from "../../components/tables/ProductDispatchTable";
import OrdersTable from './tables/OrdersTable';
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";
import { Text } from '../../services/translator';
import { areacodes } from "../../services/country_phone_areacodes";
import {
    saveCustomer,
    getCustomer,
    updateCustomer
} from "../../services/customers";
import {
    updateOrder,
    getOrder,
    getCustomerOrders
} from "../../services/orders";


const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function CustomerDetailsForm() {
    //const file = useRef(null);
    let componentRef = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [showSave, setShowSave] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [orderActions, setOrderActions] = useState([

    ])

    const [isLoading, setIsLoading] = useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        states,
        companyId
    } = useAppContext();

    const [productAttributes, setProductAttributes] = useState([]);
    const [customerOrders, setCustomerOrders] = useState([]);
    const [customerOrderStatistics, setCustomerOrderStatistics] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [customerAddress, setCustomerAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });
    const [customerShippingAddress, setCustomerShippingAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });
    const [formInput, setFormInput] = useState({
        lastCustomerNumber: '',
        fullName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneCountryCode: '221',
        phoneNumber: '',
        note: '',
        active: true,
    });


    const [errors, setErrors] = useState({
        fullName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
    });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        // if (formInput.productName == '') {
        //     newErrors.productName = 'Product name is required.';
        // } else {
        //     newErrors.productName = '';
        // }

        // setErrors(newErrors);

        // if (
        //     newErrors.productName.length > 0
        // ) {
        //     isValid = 0;
        // }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
        updateErrors(name);
    }

    async function handleSubmit(data) {
        let isValid = validateForm();

        if (isValid) {
            setIsLoading(true);

            try {

                let response = await updateCustomer(id, { ...data, address: customerAddress, shippingAddresses: customerShippingAddress });
                actions.onGetCustomers(companyId);
                actions.onSetFeedback(true, 'Order was successfully updated.', 'success');
                onLoad();

                history.push(`/a_portal/customers/${id}`);
            } catch (e) {
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
        }

    }

    useEffect(() => {
        actions.onSetSelected('customers');
        onLoad();
    }, [id]);


    async function onLoad() {
        try {
            //let newFormInput = { ...formInput };
            const customer = await getCustomer(id);
            const res = await getCustomerOrders(id);

            let newAddress = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'Dakar',
                zipCode: '',
                country: 'SN'
            };
            let address = customer.address || {};
            newAddress.addressLine1 = address.addressLine1 || '';
            newAddress.addressLine2 = address.addressLine2 || '';
            newAddress.city = address.city || '';
            newAddress.state = address.state || 'Dakar';
            newAddress.zipCode = address.zipCode || '';
            newAddress.country = address.country || 'SN';

            let newShippingAddress = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'Dakar',
                zipCode: '',
                country: 'SN'
            };
            let shippingAddress = customer.shippingAddresses || {};
            newShippingAddress.addressLine1 = shippingAddress.addressLine1 || '';
            newShippingAddress.addressLine2 = shippingAddress.addressLine2 || '';
            newShippingAddress.city = shippingAddress.city || '';
            newShippingAddress.state = shippingAddress.state || 'Dakar';
            newShippingAddress.zipCode = shippingAddress.zipCode || '';
            newShippingAddress.country = shippingAddress.country || 'SN';

            setCustomerOrders(res.orders);
            setCustomerOrderStatistics(res.statistics);
            setFormInput(customer);
            setCustomerAddress(newAddress);
            setCustomerShippingAddress(newShippingAddress);
            setShowPage(true);
            setShowSave(false);

        } catch (e) {
            console.log('error', e);
            onError(e);
        }
    }


    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
        updateErrors(name);

    }

    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...customerAddress,
            [name]: value
        };

        setCustomerAddress(newAddressInput);
        setShowSave(true);
    }

    function handleShippingAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...customerShippingAddress,
            [name]: value
        };

        setCustomerShippingAddress(newAddressInput);
        setShowSave(true);
    }

    return (
        <div className="CustomerDetailsForm" >
            {showPage === true &&

                <Grid
                    container
                    spacing={2}
                    style={{
                        //marginTop: 5
                    }}>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            flexGrow: 1,
                            marginTop: 2,
                            // marginBottom: 2
                        }}
                            style={styles.whiteBox}
                        >
                            <h2 className="pb-3 mb-3 border-bottom"> {<Text>Customer Account Number</Text>} {' - ' + formInput.customerNumber}</h2>
                            <CustomerStatistics statistics={customerOrderStatistics} />

                            <Grid container spacing={1}>

                                <Grid container item spacing={3}>

                                    <Grid item xs={6}>

                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography><Text>Active</Text></Typography>
                                            <Switch
                                                checked={formInput.active}
                                                onChange={(e) => {
                                                    let newFormInput = {
                                                        ...formInput,
                                                        ['active']: e.target.checked
                                                    };

                                                    setFormInput(newFormInput);
                                                    setShowSave(true);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Stack>

                                    </Grid>

                                </Grid>

                                <Grid container item spacing={3}>

                                    <Grid item xs={6}>

                                        <TextField
                                            error={errors.fullName !== '' ? true : false}
                                            id="outlined-error"
                                            name="fullName"
                                            helperText={errors.fullName}
                                            label={<Text>Full Name</Text>}
                                            defaultValue={formInput.fullName}
                                            value={formInput.fullName}
                                            onChange={handleInputChange}
                                            fullWidth
                                        />

                                    </Grid>
                                    <Grid item xs={6}>

                                        <TextField
                                            error={errors.email !== '' ? true : false}
                                            id="outlined-error"
                                            name="email"
                                            label={<Text>Email</Text>}
                                            defaultValue={formInput.email}
                                            value={formInput.email}
                                            onChange={handleInputChange}
                                            fullWidth
                                        />

                                    </Grid>

                                </Grid>

                                <Grid container item spacing={3}>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Phone Country Code</InputLabel>
                                            <Select
                                                disabled={false}
                                                error={errors.phoneCountryCode !== '' ? true : false}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="phoneCountryCode"
                                                defaultValue={formInput.phoneCountryCode}
                                                label="Phone Country Code"
                                                onChange={handleInputChange}
                                            >
                                                {areacodes.map((c, index) =>
                                                    <MenuItem key={index} value={c.code}>{c.country} <small>(+{c.code})</small></MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputMask
                                            mask="9999999999"
                                            value={formInput.phoneNumber}
                                            disabled={false}
                                            maskChar=" "
                                            onChange={handleInputChange}
                                        >
                                            {() =>
                                                <TextField
                                                    error={errors.phoneNumber !== '' ? true : false}
                                                    id="outlined-error"
                                                    name="phoneNumber"
                                                    label={<Text>Phone Number</Text>}
                                                    fullWidth
                                                />}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            //error={errors.note !== '' ? true : false}
                                            id="outlined-error"
                                            name="note"
                                            helperText={errors.note}
                                            label={<Text>Note</Text>}
                                            defaultValue={formInput.note}
                                            value={formInput.note}
                                            onChange={handleInputChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* <Divider variant="middle" /> */}
                                <Typography gutterBottom variant="body1" style={{ marginTop: 10 }}>
                                    <Text>Customer Address</Text>
                                </Typography>
                                <Grid container item spacing={3}>

                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors.addressLine1 !== '' ? true : false}
                                            id="outlined-error"
                                            name="addressLine1"
                                            label={<Text>Address Line One</Text>}
                                            //defaultValue={customerAddress.addressLine1}
                                            value={customerAddress.addressLine1}
                                            fullWidth
                                            onChange={handleAddressChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-error"
                                            name="addressLine2"
                                            label={<Text>Address Line Two</Text>}
                                            defaultValue={customerAddress.addressLine2}
                                            value={customerAddress.addressLine2}
                                            fullWidth
                                            onChange={handleAddressChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={3}>

                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors.city !== '' ? true : false}
                                            id="outlined-error"
                                            name="city"
                                            label={<Text>City</Text>}
                                            defaultValue={customerAddress.city}
                                            value={customerAddress.city}
                                            fullWidth
                                            onChange={handleAddressChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><Text>State</Text></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="state"
                                                value={customerAddress.state}
                                                label={<Text>State</Text>}
                                                onChange={(e) => {
                                                    //console.log('Event', e);
                                                    handleAddressChange(e);
                                                }}
                                            >
                                                {states.map((s, index) =>
                                                    <MenuItem key={index} value={s}>{s}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="outlined-error"
                                            name="zipCode"
                                            label={<Text>Zip Code</Text>}
                                            defaultValue={customerAddress.zipCode}
                                            value={customerAddress.zipCode}
                                            fullWidth
                                            onChange={handleAddressChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography gutterBottom variant="body1" style={{ marginTop: 10 }}>
                                    <Text>Shipping Address</Text>
                                </Typography>
                                <Grid container item spacing={3}>

                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors.addressLine1 !== '' ? true : false}
                                            id="outlined-error"
                                            name="addressLine1"
                                            label={<Text>Address Line One</Text>}
                                            //defaultValue={customerAddress.addressLine1}
                                            value={customerShippingAddress.addressLine1}
                                            fullWidth
                                            onChange={handleShippingAddressChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-error"
                                            name="addressLine2"
                                            label={<Text>Address Line Two</Text>}
                                            //defaultValue={customerAddress.addressLine2}
                                            value={customerShippingAddress.addressLine2}
                                            fullWidth
                                            onChange={handleShippingAddressChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={3}>

                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors.city !== '' ? true : false}
                                            id="outlined-error"
                                            name="city"
                                            label={<Text>City</Text>}
                                            //defaultValue={customerAddress.city}
                                            value={customerShippingAddress.city}
                                            fullWidth
                                            onChange={handleShippingAddressChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><Text>State</Text></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="state"
                                                value={customerShippingAddress.state}
                                                label={<Text>State</Text>}
                                                onChange={(e) => {
                                                    //console.log('Event', e);
                                                    handleShippingAddressChange(e);
                                                }}
                                            >
                                                {states.map((s, index) =>
                                                    <MenuItem key={index} value={s}>{s}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="outlined-error"
                                            name="zipCode"
                                            label={<Text>Zip Code</Text>}
                                            //defaultValue={customerAddress.zipCode}
                                            value={customerShippingAddress.zipCode}
                                            fullWidth
                                            onChange={handleShippingAddressChange}
                                        />
                                    </Grid>
                                </Grid>


                                {showSave && <LoaderButton
                                    block
                                    type="submit"
                                    size="lg"
                                    variant="primary"
                                    isLoading={isLoading}
                                    //disabled={!validateForm()}
                                    onClick={() => {
                                        handleSubmit(formInput);
                                    }}
                                    style={{ marginTop: 10 }}
                                >
                                    <Text>Save</Text>
                                </LoaderButton>}

                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            flexGrow: 1,
                            marginTop: 2,
                            // marginBottom: 2
                        }}
                            style={styles.whiteBox}
                        >
                            <h4 className="pb-3 mb-3 border-bottom"> {<Text>Customer Orders</Text>}</h4>
                            <OrdersTable ordersData={customerOrders} />

                        </Box>
                    </Grid>

                </Grid>

            }
        </div>
    );
}
