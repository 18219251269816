import React, { useRef, useState, useEffect } from "react";
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";


const styles = {
    selectedItem: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    innerItem: {
        paddingLeft: 55
    },
    nestedListStyle: {
        padding: '0 0 0 15px'
    }
};

const FolderIcon1 = <FolderIcon />;
const DraftIcon = (
    <VisibilityOffIcon />
);

function Item(props) {
    const { item, selectedId, nestedItems } = props;
    const style = item.categoryId === selectedId ? styles.selectedItem : null;

    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState(item.active ? FolderIcon1 : DraftIcon);

    const handleClick = () => {
        const { item } = props;
        props.onSelect(item);
    };

    const handleOpenClick = () => {
        setOpen(!open);
    };

    return (

        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton >
                <ListItemIcon onClick={handleClick}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={item.categoryName} onClick={handleClick} />
                {open ? <ExpandLess onClick={handleOpenClick} /> : nestedItems.length > 0 ? <ExpandMore onClick={handleOpenClick} /> : null}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 35 }} >
                {nestedItems}
            </Collapse>
        </List>

    );
}

export default function Categories(props) {
    const {
        selectedId,
    } = props;
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories,
    } = useAppContext();
    const [rows, setRows] = useState([]);


    function getItem(selectedId, allItems, item) {
        const nestedItems = getChildren(selectedId, allItems, item.categoryId);
        return (
            <Item
                key={item.categoryId}
                item={item}
                selectedId={selectedId}
                nestedItems={nestedItems}
                onSelect={props.onSelect}
            />
        );
    }

    function getChildren(selectedId, allItems, categoryId) {
        if (allItems && categoryId) {
            return allItems
                .filter(item => item.parentCategoryId === categoryId)
                .map(item => getItem(selectedId, allItems, item));
        } else {
            return [];
        }
    }

    useEffect(() => {

        async function onLoad() {
            try {
               // console.log('productCategories', productCategories);

                var newRows = productCategories.filter(item => item.parentCategoryId === '' || item.parentCategoryId === null)
                    .map(item => getItem(selectedId, productCategories, item));

                setRows(newRows);
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [productCategories]);


    return (
        <List>

            {rows}

        </List>
    );

}
