import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
//import CompanyForm from "../forms/CompanyForm";
import CompanyVendorForm from "../forms/CompanyVendorForm";
import VendorsTable from "../tables/VendorsTable";
import { useAppContext } from "../../../lib/contextLib";
import { styled } from '@mui/system';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VendorFormDialog({ id = null, btTitle = '' }) {

  const theme = useTheme();
  const {
    userHasAuthenticated,
    store,
    actions,
    settings,
    user,
    vendors,
    states,
    companyId
  } = useAppContext();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // React.useEffect(() => {

  //   async function onLoad() {
  //     try {
  //       if (id !== null) {
  //         await actions.onSetCompanyId(id);
  //         await actions.onGetVendors();
  //       }
  //     } catch (e) {
  //       // onError(e);
  //     }
  //   }

  //   onLoad();

  // }, [company, id]);
  //onGetVendors

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {btTitle}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Vendor Name
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
          {/* <AppBar position="static"> */}
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Vendor Info" {...a11yProps(0)} />
            {/* {id !== null && <Tab label="Vendors" {...a11yProps(1)} />}
            {id !== null && <Tab label="Users" {...a11yProps(2)} />}
            {id !== null && <Tab label="Other Information" {...a11yProps(3)} />} */}
          </Tabs>
          {/* </AppBar> */}

          <StyledTabPanel value={value} index={0} dir={theme.direction}>
            <CompanyVendorForm
              companyId={companyId}
              id={id}
            />
          </StyledTabPanel>

        </Box>
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;`,
);