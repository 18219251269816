import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useHistory, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { API, Storage } from "aws-amplify";
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";


function ProductSearch({ saveButtonName, cancelButtonName, buttonName, productId, onSave }) {
    const {
        userHasAuthenticated,
        store,
        actions,
        products
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState({});
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedProduct({});
        setOpen(false);
    };

    const handleAdd = () => {
        setOpen(false);
        onSave({ ...selectedProduct });


    };

    return (
        <div>
            <Button
                startIcon={<AddCircleIcon />}
                variant="outlined"
                onClick={handleClickOpen}
                style={{ marginBottom: 10 }}
            >
                {buttonName}
            </Button>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Product Search"}
                </DialogTitle>

                <DialogContent>
                    <Box
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                        }}
                    >
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            onChange={(_event, obj) => {
                                setSelectedProduct(obj);
                            }}
                            options={products}
                            getOptionLabel={option => option.productDescription}
                            //getOptionLabel={option => option.productName}
                            //noOptionsText={'Product not found!'}
                            renderInput={(params) => (
                                <TextField
                                    style={{ marginTop: 5 }}
                                    fullWidth
                                    {...params}
                                    label="Search"
                                    value={selectedProduct}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        //onChange={ (e, obj) => { console.log('Object', obj);  } }
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>{cancelButtonName}</Button>
                    <Button onClick={handleAdd} disabled={selectedProduct.productId ? false : true}>
                        {saveButtonName}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ProductSearch.defaultProps = {
    saveButtonName: 'Save',
    cancelButtonName: 'Cancel',
    buttonName: 'Add Product'
}

export default ProductSearch;
