import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import {
    priceFormat
} from "../../services/shared";
import { onError } from "../../lib/errorLib";

export default function UpdateOrderItem({ open, orderItem, onHandleClose, onHandleSave }) {
    const [quantity, setQuantity] = React.useState(null);
    const [formInput, setFormInput] = React.useState({
        quantity: null,
        price: 0.00
    });
    React.useEffect(() => {
        console.log('orderItem', orderItem.product);
        if (orderItem.quantity >= 0) {
            let newFormInput = {
                ...formInput,
                ['quantity']: orderItem.quantity,
                ['price']: orderItem.price
            };
            setFormInput(newFormInput);
        }
    }, [orderItem, open]);

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
    }

    const handleClose = () => {
       // setOpen(false);
    };

    const handleSave = () => {
        if(formInput.price < orderItem.product.productPrice){
            onError('Price cannot be lower than ' + priceFormat(orderItem.product.productPrice));

            let newFormInput = {
                ...formInput,
                ['price']: orderItem.price
            };
            setFormInput(newFormInput);
    
        }else{
            onHandleSave(formInput);
        }
        
    };

    return (
        <div>

            <Dialog open={open} onClose={onHandleClose}>
                <DialogTitle>Update order item</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 2 }}
                    >
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="outlined-error"
                                name="quantity"
                                label="Quantity"
                                type={'number'}
                                value={formInput.quantity}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="outlined-error"
                                name="price"
                                label="Price"
                                type={'number'}
                                value={formInput.price}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHandleClose}>Cancel</Button>
                    <Button onClick={handleSave} disabled={formInput.quantity > 0? false: true}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
