import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams } from "react-router-dom";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ProductMultiFilesUpload from "../../components/forms/ProductMultiFilesUpload";
import LoaderButton from "../../components/LoaderButton";
import FeedBack from "../../components/shared/FeedBack";
import ProductAttributesTable from "../../components/tables/ProductAttributesTable";
import ProductDispatchTable from "../../components/tables/ProductDispatchTable";
import ProductReceiveDialog from "./dialogs/ProductReceiveDialog";

import { Colors } from '../../lib/colors';
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";
import { Text } from '../../services/translator';
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";

import {
    getProductDispatchHistory,
    getVendorDispatchHistory
} from "../../services/products_dispatches";

import {
    getProductReceivedHistory
} from "../../services/products_received";
import {
    getVendorName,
    getUserName,
    getSupplierName
} from "../../services/shared";

import CategorySelect from "../../components/forms/CategorySelect";
import CategoryMultiSelect from "../../components/forms/CategoryMultiSelect";
import RelatedProducts1 from "../../components/forms/RelatedProducts";
import ProductTagsInput from "../../components/forms/ProductTagsInput";
import ProductsDispatchHistoryTable from './tables/ProductsDispatchHistoryTable';
import ProductsReceivedHistoryTable from './tables/ProductsReceivedHistoryTable';
var moment = require('moment');
const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};



export default function ProductDetailsForm() {
    const file = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [showSave, setShowSave] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const {
        actions,
        productQuantityTypes,
        productLengthUnits,
        userPermissions,
        user,
        suppliers,
        vendor,
        companyId
    } = useAppContext();
    //const [productTags, setProductTags] = useState([]);
    const [productAttributes, setProductAttributes] = useState([]);
    const [productDispatchHistory, setProductDispatchHistory] = useState([]);
    const [productReceivedHistory, setProductReceivedHistory] = useState([]);
    const [newDate, setNewDate] = useState(new Date());
    const [productImages, setProductImages] = useState([]);
    const [formInput, setFormInput] = useState({
        categoryId: '',
        productName: '',
        mainImage: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        vendorId: '',
        productCost: 0,
        productPrice: 0,
        productSalesPrice: 0,
        productDateSaleFrom: '',
        productDateSaleTo: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: 0,
        productQuantityType: 'm2',
        availableQuantity: 0,
        soldQuantity: 0,
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',
        productStockTracking: false,
        productStockBackorder: false,
        productStockPreorder: false,
        discontinued: false,
        productPosition: '',
        productOrigin: '',
        receivedDate: new Date(),
        blNumber: '',
        quantityAlert: 0,
        productAttributes: [],
        productTags: [],
        categoryIds: [],
        relatedProducts: [],
        productDispatch: [],
        active: true,
    });
    const [errors, setErrors] = useState({
        productName: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        productPrice: '',
        productSalesPrice: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: '',
        productQuantityType: '',
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',
        productPosition: '',
        productOrigin: '',
        receivedDate: '',
        blNumber: '',
        quantityAlert: ''

    });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.productDescription == '') {
            newErrors.productDescription = 'Product description is required.';
        } else {
            newErrors.productDescription = '';
        }

        setErrors(newErrors);

        if (
            newErrors.productDescription.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
        updateErrors(name);

    }


    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    function handleSaveAttribute(item) {
        setShowSave(true);
        let newProductAttributes = [...productAttributes];

        newProductAttributes.push(item);

        setProductAttributes(newProductAttributes);

        let newFormInput = {
            ...formInput,
            ['productAttributes']: newProductAttributes
        };
        setFormInput(newFormInput);
    }

    function handleRemoveAttribute(item) {
        setShowSave(true);
        let newProductAttributes = [...productAttributes];

        let index = newProductAttributes.indexOf(item);
        if (index > -1) {
            newProductAttributes.splice(index, 1);
        }

        setProductAttributes(newProductAttributes);

        let newFormInput = {
            ...formInput,
            ['productAttributes']: newProductAttributes
        };
        setFormInput(newFormInput);

    }

    async function handleSubmit() {
        let isValid = validateForm();

        if (isValid) {
            if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
                alert(
                    `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
                    } MB.`
                );
                return;
            }
            setIsSubmitting(true);
            //actions.onSetIsLoading(true);
            try {
                let newFormInput = { ...formInput };

                if (newFormInput.soldQuantity == null || newFormInput.soldQuantity == '') {
                    newFormInput.soldQuantity = 0;
                }

                let response = await updateProduct(id, { ...newFormInput, productImages });

                actions.onGetProducts(vendor.vendorId, companyId);
                setShowFeedback(true);
                setFeedbackMessage('Product was successfully updated.');
                setFeedbackType('success');
                setIsSubmitting(false);
                setShowSave(false);

                setTimeout(() => {
                    onLoad(true);
                }, 5000);

            } catch (e) {
                // onError(e);
                setIsSubmitting(false);
                actions.onSetIsLoading(false);
            }
        }

    }

    async function handleSoftSubmit(FormInput, productDispatch) {

        try {
            let newFormInput = { ...FormInput };

            let response = await updateProduct(id, { ...newFormInput, productDispatch });
            actions.onSetFeedback(true, 'Product was successfully updated.', 'success');

        } catch (e) {
            actions.onSetFeedback(true, e, 'error');
            setIsSubmitting(false);
        }

    }

    useEffect(() => {
        onLoad();
    }, [id]);

    async function onLoad(softLoad) {
        if (softLoad === undefined) {
            setIsLoading(true);
        }

        try {
            let newFormInput = { ...formInput };

            const product = await getProduct(id);

            const {
                categoryId,
                productName,
                mainImage,
                productDescription,
                slug,
                pageTitle,
                metaDescription,
                vendorId,
                productCost,
                productPrice,
                productSalesPrice,
                productDateSaleFrom,
                productDateSaleTo,
                productSKU,
                productBarCode,
                productQuantity,
                productQuantityType,
                availableQuantity,
                soldQuantity,
                productWeight,
                productLength,
                productWidth,
                productHeight,
                productLengthUnit,
                productStockTracking,
                productStockBackorder,
                productStockPreorder,
                discontinued,
                productPosition,
                productOrigin,
                receivedDate,
                blNumber,
                quantityAlert,
                active,
                categoryIds,
                productTags,
                relatedProducts,
                productAttributes,
                productDispatch
            } = product;

            newFormInput.categoryId = categoryId;
            newFormInput.productName = productName;
            newFormInput.mainImage = mainImage;
            newFormInput.productDescription = productDescription;
            newFormInput.slug = slug;
            newFormInput.pageTitle = pageTitle;
            newFormInput.metaDescription = metaDescription;
            newFormInput.vendorId = vendorId;
            newFormInput.productCost = productCost || 0;
            newFormInput.productPrice = productPrice;
            newFormInput.productSalesPrice = productSalesPrice;
            newFormInput.productDateSaleFrom = productDateSaleFrom;
            newFormInput.productDateSaleTo = productDateSaleTo;
            newFormInput.productSKU = productSKU;
            newFormInput.productBarCode = productBarCode;
            newFormInput.productQuantity = productQuantity;
            newFormInput.productQuantityType = productQuantityType;
            newFormInput.availableQuantity = availableQuantity || 0;
            newFormInput.soldQuantity = soldQuantity;
            newFormInput.productWeight = productWeight;
            newFormInput.productLength = productLength;
            newFormInput.productWidth = productWidth;
            newFormInput.productHeight = productHeight;
            newFormInput.productLengthUnit = productLengthUnit;
            newFormInput.productStockTracking = productStockTracking;
            newFormInput.productStockBackorder = productStockBackorder;
            newFormInput.productStockPreorder = productStockPreorder;
            newFormInput.discontinued = discontinued;
            newFormInput.productPosition = productPosition;
            newFormInput.productOrigin = productOrigin;
            newFormInput.receivedDate = receivedDate;
            newFormInput.blNumber = blNumber;
            newFormInput.quantityAlert = quantityAlert || 0;
            newFormInput.active = active;
            newFormInput.categoryIds = categoryIds || [];
            newFormInput.productTags = productTags || [];
            newFormInput.relatedProducts = relatedProducts || [];
            newFormInput.productAttributes = productAttributes || [];
            newFormInput.productDispatch = productDispatch || [];

            setFormInput(newFormInput);
            setProductAttributes(productAttributes || []);

            const dispatchStory = await getProductDispatchHistory(id);
            const receivedStory = await getProductReceivedHistory(id);
            setProductDispatchHistory(dispatchStory);
            setProductReceivedHistory(receivedStory.receivedProducts);
            setShowSave(false);
            setNewDate(new Date());
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        actions.onSetSelected('products');
    }, []);
    return (
        <div className="productDetailsForm" >

            {isLoading === false ? <>

                {showSave && <LoaderButton
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        //marginLeft: 8
                    }}
                    block
                    type="submit"
                    size="sm"
                    variant="primary"
                    style={{ backgroundColor: Colors.navBlue }}
                    isLoading={isSubmitting}
                    disabled={userPermissions.admin == true || userPermissions.products_write == true || userPermissions.product_dispatch ? false : true}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    <Text>Save</Text>
                </LoaderButton>}
                <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
                    <h2 className="pb-3 mb-3 border-bottom"> {<Text>Product Details Page</Text>}</h2>

                    <Grid container spacing={3}>

                        <Grid container item spacing={3}>

                            <Grid item xs={6}>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography><Text>Active</Text></Typography>
                                    <Switch
                                        checked={formInput.active}
                                        onChange={(e) => {
                                            let newFormInput = {
                                                ...formInput,
                                                ['active']: e.target.checked
                                            };

                                            setFormInput(newFormInput);
                                            setShowSave(true);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                    />
                                </Stack>

                            </Grid>
                            {userPermissions.receive_products == true && <Grid item xs={6} >
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ProductReceiveDialog
                                        action={'add'}
                                        product={formInput}
                                        productId={id}
                                        onSave={onLoad}
                                    />
                                </div>
                            </Grid>}


                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <Typography><Text>Product Category</Text></Typography>
                                <CategorySelect
                                    isDisabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                    selectedId={formInput.categoryId}
                                    onSave={(item) => {
                                        let newFormInput = {
                                            ...formInput,
                                            ['categoryId']: item.categoryId
                                        };

                                        setFormInput(newFormInput);
                                        setShowSave(true);
                                    }}
                                />
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={6} sm={6} md={6}>

                            <TextField
                                error={errors.productDescription !== '' ? true : false}
                                id="outlined-error"
                                name="productDescription"
                                helperText={errors.productDescription}
                                label={<Text>Product Description</Text>}
                                //defaultValue={formInput.productDescription}
                                value={formInput.productDescription}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        {/* <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.productName !== '' ? true : false}
                            id="outlined-error"
                            name="productName"
                            helperText={errors.productName}
                            label={<Text>Product Name</Text>}
                            //defaultValue={formInput.productName}
                            value={formInput.productName}
                            onChange={handleInputChange}
                            disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                            fullWidth
                        />

                    </Grid> */}

                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                error={errors.productLength !== '' ? true : false}
                                id="outlined-error"
                                name="productLength"
                                type="number"
                                helperText={errors.productLength}
                                label={<Text>Length</Text>}
                                //defaultValue={formInput.productLength}
                                value={formInput.productLength}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                error={errors.productWidth !== '' ? true : false}
                                id="outlined-error"
                                name="productWidth"
                                type="number"
                                helperText={errors.productWidth}
                                label={<Text>Width</Text>}
                                //defaultValue={formInput.productWidth}
                                value={formInput.productWidth}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                error={errors.productHeight !== '' ? true : false}
                                id="outlined-error"
                                name="productHeight"
                                type="number"
                                helperText={errors.productHeight}
                                label={<Text>Height</Text>}
                                // defaultValue={formInput.productHeight}
                                value={formInput.productHeight}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"><Text>Unit Type</Text></InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="productLengthUnit"
                                    value={formInput.productLengthUnit}
                                    label={<Text>Unit Type</Text>}
                                    onChange={handleInputChange}
                                    disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                >
                                    {productLengthUnits.map((type, index) =>
                                        <MenuItem key={index} value={type}>{type}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>

                            <TextField
                                disabled
                                error={errors.productQuantity !== '' ? true : false}
                                id="outlined-error"
                                name="productQuantity"
                                type="number"
                                helperText={errors.productQuantity}
                                label={<Text>Quantity</Text>}
                                //defaultValue={formInput.productQuantity}
                                value={formInput.productQuantity}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>

                            <TextField
                                disabled
                                // error={errors.availableQuantity !== '' ? true : false}
                                id="outlined-error"
                                name="availableQuantity"
                                type="number"
                                //helperText={errors.availableQuantity}
                                label={<Text>Available Quantity</Text>}
                                //defaultValue={formInput.productQuantity}
                                value={formInput.availableQuantity}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                error={errors.quantityAlert !== '' ? true : false}
                                id="outlined-error"
                                name="quantityAlert"
                                type="number"
                                helperText={errors.quantityAlert}
                                label={<Text>Quantity Alert</Text>}
                                value={formInput.quantityAlert}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>


                        <Grid item xs={6} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"><Text>Quantity Type</Text></InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="productQuantityType"
                                    value={formInput.productQuantityType}
                                    label={<Text>Quantity Type</Text>}
                                    onChange={handleInputChange}
                                    disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                >
                                    {productQuantityTypes.map((type, index) =>
                                        <MenuItem key={index} value={type}>{type}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <Typography><Text>Product Category</Text></Typography>
                                <CategorySelect
                                    isDisabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                    selectedId={formInput.categoryId}
                                    onSave={(item) => {
                                        let newFormInput = {
                                            ...formInput,
                                            ['categoryId']: item.categoryId
                                        };

                                        setFormInput(newFormInput);
                                        setShowSave(true);
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <CategoryMultiSelect
                                    isDisabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                    key={newDate}
                                    productId={id}
                                    onSave={(items) => {
                                        let newFormInput = {
                                            ...formInput,
                                            ['categoryIds']: items
                                        };
                                        setFormInput(newFormInput);
                                        setShowSave(true);
                                    }}
                                />
                            </FormControl>
                        </Grid>




                        {/* <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"><Text>Vendor</Text></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="vendorId"
                                value={formInput.vendorId}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                            >
                                {vendors.map((vendor, index) =>
                                    <MenuItem key={index} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid> */}


                        {/* <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Label>Product Image</Form.Label>
                        <Form.Control onChange={handleFileChange} type="file" size="lg" />
                    </Form.Group> */}

                    </Grid>
                </Box>

                <Divider />
                <Divider ><Text>INVENTORY</Text></Divider>
                <Divider />

                <Box sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 2
                }}
                    style={styles.whiteBox}
                >
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ color: '#1876D1' }}><Text>Pricing</Text></div>
                            <Grid container spacing={1} style={{ marginTop: 1 }}>
                                <Grid item xs={6} sm={6} md={6}>

                                    <TextField
                                        error={errors.productPrice !== '' ? true : false}
                                        id="outlined-error"
                                        name="productPrice"
                                        helperText={errors.productPrice}
                                        label={<Text>Regular Price</Text>}
                                        type="number"
                                        //defaultValue={formInput.productPrice}
                                        value={formInput.productPrice}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6} sm={6} md={6}>

                                    <TextField
                                        error={errors.productSalesPrice !== '' ? true : false}
                                        id="outlined-error"
                                        name="productSalesPrice"
                                        helperText={errors.productSalesPrice}
                                        label={<Text>Sales Price</Text>}
                                        type="number"
                                        //defaultValue={formInput.productSalesPrice}
                                        value={formInput.productSalesPrice}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        id="date"
                                        name="productDateSaleFrom"
                                        label={<Text>Sale From</Text>}
                                        type="date"
                                        //defaultValue={formInput.productDateSaleFrom}
                                        value={formInput.productDateSaleFrom}
                                        onChange={handleInputChange}
                                        //sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        id="date"
                                        name="productDateSaleTo"
                                        label={<Text>Sale To</Text>}
                                        type="date"
                                        //defaultValue={formInput.productDateSaleTo}
                                        value={formInput.productDateSaleTo}
                                        onChange={handleInputChange}
                                        //sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>

                                    <TextField
                                        id="outlined-error"
                                        name="productCost"
                                        label={<Text>Product cost</Text>}
                                        type="number"
                                        //defaultValue={formInput.productSalesPrice}
                                        value={formInput.productCost}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true? false : true}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                <div style={{ color: '#1876D1' }}><Text>Stock Settings</Text></div>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography><Text>Stock Management</Text></Typography>
                                        <Switch
                                            checked={formInput.productStockTracking}
                                            onChange={(e) => {
                                                let newFormInput = {
                                                    ...formInput,
                                                    ['productStockTracking']: e.target.checked
                                                };

                                                setFormInput(newFormInput);
                                                setShowSave(true);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        />
                                    </Stack>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography><Text>Is Preorder</Text></Typography>
                                        <Switch
                                            checked={formInput.productStockPreorder}
                                            onChange={(e) => {
                                                let newFormInput = {
                                                    ...formInput,
                                                    ['productStockPreorder']: e.target.checked
                                                };

                                                setFormInput(newFormInput);
                                                setShowSave(true);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        />
                                    </Stack>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography><Text>Allow Backorders</Text></Typography>
                                        <Switch
                                            checked={formInput.productStockBackorder}
                                            onChange={(e) => {
                                                let newFormInput = {
                                                    ...formInput,
                                                    ['productStockBackorder']: e.target.checked
                                                };

                                                setFormInput(newFormInput);
                                                setShowSave(true);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        />
                                    </Stack>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography><Text>Discontinued</Text></Typography>
                                        <Switch
                                            checked={formInput.discontinued}
                                            onChange={(e) => {
                                                let newFormInput = {
                                                    ...formInput,
                                                    ['discontinued']: e.target.checked
                                                };

                                                setFormInput(newFormInput);
                                                setShowSave(true);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        />
                                    </Stack>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ color: '#1876D1' }}><Text>Inventory</Text></div>

                            <Grid container spacing={1} style={{ marginTop: 1 }}>
                                <Grid item xs={6} sm={6} md={6}>

                                    <TextField
                                        error={errors.productSKU !== '' ? true : false}
                                        id="outlined-error"
                                        name="productSKU"
                                        helperText={errors.productSKU}
                                        label={<Text>SKU</Text>}
                                        //defaultValue={formInput.productSKU}
                                        value={formInput.productSKU}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6} sm={6} md={6}>

                                    <TextField
                                        error={errors.productBarCode !== '' ? true : false}
                                        id="outlined-error"
                                        name="productBarCode"
                                        helperText={errors.productBarCode}
                                        label={<Text>Bar Code</Text>}
                                        //defaultValue={formInput.productBarCode}
                                        value={formInput.productBarCode}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>


                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        error={errors.productWeight !== '' ? true : false}
                                        id="outlined-error"
                                        name="productWeight"
                                        type="number"
                                        helperText={errors.productWeight}
                                        label={<Text>Weight (kg)</Text>}
                                        //defaultValue={formInput.productWeight}
                                        value={formInput.productWeight}
                                        onChange={handleInputChange}
                                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                        fullWidth
                                    />

                                </Grid>

                            </Grid>


                        </Grid>


                    </Grid>
                </Box>



                <Divider />
                <Divider><Text>ATTRIBUTES</Text></Divider>
                <Divider />


                <Box sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 2
                }}
                    style={styles.whiteBox}
                >
                    <Grid container spacing={1}>

                        <Grid item xs={12} sm={12} md={12}>

                            <ProductAttributesTable
                                key={new Date()}
                                onSaveAttribute={(item) => {
                                    handleSaveAttribute(item);
                                }}
                                onRemoveAttribute={(item) => {
                                    handleRemoveAttribute(item);
                                }}
                                rows={productAttributes}
                            />

                        </Grid>


                    </Grid>
                </Box>

                <Divider />
                <Divider><Text>ADDITIONAL INFO</Text></Divider>
                <Divider />

                <Box sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 2
                }}
                    style={styles.whiteBox}
                >
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>

                            <TextField
                                error={errors.metaDescription !== '' ? true : false}
                                id="outlined-error"
                                name="metaDescription"
                                helperText={errors.metaDescription}
                                label={<Text>Meta Description</Text>}
                                //defaultValue={formInput.metaDescription}
                                value={formInput.metaDescription}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>

                            <TextField
                                error={errors.pageTitle !== '' ? true : false}
                                id="outlined-error"
                                name="pageTitle"
                                helperText={errors.pageTitle}
                                label={<Text>Page Title</Text>}
                                //defaultValue={formInput.pageTitle}
                                value={formInput.pageTitle}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>



                        {/* <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                error={errors.productPosition !== '' ? true : false}
                                id="outlined-error"
                                name="productPosition"
                                type="number"
                                helperText={errors.productPosition}
                                label={<Text>Position</Text>}
                                //defaultValue={formInput.productPosition}
                                value={formInput.productPosition}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />
                        </Grid> */}


                        <Grid item xs={12} sm={6} md={6}>

                            <TextField
                                disabled
                                error={errors.productOrigin !== '' ? true : false}
                                id="outlined-error"
                                name="productOrigin"
                                helperText={errors.productOrigin}
                                label={<Text>Last Origin</Text>}
                                value={getSupplierName(suppliers, formInput.productOrigin) || 'N/A'}
                                onChange={handleInputChange}
                                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                disabled
                                error={errors.receivedDate !== '' ? true : false}
                                id="receivedDate"
                                name="receivedDate"
                                label={<Text>Last Received Date</Text>}
                                type="date"
                                defaultValue={formInput.receivedDate}
                                //value={formInput.receivedDate}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>

                                <ProductTagsInput
                                    id={id}
                                    onChange={(values) => {
                                        setShowSave(true);
                                        //setProductTags(values);
                                        let newFormInput = {
                                            ...formInput,
                                            ['productTags']: values
                                        };
                                        setFormInput(newFormInput);
                                    }}
                                />

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>

                                <RelatedProducts1
                                    productId={id}
                                    onSave={(productIds) => {
                                        //setRelatedProducts(productIds);
                                        let newFormInput = {
                                            ...formInput,
                                            ['relatedProducts']: productIds
                                        };
                                        setFormInput(newFormInput);
                                        setShowSave(true);
                                    }}

                                />

                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>

                {userPermissions.product_dispatch == true ?

                    <>
                        <Divider />
                        <Divider><Text>PRODUCT DISPATCH</Text></Divider>
                        <Divider />

                        <Box sx={{
                            flexGrow: 1,
                            marginTop: 2,
                            marginBottom: 2
                        }}
                            style={styles.whiteBox}
                        >

                            <ProductDispatchTable
                                //key={new Date()}
                                quantity={formInput.productQuantity}
                                rows={formInput.productDispatch}
                                onChange={(data) => {

                                    let newFormInput = {
                                        ...formInput,
                                        ['productDispatch']: data
                                    };
                                    setFormInput(newFormInput);
                                    setShowSave(true);
                                }}
                                onSave={(data) => {
                                    let newFormInput = {
                                        ...formInput,
                                        ['productDispatch']: data
                                    };
                                    setFormInput(newFormInput);
                                    handleSoftSubmit(newFormInput, data);
                                }}
                            />

                        </Box>
                    </> : null}


                {showSave && <LoaderButton
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        //marginLeft: 8
                    }}
                    block
                    type="submit"
                    size="sm"
                    variant="primary"
                    style={{ backgroundColor: Colors.navBlue }}
                    isLoading={isSubmitting}
                    // disabled={!showSave}
                    onClick={() => {
                        handleSubmit();
                    }}
                    disabled={userPermissions.admin == true || userPermissions.products_write == true || userPermissions.product_dispatch ? false : true}
                >
                    <Text>Save</Text>
                </LoaderButton>}

                <Box sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 2
                }}
                    style={styles.whiteBox}
                >

                    <AppBar position="static" sx={{
                        borderRadius: 2
                    }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                                background: Colors.navBlue,
                                borderRadius: 2
                            }}
                        >
                            <Tab label={<Text>IMAGES</Text>} {...a11yProps(0)} />
                            <Tab label={<Text>Product Dispatch History</Text>} {...a11yProps(1)} />
                            <Tab label={<Text>Product Received History</Text>} {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    <StyledTabPanel value={value} index={0} dir={theme.direction}>
                        <ProductMultiFilesUpload
                            key={'889u8jko8u9'}
                            id={id}
                            formInput={formInput}
                            onUpdate={(images) => {
                                setProductImages(images);
                            }}
                        />
                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={1} dir={theme.direction}>
                        <ProductsDispatchHistoryTable dispatchesData={productDispatchHistory} />
                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={2} dir={theme.direction}>
                        <ProductsReceivedHistoryTable receivedData={productReceivedHistory} productQuantityType={formInput.productQuantityType} />
                    </StyledTabPanel>

                </Box>
            </> : null}
            {showFeedback &&
                <FeedBack
                    message={feedbackMessage}
                    type={feedbackType}
                />
            }
        </div>

    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};


const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  
  `,
);

