import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useAppContext } from "../../../lib/contextLib";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function ProductCategorySelectFilter({ type, onChange, data }) {
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories
    } = useAppContext();

    const [filter, setFilter] = React.useState('All');
    const [optionList, setOptionList] = React.useState([]);

    React.useEffect(() => {
        const newOptionList = ['All', ...productCategories.map(element => element.categoryName)];
        setOptionList(newOptionList);
    }, [data]);

    function getCatId(categoryName) {
        let id = '';
        if (productCategories && categoryName) {
            productCategories
                .filter(item => item.categoryName == categoryName)
                .map(item => {
                    id = item.categoryId;
                });
        }
        return id;
    }

    function handleInputChange(event) {
        let { value } = event.target;
        setFilter(value);
        if(value === "All"){
            onChange(data);
        }else{
            const categoryId = getCatId(value);
            console.log(categoryId);
            const filterData = data.filter(element => {
                return element.categoryId == categoryId;
            });
            onChange(filterData);
        }
    }

    return (
        <FormControl style={{ width: 200, marginLeft: 8 }}>
            <InputLabel id="demo-simple-select-category">Filter</InputLabel>
            <Select
                labelId="demo-simple-select-category"
                id="demo-simple-select-category"
                name="productQuantityType"
                value={filter}
                label={"Filter"}
                onChange={handleInputChange}
            >
                {optionList.map((value, index) =>
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
