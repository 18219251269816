import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment';

import ProductCats from "../../product_categories/ProductCats";
import BankAccountDialog from "../dialogs/BankAccountDialog";
import { useAppContext } from "../../../lib/contextLib";
import TableFilter from '../../../components/shared/Filter';
import { Colors } from '../../../lib/colors';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: 'Account Name',
    },
    {
        id: 'rib',
        numeric: false,
        disablePadding: false,
        label: 'RIB',
    },
    {
        id: 'swiftAddress',
        numeric: false,
        disablePadding: false,
        label: 'SWIFT Address',
    },
    {
        id: 'bankName',
        numeric: false,
        disablePadding: false,
        label: 'Bank Name',
    },
    {
        id: 'bankContactPerson',
        numeric: false,
        disablePadding: false,
        label: 'Bank Contact Person',
    },
    {
        id: 'bankPhoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Bank Phone Number',
    },

    {
        id: 'bankEmail',
        numeric: false,
        disablePadding: false,
        label: 'Bank Email',
    },

    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    }
];

function AccountsTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: Colors.darkBlue }}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            style={{ color: Colors.gold }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

AccountsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const AccountsTableToolbar = (props) => {
    const { numSelected, onSearch, accounts } = props;
    const history = useHistory();

    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        <BankAccountDialog
                            btColor={'primary'}
                            btTitle={'Add Bank Account'}
                        />
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <TableFilter
                        onChange={onSearch}
                        type={'accounts'}
                        data={accounts}
                    />
                )}
            </Toolbar>
            
        </>
    );
};

AccountsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function AccountsTable({ accountsData, onHandleClickOpen }) {

    const [accounts, setAccounts] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('firstName');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        setAccounts(accountsData);
    }, [accountsData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = accounts.map((n) => n.permissionId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accounts.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>

            <Paper sx={{ width: '100%', mb: 2 }}>

                <AccountsTableToolbar
                    numSelected={selected.length}
                    onHandleClickOpen={onHandleClickOpen}
                    onSearch={(data) => {
                        setAccounts(data);
                    }}
                    accounts={accountsData}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <AccountsTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={accounts.length}
                        />
                        <TableBody>

                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(accounts, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.permissionId);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.permissionId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.permissionId)}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                            >
                                                {row.accountName}
                                            </TableCell>
                                            <TableCell align="left">{row.bankCode}{row.agencyCode}{row.accountNumber}{row.ribKey}</TableCell>
                                            <TableCell align="left">{row.swiftAddress}</TableCell>
                                            {/* <TableCell align="left">{moment(row.createdAt).format("MMM Do YY")}</TableCell> */}
                                            <TableCell align="left">{row.bankName}</TableCell>
                                            <TableCell align="left">{row.bankContactPerson}</TableCell>
                                            <TableCell align="left">{row.bankPhoneNumber}</TableCell>
                                            <TableCell align="left">{row.bankEmail}</TableCell>

                                            <TableCell align="left">
                                                <BankAccountDialog
                                                    btColor={'primary'}
                                                    btTitle={'Manage'}
                                                    id={row.accountId}
                                                    bank_account={row}
                                                />
                                                {/* <BankAccountDialog
                                                    btColor={'primary'}
                                                    btTitle={'Remove'}
                                                    id={row.accountId}
                                                    bank_account={row}
                                                /> */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={accounts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </Box>
    );
}
