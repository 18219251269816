import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import ProductsTable from "../../components/tables/ProductsTable";
import AllAvailableVendorsProductsTable2 from "../../components/tables/AllAvailableVendorsProductsTable2";
import { Text } from '../../services/translator';
import "./Products.css";

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Products() {
    const history = useHistory();
    const { isAuthenticated, userPermissions, isSupperAdmin } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { store, actions, products, allProducts, pendingProducts, vendor, vendorsAvailableProducts } = useAppContext();
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        actions.onSetSelected('products');
    }, []);

    useEffect(() => {
        if(vendor.isMainVendor){
            setValue(1);
        }
    }, [vendor]);

    
    //userPermissions.is_admin
    return (
        <div className="Products">
            <h2 className="pb-3 mt-4 mb-3 border-bottom"><Text>Products</Text></h2>
            <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
              
                {(userPermissions.admin == true || userPermissions.products_write == true) &&
                    <LinkContainer to="/a_portal/products/create">
                        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                            <BsPencilSquare size={17} />
                            <span className="ml-2 font-weight-bold"><Text>Create New Product</Text></span>
                        </ListGroup.Item>
                    </LinkContainer>
                }
                
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {(userPermissions.admin == true || userPermissions.available_products_view == true) &&
                        <Tab label={<Text>Available Products</Text>} {...a11yProps(0)} />
                    }
                    {(userPermissions.admin == true || vendor.isMainVendor == true || userPermissions.all_products_view == true) &&
                        <Tab label={<Text>All Products</Text>} {...a11yProps(1)} />
                    }
                    {(userPermissions.admin == true || userPermissions.vendor_products_view == true) &&
                        <Tab label={<Text>Product by vendor</Text>} {...a11yProps(2)} />
                    }
                </Tabs>

                <StyledTabPanel value={value} index={0} dir={theme.direction}>
                    {(userPermissions.admin == true || userPermissions.available_products_view == true) &&
                        <ProductsTable
                            key={'55544545edfd'}
                            pageTitle={<Text>Available Products</Text>}
                            showCategory={true}
                            productsData={products}
                            isVendor={true}
                        />
                    }
                </StyledTabPanel>
                <StyledTabPanel value={value} index={1} dir={theme.direction}>
                    {(userPermissions.admin == true || vendor.isMainVendor == true || userPermissions.all_products_view == true) &&
                        <ProductsTable
                            key={'6577986yighhj'}
                            pageTitle={<Text>All Products</Text>}
                            showCategory={vendor.isMainVendor == true ? true : false}
                            productsData={allProducts}
                            isVendor={false}
                        />
                    }
                </StyledTabPanel>
                <StyledTabPanel value={value} index={2} dir={theme.direction}>
                    {(userPermissions.admin == true || userPermissions.vendor_products_view == true) &&
                        <>
                            <AllAvailableVendorsProductsTable2
                                key={'979908978iukj799'}
                                pageTitle={<Text>Product by vendor</Text>}
                                dispatchesData={vendorsAvailableProducts}
                                showTransfer={vendor.isMainVendor == true ? false : true}
                            />
                        </>
                    }
                </StyledTabPanel>

            </Box>

        </div>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;`,
);

