import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';

import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FactoryIcon from '@mui/icons-material/Factory';
import ApprovalIcon from '@mui/icons-material/Approval';
import Badge from '@mui/material/Badge';

import { LinkContainer } from "react-router-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { useAppContext } from "../../lib/contextLib";
import { Colors } from '../../lib/colors';
import { translator, Text } from '../../services/translator';

export default function MobileNav({ menuItems, approvalsCount, selected, onHandleLogout }) {
    // const { 
    //    actions
    // } = useAppContext();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            sx={{
                //marginRight: 5,
                marginTop: 8
            }}
        >
            <List>
            {menuItems.map(({ name, value, url }, index) => (
                    <div key={value + index}>
                      <LinkContainer to={url} >
                        <Tooltip
                          placement="top"
                          arrow
                          title={name}
                        >
                          <ListItemButton
                            selected={selected == value ? true : false}

                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >

                              {value === 'home' ? <HomeIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                value === 'products' ? <LocalOfferIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                  value === 'orders' ? <ShoppingBasketIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                    value === 'cashier' ? <PointOfSaleIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                      value === 'customers' ? <GroupIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                        value === 'vendors' ? <StoreIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                          value === 'users' ? <SupervisedUserCircleIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                            value === 'user_roles' ? <AccessibilityIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                              value === 'settings' ? <SettingsApplicationsIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                                value === 'suppliers' ? <FactoryIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                                  value === 'pending_approvals' ? <Badge badgeContent={approvalsCount} color="error"> <ApprovalIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> </Badge> : null
                              }

                            </ListItemIcon>
                            <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                        </Tooltip>
                      </LinkContainer>
                      {value == 'user_roles' && <Divider />}
                    </div>
                  ))}
                  <Tooltip
                    placement="top"
                    arrow
                    title={<Text>Logout</Text>}
                  >
                    <ListItemButton
                      key={'logout'}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={onHandleLogout}
                    >

                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >

                        <LogoutIcon style={{ color: Colors.error }} />

                      </ListItemIcon>

                      <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />

                    </ListItemButton>
                  </Tooltip>
            </List>
            
        </Box>
    );

    return (
        <div >
            <Grid
                container
                spacing={1}
                //sx={{ display: { sm: 'none', md: 'none' } }}
                sx={{ display: { md: 'none', lg: 'none'} }}
            >
                <Grid item xs={6} >

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(!open)}
                        edge="start"
                        sx={{
                            //marginLeft: 3,
                            //marginTop: 3
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>

            </Grid>
            {/* <Button onClick={toggleDrawer(!open)} sx={{ display: { sm: 'none', md: 'none' } }}>Open drawer</Button> */}
            <Drawer 
            open={open} 
            onClose={toggleDrawer(false)} 
            //sx={{ display: { sm: 'none', md: 'none' } }}
            sx={{ display: { md: 'none', lg: 'none'} }}
            >
                {DrawerList}
            </Drawer>
        </div>
    );
}
