import { API } from "aws-amplify";

import {
    saveDispatch,
    updateDispatch,
    removeProductDispatch,
    getProductDispatch,
    saveDispatchHistoryProduct,
    saveDispatchHistoryVendor
} from "./products_dispatches";
import {
    getApproval,
    saveApproval,
    updateApproval,
    removeApproval
} from "./approvals";

/**
* Function for getting all products
* @param {function} getAllProducts 
*/
export const getAllProducts = async (companyId) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove product
* @param {function} removeProduct
*/
export const removeProduct = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all available vendor products
* @param {function} getAllAvailableVendorProducts 
*/
export const getAllAvailableVendorProducts = async (companyId) => {
    let data = [];
    let response;

    try {
        response = await API.get("adminAPI", "/admin/products/vendors/available/"+companyId);

        // const groupByCategory = response.reduce((group, product) => {
        //     const { vendorId } = product;
        //     group[vendorId] = group[vendorId] ?? [];
        //     group[vendorId].push(product);
        //     return group;
        // }, []);

        // response.forEach(element => {

        //     let res = data.filter(item => item.vendorId == element.vendorId)
        //         .map(item => {
        //             let index = data.indexOf(item);
        //             data[index].products.push(element);
        //         });

        //     if (res.length == 0) {
        //         let products = [];
        //         products.push(element);
        //         let obj = {
        //             vendorId: element.vendorId,
        //             products: products
        //         }
        //         data.push(obj);
        //     }
        // });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product
* @param {function} getProduct
*/
export const getProduct = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/product/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting products for logged in vender
* @param {id} id  
* @param {function} getVendorProducts
*/
export const getVendorProducts = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/vendors/${id}`);

    } catch (err) {
        console.log('products err', err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a product cat 
* @param {function} updateProduct 
*/
export const updateProduct = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products/${id}`, {
            body: data
        });

        let product = response.product;

        if (product.productDispatch && product.productDispatch.length > 0) {
            await _updateDispatch(data.productDispatch, data, id);
        }

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a product quantity
* @param {function} updateProductQuantity 
*/
export const updateProductQuantity = async (id, data, type) => {

    let response;

    try {
        //Do this when order is placed.
        response = await API.put("adminAPI", `/admin/products/${id}`, {
            body: data
        });

    } catch (err) {
        //console.log('Error Vendor', err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a product 
* @param {object} data  
* @param {function} saveProduct
*/
export const saveProduct = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for saving  a product 
* @param {object} data  
* @param {function} recreateProduct
*/
export const recreateProduct = async (data) => {

    console.log('data',);
    return;
    let response;

    try {

        response = await API.post("adminAPI", "/admin/products/recreate", {
            body: data
        });

        await removeProduct(data.productId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


export const calculateDispatchQty = (data) => {

    let count = 0;

    data.forEach(element => {

        if (element.quantity != '' || element.quantity != null) {
            count += +element.quantity;
        }

    });

    return count;
};

export const _updateDispatch = async (data, product, productId, reload = false) => {
    let id = productId;
    let response;

    data.forEach(async (element) => {
        let load = {
            productDispatchId: element.productDispatchId,
            productId: productId,
            vendorId: element.vendorId || null,
            soldQuantity: element.soldQuantity || null,
            quantity: element.quantity || null,
            dispatchDate: element.dispatchDate || null,
            dispatchStatus: element.status || null,
            blNumber: element.blNumber || null,

            productName: product.productName || null,
            productDescription: product.productDescription || null,
            categoryId: product.categoryId || null,
            productPrice: product.productPrice || null,
            productQuantityType: product.productQuantityType || null,
            quantityAlert: product.quantityAlert || null
        };

        //Check if dispatch is assigned to a vendor.
        if (element.vendorId && element.vendorId != '' && element.vendorId != null) {

            let approvalId = element.vendorId + element.productDispatchId;

            //Check if dispatch is market as received
            if (element.status && element.status.toLowerCase() == 'received') {

                //Find index of product dispatch
                const index = product.productDispatch.indexOf(element);
                //Reduce quantity from product record
                product.productQuantity = +product.productQuantity - +element.quantity;

                let disId = element.vendorId + productId;
                let disRecord = null;
                try {
                    //Get product dispatch for vendor
                    disRecord = await getProductDispatch(disId);

                } catch (err) {
                    //Creat product dispatch if not exist.
                    let dis = await saveDispatch({ ...load });

                    //Save dispatch history for product
                    let dHistoryProduct = await saveDispatchHistoryProduct({ ...load });
                    //Save dispatch history for vendor
                    let dHistoryVendor = await saveDispatchHistoryVendor({ ...load });

                    //Remove Approval Record
                    await removeApproval(approvalId);
                    if (index > -1) {
                        //remove dispatch record from product dispatch
                        product.productDispatch.splice(index, 1);
                        //update main product
                        response = await API.put("adminAPI", `/admin/products/${id}`, {
                            body: product
                        });

                        if (reload == true) {
                            window.location.reload(false);
                        }

                        return response;
                    };

                }

                //If product dispatch exist on vendor's record
                if (disRecord.productDispatchId) {

                    disRecord.quantityAlert = product.quantityAlert;
                    disRecord.productName = product.productName;
                    disRecord.productDescription = product.productDescription;
                    disRecord.categoryId = product.categoryId;
                    disRecord.productPrice = product.productPrice;
                    disRecord.productQuantityType = product.productQuantityType;
                    disRecord.quantity = +disRecord.quantity + +element.quantity;

                    //Update vendors dispatch
                    await updateDispatch(disId, {
                        ...disRecord
                    });

                    //Creat Dispatch history for product
                    await saveDispatchHistoryProduct({ ...load });
                    //Creat Dispatch history for Vendor
                    await saveDispatchHistoryVendor({ ...load });
                    //Remove Approval Record
                    await removeApproval(approvalId);
                    if (index > -1) {
                        //Remove dispatch from main pro
                        product.productDispatch.splice(index, 1);
                        //update main product
                        response = await API.put("adminAPI", `/admin/products/${id}`, {
                            body: product
                        });

                        if (reload == true) {
                            window.location.reload(false);
                        }
                        return response;
                    };
                };

            } else {

                if (element.status && element.status.toLowerCase() == 'shipped') {

                    let res = await getApproval(approvalId);

                    if (res.status === true) {
                        await updateApproval(approvalId, {
                            lastUpdatedBy: element.lastUpdatedBy,
                            approvalData: load
                        });
                    } else {
                        await saveApproval({
                            recordIdentifier: productId,
                            vendorId: element.vendorId,
                            parentId: element.vendorId + element.productDispatchId,
                            approvalType: 'dispatch',
                            createdBy: element.createdBy,
                            approvalData: load
                        });
                    }

                }

            };
        };

    });

};
