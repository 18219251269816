import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import { Colors } from '../../lib/colors';
import {
    priceFormat
} from "../../services/shared";
import { Divider } from '@mui/material';

export default function Statistics({ statistics }) {
    const theme = useTheme();

    return (

        <Grid container item spacing={1} style={{ textAlign: 'center' }}>

            <Grid item xs={12} sm={3} md={3}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.darkBlue,
                        color: Colors.gold,
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                Draft Orders
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {statistics.totalDraft}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={3} md={3}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.darkBlue,
                        color: Colors.gold,

                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                Total Orders
                            </Typography>

                            <Typography variant="subtitle1" color="white" component="div" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                {statistics.totalOrders}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={3} md={3}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.darkBlue,
                        color: Colors.gold,
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                Spent this Month
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {priceFormat(statistics.totalSalesThisMonth)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

            <Grid item xs={12} sm={3} md={3}>

                <Card sx={{
                    //  display: 'flex'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: Colors.darkBlue,
                        color: Colors.gold,
                    }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                Total Spent
                            </Typography>

                            <Typography variant="subtitle1" color={Colors.white} component="div">
                                {priceFormat(statistics.totalAmount)}
                            </Typography>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

        </Grid>


    );
}
