import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { Text } from '../../../services/translator';
import CustomerCreditsList from "../lists/CustomerCreditsList";

import { Colors } from '../../../lib/colors';

export default function CustomerCreditsDialog({
    btColor,
    btTitle,
    openDialog,
    customerCredits,
    onClose
}) {

    const handleClose = () => {
        onClose();
    };

    return (
        <div>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {btTitle}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">

                        <Divider />

                        <CustomerCreditsList
                            rows={customerCredits}
                        />
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                onClick={handleClose}
                            ><Text>Cancel</Text></Button>
                        </Grid>

                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
