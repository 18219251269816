import React, { useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import {
    createSettings,
    resetSytemData
} from "../../services/settings";
import { useAppContext } from "../../lib/contextLib";
//import { onError } from "../../lib/errorLib";
import Permission from "./Permissions";
import General from "./General";
import AccountInfo from "./PersonalInfo";
import EmailInfo from "./EmailInfo";
import BankAccounts from "./BankAccounts";
import SpendingCategories from "./SpendingCategories";

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
//import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/system';

export default function Setting() {
    const { settings, actions, isSupperAdmin, company } = useAppContext();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const saveSettings = async () => {
        try {
            actions.onSetIsLoading(true);
            let newSettings = { ...settings };

            newSettings.active = true;

            await createSettings(newSettings);

            actions.onGetSettings();
            //history.push(`/settings`);
            actions.onSetIsLoading(false);
        } catch (err) {
            actions.onSetIsLoading(false);
            // console.log("Err: " + err);
        }

    };

    useEffect(() => {
        actions.onSetSelected('settings');
    });

    function renderLander() {
        return (
            <div className="settings">
                Page Not Ready Yet.
            </div>
        );
    }
    function renderDashboard() {
        return (
            <div className="settings" >
                <h2 className="pb-3 mt-4 mb-3 border-bottom">Company Setting Page</h2>
                <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                    {/* <AppBar position="static"> */}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="General Info" {...a11yProps(0)} />
                        <Tab label="User Info" {...a11yProps(1)} />
                        <Tab label="Bank Accounts" {...a11yProps(2)} />
                        <Tab label="Spending Categories" {...a11yProps(3)} />
                    </Tabs>
                    {/* </AppBar> */}

                    <StyledTabPanel value={value} index={0} dir={theme.direction}>
                        <General />
                    </StyledTabPanel>
                    <StyledTabPanel value={value} index={1} dir={theme.direction}>
                        <AccountInfo />
                    </StyledTabPanel>
                    <StyledTabPanel value={value} index={2} dir={theme.direction}>
                        <BankAccounts />
                    </StyledTabPanel>
                    <StyledTabPanel value={value} index={3} dir={theme.direction}>
                    <SpendingCategories />
                    </StyledTabPanel>
                </Box>


            </div>
        );
    }
    return (
        <div className="company">
            {company && company.active ? renderDashboard() : renderLander()}
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;`,
);











