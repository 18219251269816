import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useHistory, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { API, Storage } from "aws-amplify";
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";

const images = [
    {
        url: '/static/images/buttons/breakfast.jpg',
        title: 'Breakfast',
        width: '40%',
    },
    {
        url: '/static/images/buttons/burgers.jpg',
        title: 'Burgers',
        width: '30%',
    },
    {
        url: '/static/images/buttons/camera.jpg',
        title: 'Camera',
        width: '30%',
    },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const RelatedProd = (props) => {
    const { onDelete, productId } = props;
    const history = useHistory();
    const [attachmentURL, setAttachmentURL] = React.useState('/no-image.jpeg');
    const [selectedPro, setSelectedPro] = React.useState({});
    const { userHasAuthenticated, store, actions, products, userPermissions } = useAppContext();

    React.useEffect(() => {

        async function onLoad() {
            try {
                // return products
                //     .filter(item => item.productId === productId)
                //     .map(item => {
                //         setSelectedPro(item);
                //     }
                //     );

                let pro = await getProduct(productId);
                if(pro.productId){
                    setSelectedPro(pro);
                }
                return;

            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [productId]);

    React.useEffect(() => {

        async function onLoad() {
            try {

                if (selectedPro.mainImage) {
                    //alert('h');
                    //let mainImage = await Storage.vault.get(selectedPro.mainImage);
                    let url = await Storage.vault.get(selectedPro.mainImage, { level: 'public' });
                    setAttachmentURL(url);
                }

            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [selectedPro]);


    return (
        <>
            {selectedPro.productId &&
                <>
                    <ImageButton
                        focusRipple
                        key={selectedPro.productId}
                        style={{
                            width: '100%',
                        }}
                        onClick={() => {
                            history.push(`/a_portal/products/${selectedPro.productId}`);
                        }}

                    >
                        <ImageSrc style={{ backgroundImage: `url(${attachmentURL})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}

                            >
                                {selectedPro.productDescription}
                                <ImageMarked className="MuiImageMarked-root" />
                            </Typography>
                        </Image>

                    </ImageButton>
                    <Button
                        fullWidth
                        color={'error'}
                        variant="outlined"
                        onClick={() => {
                            onDelete(selectedPro.productId);
                        }}
                        disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                        startIcon={<DeleteIcon />}>
                        <Text>remove</Text>
                    </Button>
                </>
            }
        </>
    );
};

export default function RelatedProducts({ productId, onSave }) {
    const {
        userHasAuthenticated,
        store,
        actions,
        products
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [relatedProds, setRelatedProds] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState({});
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedProduct({});
        setOpen(false);
    };

    const handleAdd = () => {

        if (selectedProduct && selectedProduct.productId) {
            handleChange(selectedProduct.productId);
        }

    };


    React.useEffect(() => {

        async function onLoad() {
            try {

                const product = await getProduct(productId);

                const {
                    relatedProducts
                } = product;

                setRelatedProds(relatedProducts || []);

            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [productId]);


    const handleChange = (productId) => {

        let newRelatedProducts = relatedProds;

        if (newRelatedProducts.includes(productId)) {
            let index = newRelatedProducts.indexOf(productId);
            // remove
            newRelatedProducts.splice(index, 1);
            setSelectedProduct({});
            //return;
        } else {
            // add
            newRelatedProducts.push(productId);
        }

        setRelatedProds(newRelatedProducts);
        onSave(newRelatedProducts);
        setOpen(false);
        //setShowSave(true);
    };


    const onProdsChange = (event, obj) => {
        handleChange(obj.productId);
    }

    return (
        <div>
            <Button
                startIcon={<ModeEditIcon />}
                variant="outlined"
                onClick={handleClickOpen}
                style={{ marginBottom: 10 }}
            >
                <Text>Related Products</Text>
            </Button>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}> */}
            <Grid container spacing={1}>
                {relatedProds.map((item, index) =>

                    <Grid item xs={4} sm={3} md={3} key={index}>
                        <RelatedProd
                            key={item}
                            productId={item}
                            onDelete={(id) => {
                                handleChange(id);
                                forceUpdate(); //Force the component to update
                            }}
                        />
                    </Grid>

                )}
            </Grid>

            {/* </Box> */}


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {<Text>Product Search</Text>}
                </DialogTitle>

                <DialogContent>
                    <Box
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                        }}
                    >
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            //onChange={onProdsChange}
                            onChange={(_event, obj) => {
                                setSelectedProduct(obj);
                            }}
                            options={products}
                            getOptionLabel={option => option.productName}
                            //options={products.map((option) => option.productName)}
                            renderInput={(params) => (
                                <TextField
                                    style={{ marginTop: 5 }}
                                    fullWidth
                                    {...params}
                                    label={<Text>Search</Text>}
                                    value={selectedProduct}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <SearchIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}

                                />
                            )}
                        //onChange={ (e, obj) => { console.log('Object', obj);  } }
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}><Text>Cancel</Text></Button>
                    <Button onClick={handleAdd} >
                        <Text>Add</Text>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
