import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useHistory, useParams } from "react-router-dom";
import {
    priceFormat,
    getCusAccountNumber
} from "../../../services/shared";
import { Text } from '../../../services/translator';
import { useAppContext } from "../../../lib/contextLib";

import { Divider } from '@mui/material';
var moment = require('moment'); // require

export default function CustomerCreditsList({ rows }) {
    const history = useHistory();
    const {
        customers
    } = useAppContext();

    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                {rows.map((row, index) => (
                    <>{row.orderCredit > 0 &&
                    <Grid
                        key={row.orderId}
                        container
                        spacing={1}
                    >
                        <Grid item xs={4} sm={4} md={4}>

                            <ListItem>
                                <a href="#" onClick={() => {
                                    history.push(`/a_portal/customers/${row.customerId}`);
                                }}>
                                    <ListItemText primary={<b><Text>Customer Name</Text></b>} secondary={row.fullName + ' - ' + getCusAccountNumber(customers, row.customerId)} />
                                </a>
                            </ListItem>

                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <ListItem>
                            <a href="#" onClick={() => {
                                    history.push(`/a_portal/orders/${row.orderId}`);
                                }}>
                                <ListItemText primary={<b><Text>Order Number</Text></b>} secondary={row.orderNumber} />
                            </a>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<b><Text>Order Date</Text></b>} secondary={moment(row.createdDate).format('L')} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4}>
                            <ListItem>
                                <ListItemText primary={<b><Text>Grand Total</Text></b>} secondary={priceFormat(row.grandTotal)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText style={{ color: 'red' }} primary={<b><Text>Amount Due</Text></b>} secondary={priceFormat(row.orderCredit)} />
                            </ListItem>
                        </Grid>
                        <Divider />
                    </Grid>
                    }</>
                ))}

            </List>

        </div>
    );
}
