import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListGroup from "react-bootstrap/ListGroup";
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../lib/contextLib";
import {
  priceFormat,
  getCusAccountNumber
} from "../../../services/shared";
import { Colors } from '../../../lib/colors';
import { Text } from '../../../services/translator';

var moment = require('moment'); // require

function Row(props) {
  const { store, actions, customers } = useAppContext();
  const history = useHistory();
  const { row, index } = props;
  const [open, setOpen] = React.useState(index == 0 ? true : false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left" style={{ fontSize: 15 }}>
          <a href="#" onClick={() => {
            history.push(`/a_portal/customers/${row.customerId}`);
          }}> {row.fullName} - {getCusAccountNumber(customers,row.customerId)}</a>
        </TableCell>
        <TableCell align="left" style={{ fontSize: 20, color: 'red' }}>{priceFormat(row.totalCredit)}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: Colors.blue50 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 12 }}>

              <Grid item xs={12} sm={12} md={12} >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" gutterBottom component="div">
                      <Text>Order Items</Text>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell><Text>Order Number</Text></TableCell>
                    <TableCell> <Text>Created Date</Text></TableCell>
                    <TableCell > <Text>Grand Total</Text> (CFA)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.credits.map((credit, i) => (
                    <TableRow key={credit.orderId + i}>
                      <TableCell component="th" scope="row">
                        <a href="#" onClick={() => {
                          history.push(`/a_portal/orders/${credit.orderId}`);
                        }}> {credit.orderNumber}</a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(credit.createdDate).format('L')}
                      </TableCell>
                      <TableCell style={{ paddingRight: 40 }}>{priceFormat(credit.grandTotal)} </TableCell>

                    </TableRow>
                  ))}

                </TableBody>
              </Table>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    totalCredit: PropTypes.string.isRequired,
    credits: PropTypes.arrayOf(
      PropTypes.shape({
        createdDate: PropTypes.number.isRequired,
        orderId: PropTypes.string.isRequired,
        orderNumber: PropTypes.string,
        grandTotal: PropTypes.string
      }),
    ),
  }).isRequired,
};

export default function CustomerCreditsTable({ customerCredits }) {

  return (
    <TableContainer component={Paper}>

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow >
            <TableCell/>
            <TableCell style={{ fontSize: 20 }}><Text>Customer</Text></TableCell>
            <TableCell style={{ fontSize: 20 }}><Text>Amount Due</Text></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerCredits.map((row, index) => (
            <Row key={row.customerId} row={row} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}