export const Colors = {
    primary: '#1876D1',
    success: '#2F7C31',
    error: '#D32F2F',
    secondary: '#9C27B0',
    default: '#EBEBEB',
    gold: '#F39200',
    blue: '#2196f3',
    blue50: '#e3f2fd',
    lightBlue: '#03a9f4',
    blueGrey300: '#90a4ae',
    darkBlue: '#35477E',
    navBlue: '#0d47a1',
    white: 'white',
    amber900: '#ff6f00',
    red: 'red',
    green: 'green'
};