import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListGroup from "react-bootstrap/ListGroup";
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SpendingsDialog from "../dialogs/SpendingsDialog";
import { useAppContext } from "../../../lib/contextLib";
import {
  priceFormat
} from "../../../services/shared";
import { Colors } from '../../../lib/colors';
import { Text } from '../../../services/translator';

import {
  getLastOrder,
  saveOrder
} from "../../../services/orders";

var moment = require('moment'); // require

// function createData(createdDate, orderNumber, fullName, grandTotal, shippingTotal, credit) {
//   return {
//     createdDate,
//     orderNumber,
//     fullName,
//     grandTotal,
//     shippingTotal,
//     credit,
//     history: orderItems,
//   };
// }

function Row(props) {
  const history = useHistory();
  const { row, index } = props;
  const [open, setOpen] = React.useState(index == 0 ? true : false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <a href="#" onClick={() => {
            history.push(`/a_portal/orders/${row.orderId}`);
          }}> {row.orderNumber}</a>
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(row.createdDate).format('L')}
        </TableCell>
        <TableCell align="left">{row.fullName}</TableCell>
        <TableCell align="left">{priceFormat(row.grandTotal)}</TableCell>
        <TableCell align="left">
          <Stack>
            {row.cancelled ? <Chip label="Cancelled" color="error" size="small" /> :
              row.closed ? <Chip label="Closed" color="success" size="small" /> :
                row.orderHold ? <Chip label="Hold" size="small" /> :
                  row.orderPlaced ? <Chip label="Placed" color="primary" size="small" /> :
                    <Chip label="Draft" size="small" />}
          </Stack>

        </TableCell>
        {/* <TableCell align="left">{priceFormat(row.orderCredit)}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: Colors.blue50 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>


              <Grid item xs={12} sm={12} md={12} >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" gutterBottom component="div">
                      <Text>Order Items</Text>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right'
                    }}
                  >
                    <SpendingsDialog
                      btColor={'primary'}
                      btTitle={<Text>Order Outputs</Text>}
                      orderId={row.orderId}
                      orderNumber={row.orderNumber}
                      showForm={false}
                    />

                  </Grid>
                </Grid>
              </Grid>


              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell> <Text>Description</Text></TableCell>
                    <TableCell> <Text>Quantity</Text></TableCell>
                    <TableCell align="center"> <Text>Price</Text></TableCell>
                    <TableCell align="right"> <Text>Total Price</Text> (CFA)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderItems.map((historyRow, i) => (
                    <TableRow key={historyRow.quantity + i}>
                      <TableCell component="th" scope="row">
                        {historyRow.product.productDescription}
                      </TableCell>
                      <TableCell>{historyRow.quantity}</TableCell>
                      <TableCell align="right" style={{ paddingRight: 40 }}>{priceFormat(historyRow.price)} </TableCell>
                      <TableCell align="right">
                        {priceFormat(historyRow.total)}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>

              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: 2,
                  // marginLeft: '92%'
                }}
              >
                <Grid item xs={12} sm={7} md={7} >

                </Grid>
                <Grid item xs={12} sm={5} md={5} style={{
                  paddingLeft: 20
                }}>
                  <Grid
                    container
                    spacing={2}

                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <b><Text>Subtotal</Text>:</b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right',
                      paddingRight: 15
                    }}>
                      {priceFormat(row.subtotal)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <b> <Text>Shipping Cost</Text>:</b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right',
                      paddingRight: 15
                    }}>
                      {priceFormat(row.shippingPrice)}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <b><Text>Tax</Text>:</b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right',
                      paddingRight: 15
                    }}>
                      {priceFormat(row.taxIncludedTotal)}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ fontSize: 18 }}>
                      <b><Text>Grand Total</Text>: </b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right',
                      paddingRight: 15
                    }}>
                      {priceFormat(row.grandTotal)}
                    </Grid>

                    {row.orderCredit > 0 &&
                      <>
                        <Grid style={{ color: 'red' }} item xs={12} sm={6} md={6}>
                          <b><Text>Amount Due</Text>:</b>
                        </Grid>
                        <Grid style={{
                          color: 'red',
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'right',
                          paddingRight: 15
                        }} item xs={12} sm={6} md={6}>
                          {priceFormat(row.orderCredit)}
                        </Grid>

                      </>
                    }

                  </Grid>

                </Grid>

              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    grandTotal: PropTypes.number.isRequired,
    fullName: PropTypes.string,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.string.isRequired,
        productDescription: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
      }),
    ),
    createdDate: PropTypes.number.isRequired,
    credit: PropTypes.string,
    shippingTotal: PropTypes.string,
  }).isRequired,
};

export default function CashierTable({ orders }) {
  const history = useHistory();
  const { store, actions, vendor} = useAppContext();

  const createOrder = async () => {
    try {
      actions.onSetIsLoading(true);
      let data = {
        orderNumber: null,
        closed: false,
        cancelled: false,
        delivered: false,
        paid: false,
        hold: false,
        draft: true,
        orderPlaced: false,
        vendorId: vendor.vendorId
      }

      let order = await saveOrder(data);
      actions.onGetOrders(vendor.vendorId);
      actions.onSetIsLoading(false);
      history.push(`/a_portal/orders/${order.orderId}`);

    } catch (err) {
      actions.onSetIsLoading(false);
    }

  };

  return (
    <TableContainer component={Paper} style={{maxHeight: 560, overflow: 'auto'}}>
      <LinkContainer to="#" onClick={() => {
        createOrder();
      }}>
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold"><Text>Create New Order</Text></span>
        </ListGroup.Item>
      </LinkContainer>

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell ><Text>Order Number</Text></TableCell>
            <TableCell ><Text>Order Date</Text></TableCell>
            <TableCell ><Text>Customer</Text></TableCell>
            <TableCell ><Text>Grand Total</Text></TableCell>
            <TableCell ><Text>Order Status</Text></TableCell>
            {/* <TableCell ><Text>Credits</Text></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.sort((a, b) => a.orderNumber < b.orderNumber ? 1 : -1).map((row, index) => (
            <Row key={row.orderId} row={row} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}