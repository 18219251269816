import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useAppContext } from "../../lib/contextLib";
import { Colors } from '../../lib/colors';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SelectFilter({ type, onChange, data, filterList, defualtFilter = 'all' }) {
    const {
        userHasAuthenticated,
        store,
        actions,
        orderStateFilters
    } = useAppContext();

    const [filter, setFilter] = React.useState(defualtFilter);

    function handleInputChange(event) {
        let { name, value } = event.target;
        let query = value.toLowerCase();
        setFilter(value);

        if (query == 'draft') {

            const draft = data.filter(element => {
                return element.draft == true;
            });

            onChange(draft);
        } else if (query == 'placed') {
            const placed = data.filter(element => {
                return element.orderPlaced == true
                    && element.cancelled != true
                    && element.closed != true;
            });

            onChange(placed);
        } else if (query == 'cancelled') {
            const cancelled = data.filter(element => {
                return element.cancelled == true;
            });

            onChange(cancelled);
        } else if (query == 'hold') {
            const hold = data.filter(element => {
                return element.orderHold == true;
            });

            onChange(hold);
        } else if (query == 'closed') {
            const hold = data.filter(element => {
                return element.closed == true;
            });

            onChange(hold);
        } else if (query == 'loan_sp') {
            const loan = data.filter(element => {
                return element.spendingType == 'loan';
            });
            onChange(loan);
        } else if (query == 'vendor_sp') {
            const vendor = data.filter(element => {
                return element.spendingType == 'vendor';
            });
            onChange(vendor);
        } else if (query == 'vehicle_sp') {
            const vehicle = data.filter(element => {
                return element.spendingType =='vehicle';
            });
            onChange(vehicle);
        }else if (query == 'all') {
            onChange(data);
        }
    }

    return (
        
        <FormControl style={{ width: 200 }}>
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="productQuantityType"
                value={filter}
                label="Filter"
                onChange={handleInputChange}
            >
                {filterList.map((type, index) =>
                    <MenuItem key={index} value={type.value}>{type.name}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
