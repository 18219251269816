import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ProductMultiFilesUpload from "../../components/forms/ProductMultiFilesUpload";
import LoaderButton from "../../components/LoaderButton";
import FeedBack from "../../components/shared/FeedBack";
import ProductAttributesTable from "../../components/tables/ProductAttributesTable";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";
import { Text } from '../../services/translator';
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";
import CategorySelect from "../../components/forms/CategorySelect";
import CategoryMultiSelect from "../../components/forms/CategoryMultiSelect";
import RelatedProducts1 from "../../components/forms/RelatedProducts";
import ProductTagsInput from "../../components/forms/ProductTagsInput";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function ProductForm() {
    const file = useRef(null);
    //const { id } = useParams();
    const history = useHistory();
    const [showSave, setShowSave] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories,
        vendors,
        productQuantityTypes,
        productLengthUnits,
        products,
        user,
        vendor,
        companyId
    } = useAppContext();
    const [productTags, setProductTags] = useState([]);
    const [productAttributes, setProductAttributes] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);
    const [originalCategoryIds, setOriginalCategoryIds] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [formInput, setFormInput] = useState({
        categoryId: '',
        productName: '',
        mainImage: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        vendorId: '',
        productCost: 0,
        productPrice: 0,
        productSalesPrice: 0,
        productDateSaleFrom: '',
        productDateSaleTo: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: 0,
        productQuantityType: '',
        availableQuantity: '',
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',
        productStockTracking: false,
        productStockBackorder: false,
        productStockPreorder: false,
        discontinued: false,
        productPosition: '',
        productAttributes: [],
        productTags: [],
        categoryIds: [],
        relatedProducts: [],
        active: true,
        productOrigin: '',
        receivedDate: new Date(),
        blNumber: '',
        quantityAlert: 0
    });
    const [errors, setErrors] = useState({
        productName: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        productPrice: '',
        productSalesPrice: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: '',
        productQuantityType: '',
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',
        productPosition: '',
        productOrigin: '',
        receivedDate: '',
        blNumber: '',
        quantityAlert: ''
    });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.productDescription == '') {
            newErrors.productDescription = 'Product description is required.';
        } else {
            newErrors.productDescription = '';
        }

        // if (formInput.productQuantity == '') {
        //     newErrors.productQuantity = 'Product quantity is required.';
        // } else {
        //     newErrors.productQuantity = '';
        // }

        setErrors(newErrors);

        if (
            newErrors.productDescription.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
        updateErrors(name);

    }



    async function handleSubmit() {
        let isValid = validateForm();

        if (isValid) {
            if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
                alert(
                    `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
                    } MB.`
                );
                return;
            }
            setIsLoading(true);
            actions.onSetIsLoading(true);
            try {
            
                //const mainImage = file.current ? await s3Upload(file.current) : null;
                let response = await saveProduct({ ...formInput, companyId });
                //actions.onGetProductCats();
                //actions.onSetIsLoading(false);
                actions.onGetProducts(vendor.vendorId, companyId);
                setShowFeedback(true);
                setFeedbackMessage('Category was successfully created.');
                setFeedbackType('success');
                history.push(`/a_portal/products/${response.productId}`);
            } catch (e) {
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
        }

    }

    useEffect(() => {
        let newFormInput = { ...formInput };
        newFormInput.vendorId = vendor.vendorId;
        actions.onSetSelected('products');
        setFormInput(newFormInput);
    }, [vendor]);

    return (
        <div className="productForm" >
            {/* <Divider />
            <Divider>DESCRIPTION</Divider>
            <Divider /> */}
            {showSave && <LoaderButton
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    //marginLeft: 8
                }}
                block
                type="submit"
                size="sm"
                variant="primary"
                isLoading={isLoading}
                // disabled={!showSave}
                onClick={() => {
                    handleSubmit();
                }}
            >
                <Text>Save</Text>
            </LoaderButton>}
            <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
            <h2 className="pb-3 mb-3 border-bottom"> {<Text>Create Product</Text>}</h2>
                <Grid container spacing={3}>

                    <Grid container item spacing={3}>

                        <Grid item xs={6}>

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography><Text>Active</Text></Typography>
                                <Switch
                                    checked={formInput.active}
                                    onChange={(e) => {
                                        let newFormInput = {
                                            ...formInput,
                                            ['active']: e.target.checked
                                        };

                                        setFormInput(newFormInput);
                                        setShowSave(true);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>

                        </Grid>

                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                            <Typography><Text>Product Category</Text></Typography>
                            <CategorySelect
                                selectedId={formInput.categoryId}
                                onSave={(item) => {
                                    let newFormInput = {
                                        ...formInput,
                                        ['categoryId']: item.categoryId
                                    };

                                    setFormInput(newFormInput);
                                    setShowSave(true);
                                }}
                            />
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.productDescription !== '' ? true : false}
                            id="outlined-error"
                            name="productDescription"
                            helperText={errors.productDescription}
                            label={<Text>Product Description</Text>}
                            //defaultValue={formInput.productDescription}
                            value={formInput.productDescription}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    {/* <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.productName !== '' ? true : false}
                            id="outlined-error"
                            name="productName"
                            helperText={errors.productName}
                            label={<Text>Product Name</Text>}
                            //defaultValue={formInput.productName}
                            value={formInput.productName}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid> */}

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.productLength !== '' ? true : false}
                            id="outlined-error"
                            name="productLength"
                            type="number"
                            helperText={errors.productLength}
                            label={<Text>Length</Text>}
                            //defaultValue={formInput.productLength}
                            value={formInput.productLength}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    
                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.productWidth !== '' ? true : false}
                            id="outlined-error"
                            name="productWidth"
                            type="number"
                            helperText={errors.productWidth}
                            label={<Text>Width</Text>}
                            //defaultValue={formInput.productWidth}
                            value={formInput.productWidth}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.productHeight !== '' ? true : false}
                            id="outlined-error"
                            name="productHeight"
                            type="number"
                            helperText={errors.productHeight}
                            label={<Text>Height</Text>}
                            // defaultValue={formInput.productHeight}
                            value={formInput.productHeight}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{<Text>Unit Type</Text>}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="productLengthUnit"
                                value={formInput.productLengthUnit}
                                label={<Text>Unit Type</Text>}
                                onChange={handleInputChange}
                            >
                                {productLengthUnits.map((type, index) =>
                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.quantityAlert !== '' ? true : false}
                            id="outlined-error"
                            name="quantityAlert"
                            type="number"
                            helperText={errors.quantityAlert}
                            label={<Text>Quantity Alert</Text>}
                            value={formInput.quantityAlert}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{<Text>Quantity Type</Text>}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="productQuantityType"
                                value={formInput.productQuantityType}
                                label={<Text>Quantity Type</Text>}
                                onChange={handleInputChange}
                            >
                                {productQuantityTypes.map((type, index) =>
                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"><Text>Vendor</Text></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="vendorId"
                                value={formInput.vendorId}
                                label="Category"
                                onChange={handleInputChange}
                            >
                                {vendors.map((vendor, index) =>
                                    <MenuItem key={index} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid> */}

                   
                    {/* <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.slug !== '' ? true : false}
                            id="outlined-error"
                            name="slug"
                            helperText={errors.slug}
                            label="Slug"
                            //defaultValue={formInput.slug}
                            value={formInput.slug}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <small>
                            The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.
                        </small>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={6}>

                        <TextField
                            error={errors.pageTitle !== '' ? true : false}
                            id="outlined-error"
                            name="pageTitle"
                            helperText={errors.pageTitle}
                            label={<Text>Page Title</Text>}
                            //defaultValue={formInput.pageTitle}
                            value={formInput.pageTitle}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={6}>

                        <TextField
                            error={errors.metaDescription !== '' ? true : false}
                            id="outlined-error"
                            name="metaDescription"
                            helperText={errors.metaDescription}
                            label={<Text>Meta Description</Text>}
                            //defaultValue={formInput.metaDescription}
                            value={formInput.metaDescription}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid> */}

                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Typography><Text>Product Category</Text></Typography>
                            <CategorySelect
                                selectedId={formInput.categoryId}
                                onSave={(item) => {
                                    let newFormInput = {
                                        ...formInput,
                                        ['categoryId']: item.categoryId
                                    };

                                    setFormInput(newFormInput);
                                    setShowSave(true);
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                            <CategoryMultiSelect
                                productId={''}
                                onSave={(items) => {
                                    setCategoryIds(items);
                                    let newFormInput = {
                                        ...formInput,
                                        ['categoryIds']: items
                                    };

                                    setFormInput(newFormInput);
                                    setShowSave(true);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    
                    {/* <Grid item xs={12} sm={6} md={6}>

                        <TextField
                            error={errors.productOrigin !== '' ? true : false}
                            id="outlined-error"
                            name="productOrigin"
                            helperText={errors.productOrigin}
                            label="Origin"
                            value={formInput.productOrigin}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={6}>

                        <TextField
                            error={errors.blNumber !== '' ? true : false}
                            id="outlined-error"
                            name="blNumber"
                            helperText={errors.blNumber}
                            label="BL Number"
                            value={formInput.blNumber}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            error={errors.receivedDate !== '' ? true : false}
                            id="date"
                            name="receivedDate"
                            label="Received Date"
                            type="date"
                            value={formInput.receivedDate}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />

                    </Grid> */}

                    {/* <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Label>Product Image</Form.Label>
                        <Form.Control onChange={handleFileChange} type="file" size="lg" />
                    </Form.Group> */}

                </Grid>
            </Box>

            {showSave && <LoaderButton
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    //marginLeft: 8
                }}
                block
                type="submit"
                size="sm"
                variant="primary"
                isLoading={isLoading}
                // disabled={!showSave}
                onClick={() => {
                    handleSubmit();
                }}
            >
                {<Text>Save</Text>}
            </LoaderButton>}

        </div>
    );
}
