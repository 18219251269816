import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InputMask from "react-input-mask";
import PermissionsTable from './tables/PermissionsTable'
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import EmailSettingsForm from "./forms/EmailSettingsForm";
import { onError } from "../../lib/errorLib";
import {
    saveUserPermission,
    getSingleUserPermission,
    updateUserPermission,
    getAllUserPermissions
} from "../../services/user_permissions";

var moment = require('moment'); // require

export default function EmailInfo({ onHandleChange, onSave }) {

    const {
        userHasAuthenticated,
        store,
        actions,
        orderStatus,
        shippingMethods,
        paymentMethods,
        customers,
        permissions,
        states
    } = useAppContext();
    const { id } = useParams();
    const history = useHistory();


    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <h4 className="pb-3 mb-3 border-bottom" style={{marginLeft: 10}}> {'Email Settings'}</h4>
                        <EmailSettingsForm/>
                    </Grid>
                </Grid>

            </List>

        </div>
    );
}
