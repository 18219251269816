import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InputMask from "react-input-mask";
import LoaderButton from "../../../components/LoaderButton";
import PermissionsTable from '../tables/PermissionsTable'
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import {
    updateSetting
} from "../../../services/settings";
import {
    updateVendor
} from "../../../services/vendors";
import {
    updateCustomer
} from "../../../services/customers";
import { Text } from '../../../services/translator';

var moment = require('moment'); // require

export default function OrderInfo({ btColor, btTitle }) {

    const {
        userHasAuthenticated,
        store,
        actions,
        orderStatus,
        shippingMethods,
        paymentMethods,
        customers,
        permissions,
        settings,
        yearsList,
        states,
        vendors
    } = useAppContext();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [showSave, setShowSave] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        currentYear: ''
    });

    const [errors, setErrors] = React.useState({
        permissionName: ''
    });

    React.useEffect(() => {

        setFormInput(settings);
    }, [settings]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.currentYear == '') {
            newErrors.currentYear = 'Field is required.';
        } else {
            newErrors.currentYear = '';
        }

        setErrors(newErrors);

        if (
            newErrors.currentYear.length > 0 || newErrors.currentYear.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {
        let isValid = validateForm();
        setIsLoading(true);
        if (isValid) {
            let response = null;
            try {

                response = await updateSetting({ ...formInput });

                // vendors.forEach(async element => {
                //     await updateVendor(element.vendorId, { ...vendors, lastOrderNumber: 100000 });
                // });

                actions.onSetFeedback(true, 'Business year was successfully updated.', 'success');
                setIsLoading(false);
                setOpen(false);
            } catch (e) {
                onError(e);
                setIsLoading(false);
            }
        }

    }
    return (
        <div style={{ marginLeft: 10 }}>
            <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10 }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    {<Text>New Business Year</Text>}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Business Year</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="currentYear"
                                        value={formInput.currentYear}
                                        label={<Text>Business Year</Text>}
                                        onChange={handleInputChange}
                                    >
                                        {yearsList.map((type, index) =>
                                            <MenuItem key={index} value={type}>{type}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}><Text>Cancel</Text></Button>
                    <LoaderButton
                        type="submit"
                        size="sm"
                        variant="primary"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        <Text>Save</Text>
                    </LoaderButton>
                
                </DialogActions>
            </Dialog>
        </div>
    );
}
