import React, { useState, useEffect, useContext } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useHistory, useParams } from "react-router-dom";

import {
    saveOrder,
    updateOrder,
    getOrder,
    updateOrderItems,
    calculateOrderPrices,
    removeOrderItem,
    updatOrderQuantity,
    placeOrder,
    cancelOrder,
    removeOrder,
    saveTransactions
} from "../../services/orders";
import {
    saveCustomer
} from "../../services/customers";
import { useAppContext } from "../../lib/contextLib";
import { Text } from '../../services/translator';
import { onError } from "../../lib/errorLib";
import OrderDetailsForm from "./OrderDetailsForm";

var moment = require('moment');

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Cashier() {
    const {
        actions,
        userPermissions,
        taxRate,
        companyId,
        vendor,
        customers
    } = useAppContext();

    const { id } = useParams();
    const [formInput, setFormInput] = useState({
        vendorId: '',
        customerId: '',
        shippingMethodId: '',
        paymentMethodId: '',
        orderPlaced: false,
        createdDate: new Date(),
        closedDate: '',
        paidDate: '',
        cancelledDate: '',
        orderNumber: '',
        orderStatus: '',
        shippingStatus: '',
        orderItems: [],
        orderTransactions: [],
        shippingAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: 'Dakar',
            zipCode: '',
            country: 'SN'
        },
        billingAddress: {},
        taxRate: taxRate,
        shippingTax: '',
        shippingDiscount: '',
        shippingPrice: '',
        shippingTaxIncluded: '',
        itemTaxIncluded: '',
        closed: false,
        cancelled: false,
        delivered: false,
        paid: false,
        orderHold: false,
        draft: true,
        fullName: '',
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        note: '',
        comments: [],
        trackingNumber: Date.now(),
        coupon: '',
        paymentMethod: '',
        shippingMethod: '',
        browser: {},
        tags: '',
        weightTotal: '',
        discountTotal: '',
        subtotal: '',
        taxIncludedTotal: '',
        taxTotal: '',
        shippingTotal: '',
        grandTotal: '',
        orderCredit: '',
        active: true,
    });
    const [errors, setErrors] = useState({
        vendorId: '',
        customerId: '',
        shippingMethodId: '',
        paymentMethodId: '',
        createdDate: '',
        closedDate: '',
        paidDate: '',
        cancelledDate: '',
        orderNumber: '',
        orderStatus: '',
        shippingStatus: '',
        orderItems: [],
        orderTransactions: [],
        shippingAddress: {},
        billingAddress: {},
        shippingTax: '',
        shippingDiscount: '',
        shippingPrice: '',
        shippingTaxIncluded: '',
        itemTaxIncluded: '',
        closed: false,
        cancelled: false,
        delivered: false,
        paid: false,
        orderHold: false,
        draft: true,
        fullName: '',
        email: '',
        phoneCountryCode: '',
        phoneNumber: '',
        note: '',
        comments: '',
        trackingNumber: '',
        coupon: '',
        paymentMethod: '',
        shippingMethod: '',
        browser: '',
        tags: '',
        weightTotal: '',
        discountTotal: '',
        subtotal: '',
        taxIncludedTotal: '',
        taxTotal: '',
        shippingTotal: '',
        grandTotal: '',
        active: true,

    });

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        onLoad();

    }, [id]);

    async function onLoad(orderData) {
        try {
            setIsLoading(true);
            let newFormInput = { ...formInput };
            let order = null;
            if (orderData === undefined) {
                order = await getOrder(id);
            } else {
                order = orderData;
            }

            const {
                vendorId,
                customerId,
                shippingMethodId,
                paymentMethodId,
                orderPlaced,
                createdDate,
                closedDate,
                paidDate,
                cancelledDate,
                orderNumber,
                orderStatus,
                shippingStatus,
                orderItems,
                orderTransactions,
                shippingAddress,
                billingAddress,
                //taxRate,
                shippingTax,
                shippingDiscount,
                shippingPrice,
                shippingTaxIncluded,
                itemTaxIncluded,
                closed,
                cancelled,
                delivered,
                paid,
                orderHold,
                draft,
                fullName,
                email,
                phoneCountryCode,
                phoneNumber,
                note,
                comments,
                trackingNumber,
                coupon,
                paymentMethod,
                shippingMethod,
                browser,
                tags,
                weightTotal,
                discountTotal,
                subtotal,
                taxIncludedTotal,
                taxTotal,
                shippingTotal,
                grandTotal,
                orderCredit,
                active,
            } = order;

            newFormInput.vendorId = vendorId;
            newFormInput.customerId = customerId;
            newFormInput.shippingMethodId = shippingMethodId;
            newFormInput.paymentMethodId = paymentMethodId;
            newFormInput.orderPlaced = orderPlaced;
            newFormInput.createdDate = createdDate;
            newFormInput.closedDate = closedDate;
            newFormInput.paidDate = paidDate;
            newFormInput.cancelledDate = cancelledDate;
            newFormInput.orderNumber = orderNumber;
            newFormInput.orderStatus = orderStatus;
            newFormInput.shippingStatus = shippingStatus;
            newFormInput.orderItems = orderItems || [];
            newFormInput.orderTransactions = orderTransactions || [];
            newFormInput.shippingAddress = shippingAddress || newFormInput.shippingAddress;
            newFormInput.billingAddress = billingAddress || newFormInput.billingAddress;
            newFormInput.taxRate = taxRate;
            newFormInput.shippingTax = shippingTax;
            newFormInput.shippingDiscount = shippingDiscount;
            newFormInput.shippingPrice = shippingPrice;
            newFormInput.shippingTaxIncluded = shippingTaxIncluded;
            newFormInput.itemTaxIncluded = itemTaxIncluded;
            newFormInput.closed = closed;
            newFormInput.cancelled = cancelled;
            newFormInput.delivered = delivered;
            newFormInput.paid = paid;
            newFormInput.orderHold = orderHold;
            newFormInput.draft = draft;
            newFormInput.fullName = fullName;
            newFormInput.email = email;
            newFormInput.phoneCountryCode = phoneCountryCode || '';
            newFormInput.phoneNumber = phoneNumber;
            newFormInput.note = note;
            newFormInput.comments = comments || [];
            newFormInput.trackingNumber = trackingNumber;
            newFormInput.coupon = coupon;
            newFormInput.paymentMethod = paymentMethod;
            newFormInput.shippingMethod = shippingMethod;
            newFormInput.browser = browser || {};
            newFormInput.tags = tags;
            newFormInput.weightTotal = weightTotal;
            newFormInput.discountTotal = discountTotal;
            newFormInput.subtotal = subtotal;
            newFormInput.taxIncludedTotal = taxIncludedTotal;
            newFormInput.taxTotal = taxTotal;
            newFormInput.shippingTotal = shippingTotal;
            newFormInput.grandTotal = grandTotal;
            newFormInput.orderCredit = orderCredit;
            newFormInput.active = active;

            setFormInput(newFormInput);

            actions.onGetProducts(vendor.vendorId, companyId);
            setIsLoading(false);

        } catch (e) {
            onError(e);
        }
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        // if (formInput.productName == '') {
        //     newErrors.productName = 'Product name is required.';
        // } else {
        //     newErrors.productName = '';
        // }

        // setErrors(newErrors);

        // if (
        //     newErrors.productName.length > 0
        // ) {
        //     isValid = 0;
        // }

        return isValid;
    }

    async function linkCustomer(data) {
        await actions.onGetCustomers(companyId);

        let customerId = null;
        customers.filter(element => element.customerId === data.customerId)
            .map(element => customerId = element);

        if (customerId == null) {
            let response = await saveCustomer({
                companyId,
                fullName: data.fullName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                totalSpent: 0,
                totalOrders: 0,
                active: true,
            });

            customerId = response;
        }

        return customerId;
    }

    async function handleSubmit(data) {
        let isValid = validateForm();

        if (isValid) {
            //setIsLoading(true);
            try {
                let customerId = null;
                if (data.phoneNumber && data.phoneNumber.length > 0) {
                    let customer = await linkCustomer(data);
                    customerId = customer.customerId;
                    data.fullName = customer.fullName;
                    data.email = customer.email;
                }

                let response = await updateOrder(id, { ...data, customerId });

                actions.onGetOrders(vendor.vendorId);
                actions.onGetCustomers(companyId);
                actions.onSetFeedback(true, 'Order was successfully updated.', 'success');
                onLoad(response.data);
            } catch (e) {
                onLoad();
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
        }

    }


    return (
        <div className="Orders">
            {userPermissions.admin || userPermissions.cashier_read || userPermissions.cashier_write ? <>

                <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >

                        <Tab label={<Text>Order Details</Text>} {...a11yProps(0)} />
                        <Tab label={<Text>Payments and Invoices</Text>} {...a11yProps(1)} />
                        <Tab label={<Text>Returned Items</Text>} {...a11yProps(2)} />
                        <Tab label={<Text>Shipments</Text>} {...a11yProps(3)} />

                    </Tabs>

                    <StyledTabPanel value={value} index={0} dir={theme.direction}>
                        <Grid container item spacing={3}>
                            <OrderDetailsForm 
                                formData = {formInput}
                                onHandleSubmit = { (data) => {
                                    handleSubmit(data);
                                }}
                                onSetIsLoading = { (load) => {
                                    setIsLoading(load);
                                }}
                                isLoading={isLoading}
                            />
                        </Grid>
                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={1} dir={theme.direction}>
                        {'Payments and Invoices'}
                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={2} dir={theme.direction}>
                        {'Returned Items'}
                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={3} dir={theme.direction}>
                        {'Shipments'}
                    </StyledTabPanel>

                </Box>

            </> : null}
        </div>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;`,
);

