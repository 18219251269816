import * as React from 'react';
import { useAppContext } from "../../lib/contextLib";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoaderButton from "../LoaderButton";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    calculateDispatchQty
} from "../../services/products";
import {
    removeApproval
} from "../../services/approvals";
import { onError } from "../../lib/errorLib";

import { Colors } from '../../lib/colors';

export default function ProductDispatchTable({ rows, onChange, onSave, quantity }) {
    const [open, setOpen] = React.useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        vendors,
        productDispatchStatus,
        userPermissions,
        user
    } = useAppContext();
    const [productDispatch, setProductDispatch] = React.useState([]);
    const [quantityLeft, setQuantityLeft] = React.useState(0);

    const handleRemove = async (data) => {

        if (window.confirm("Are you sure you want to remove this record?") != true) {
            return;
        }

        let newProductDispatch = [...productDispatch];

        const index = newProductDispatch.indexOf(data);
        let dispatchRecord = newProductDispatch[index];

        if (index > -1) {
            newProductDispatch.splice(index, 1);

            if (dispatchRecord.vendorId != '' && dispatchRecord.vendorId != null) {
                let approvalId = dispatchRecord.vendorId + dispatchRecord.productDispatchId;
                await removeApproval(approvalId);
            }

        }

        setProductDispatch(newProductDispatch);
        onSave(newProductDispatch);
        //onChange(newProductDispatch);

    };

    function handleInputChange(event, data) {

        let { name, value } = event.target;
        let newProductDispatch = [...productDispatch];

        const index = newProductDispatch.indexOf(data);

        let row = newProductDispatch[index];
        row.lastUpdatedBy = user.userId;

        let newRow = {
            ...row,
            [name]: value
        };

        newProductDispatch[index] = newRow;

        let qtyLeft = calculateDispatchQty(newProductDispatch);

        if (qtyLeft > quantity) {
            return onError('Quantity is more than what we have left.');
        }
        let total = quantity - qtyLeft;
        setQuantityLeft(total);
        setProductDispatch(newProductDispatch);
        onChange(newProductDispatch);
    }

    const addRow = async () => {
        let newProductDispatch = [...productDispatch];
        let data = {

            productDispatchId: 'unique' + Date.now(),
            vendorId: '',
            blNumber: 'BL-' + Date.now(),
            soldQuantity: 0,
            quantity: '',
            dispatchDate: Date.now(),
            status: 'processing',
            createdBy: user.userId,
            lastUpdatedBy: user.userId,
        };

        newProductDispatch.push(data);
        setProductDispatch(newProductDispatch);
        onSave(newProductDispatch);
    };

    React.useEffect(() => {
        setProductDispatch(rows);
    }, [rows]);

    React.useEffect(() => {
        let qtyLeft = calculateDispatchQty(productDispatch);
        let total = quantity - qtyLeft;
        setQuantityLeft(total);
    }, [productDispatch]);

    return (
        <>
            <Chip
                style={{
                    marginBottom: 10,
                }}
                label={'Available Quantity: ' + quantityLeft}
                color="success" variant="outlined"
            />
            <Grid container spacing={1}>

                {productDispatch.map((data, index) =>

                    <>
                        <Grid item xs={12} sm={3} md={3} key={index + 3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="vendorId"
                                    value={data.vendorId}
                                    label="Category"
                                    onChange={(event) => {
                                        handleInputChange(event, data);
                                    }}
                                //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                >
                                    {vendors.map((vendor, index) =>
                                        <MenuItem
                                            key={index}
                                            value={vendor.vendorId}
                                            disabled={data.status === 'shipped' ? true : false}
                                        >{vendor.vendorName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>

                            <TextField
                                id="outlined-error"
                                name="blNumber"
                                label="BL Number"
                                value={data.blNumber}
                                onChange={(event) => {
                                    handleInputChange(event, data);
                                }}
                                //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={2} md={2}>

                            <TextField
                                id="outlined-error"
                                name="quantity"
                                type="number"
                                // helperText={errors.productQuantity}
                                label="Quantity"
                                value={data.quantity}
                                onChange={(event) => {
                                    handleInputChange(event, data);
                                }}
                                //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={2} md={2}>
                            <TextField
                                id="date"
                                name="dispatchDate"
                                label="Dispatch Date"
                                type="date"
                                value={data.dispatchDate}
                                onChange={(event) => {
                                    handleInputChange(event, data);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={2} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="status"
                                    value={data.status}
                                    label="Status"
                                    onChange={(event) => {
                                        handleInputChange(event, data);
                                    }}
                                //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                >
                                    {productDispatchStatus.map((status, index) =>
                                        <MenuItem
                                            key={index}
                                            value={status.value}
                                            disabled={data.status === 'shipped'? true: false}
                                        >{status.name}</MenuItem>
                                    )}
                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                            <IconButton
                                style={{
                                    marginTop: 10,
                                    // marginBottom: 10,
                                }}
                                color="error"
                                aria-label="remove attribute"
                                component="span"
                                onClick={() => {
                                    handleRemove(data);
                                }}
                            // disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        </Grid>
                    </>

                )}
                <Grid item xs={12} sm={12} md={12}>
                    {productDispatch.length <= 0 &&
                        <p>No dispatch in progress.</p>
                    }
                </Grid>



                <LoaderButton
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                    // block
                    type="submit"
                    size="sm"
                    variant="primary"
                    style={{backgroundColor: Colors.navBlue}}

                    //isLoading={isLoading}
                    onClick={() => {
                        addRow();
                    }}
                    //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                >
                    {productDispatch.length <= 0 ? 'DISPATCH PRODUCT' : 'NEW ROW'}
                </LoaderButton>

            </Grid>

        </>
    );
}
