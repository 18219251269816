import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useHistory, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import { LinkContainer } from "react-router-bootstrap";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Divider } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {
    priceFormat,
    getVendorName,
    getBankAccountName
} from "../../services/shared";
import {
    updateDeposit
} from "../../services/deposits";

import {
    removeApproval
} from "../../services/approvals";

import { Text } from '../../services/translator';
import ProductCats from "../../containers/product_categories/ProductCats";
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Colors } from '../../lib/colors';
import LoaderButton from "../../components/LoaderButton";

var moment = require('moment');

export default function VendorDeposits({ allData, pageTitle }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        userPermissions,
        bankAccounts,
        vendors,
        vendor,
        user,
        companyId,
        companyYear
    } = useAppContext();
    const [data, setData] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        setData(allData);
    }, [allData]);

    async function handleSubmit(status, data) {
        try {
            let newData = { ...data };
            if (window.confirm("Are you sure?") != true) {
                return;
            }

            setSubmitting(true);
            if (status === 'a') {
                newData.depositStatus = 'a';
                newData.approvedBy = user.userId;
            } else if (status === 'd') {
                newData.depositStatus = 'd';
                newData.approvedBy = user.userId;
            }
            await updateDeposit(data.depositId, { ...newData });

            actions.onGetApprovals(companyId, companyYear);
            setSubmitting(true);
            actions.onGetCashierData(vendor.vendorId)

        } catch (err) {
            setSubmitting(true);
        }
    }

    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <TableFilter
                    onChange={(data) => {
                        setData(data);
                    }}
                    type={'deposits'}
                    data={allData}
                />
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 335,
                    '& ul': { padding: 0 },
                }}>

                    <Divider />
                    {userPermissions.admin || userPermissions.bank_deposit_approval ? <>
                        {data.map((dep, index) => (
                            <ListItem alignItems="flex-start" key={dep.skId}>
                                <ListItemText
                                    primary={<b style={{ color: Colors.gold }}>{getVendorName(vendors, dep.vendorId)}</b>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <b><Text>Amount</Text>:</b>
                                            </Typography>
                                            {' ' + priceFormat(dep.approvalData.depositedAmount)}
                                            <br />
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <b><Text>Deposited Date</Text>:</b>
                                            </Typography>
                                            {' ' + moment(dep.approvalData.depositedDate).format('L')}
                                            <br />
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <b><Text>Deposited To</Text>:</b>
                                            </Typography>
                                            {' ' + getBankAccountName(bankAccounts, dep.approvalData.accountId)}
                                            <br />

                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <b><Text>Deposited By</Text>:</b>
                                            </Typography>
                                            {' ' + dep.approvalData.depositedBy}
                                            <br />
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <b><Text>Note</Text>:</b>
                                            </Typography>
                                            {' ' + dep.approvalData.depositNote}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemAvatar>
                                    <LoaderButton
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                        type="submit"
                                        size="sm"
                                        variant="primary"
                                        isLoading={submitting}
                                        onClick={() => {
                                            handleSubmit('a', dep.approvalData);
                                        }}
                                    >
                                        <Text>Approve</Text>
                                    </LoaderButton>
                                    <LoaderButton
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                            marginLeft: 5
                                        }}
                                        type="submit"
                                        size="sm"
                                        variant="warning"
                                        isLoading={submitting}
                                        onClick={() => {
                                            handleSubmit('d', dep.approvalData);
                                        }}
                                    >
                                        <Text>Decline</Text>
                                    </LoaderButton>
                                </ListItemAvatar>
                            </ListItem>
                        ))}
                    </> : null}
                    {data && data.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }
                </List>

            </Paper>
        </Box>
    </div>
    );
}
