import { API } from "aws-amplify";

/**
* Function for getting all user prmissions 
* @param {function} getAllUserPermissions 
*/
export const getAllUserPermissions = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/user_permissions");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a user prmission
* @param {function} getSingleUserPermission 
*/
export const getSingleUserPermission = async (skId) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/user_permissions/"+skId);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a user permission
* @param {object} data  
* @param {function} saveUserPermission 
*/
export const saveUserPermission = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/user_permissions", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a user permission
* @param {function} updateUserPermission 
*/
export const updateUserPermission = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/user_permissions/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for checking if user has permission
* @param {function} userHasPermission 
*/
export const userHasPermission = async (permission, userPermissions) => {

    let response = false;

    try {

        let per = userPermissions.filter((item) => item.permissionValue == permission);
        if (per.length > 0) {
            return true;
        }
        
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for checking if user has permission 
* New Permissions need to be added here to make the permission work.
* @param {function} getLoggedInUserPermissions 
*/
export const getLoggedInUserPermissions = async (userPermissions) => {

    let response = {
        admin: false,
        products_read: false,
        products_write: false,
        orders_read: false,
        orders_write: false,
        customers_read: false,
        customers_write: false,
        vendors_read: false,
        vendors_write: false,
        users_read: false,
        users_write: false,
        user_roles_read: false,
        user_roles_write: false,
        settings_read: false,
        settings_write: false,
        receive_products: false,
        suppliers_read: false,
        suppliers_write: false,
        product_dispatch: false,
        available_products_view: false,
        all_products_view: false,
        vendor_products_view: false,
        cashier_read: false,
        cashier_write: false,
        deposit_read: false,
        deposit_write: false,
        approval_access: false,
        bank_deposit_access: false,
        vendor_switch: false
    };

    try {
        response.admin = await userHasPermission('admin', userPermissions);
        
        response.products_read = await userHasPermission('products_read', userPermissions);
        response.products_write = await userHasPermission('products_write', userPermissions);
        
        response.orders_read = await userHasPermission('orders_read', userPermissions);
        response.orders_write = await userHasPermission('orders_write', userPermissions);
       
        response.customers_read = await userHasPermission('customers_read', userPermissions);
        response.customers_write = await userHasPermission('customers_write', userPermissions);
        
        response.vendors_read = await userHasPermission('vendors_read', userPermissions);
        response.vendors_write = await userHasPermission('vendors_write', userPermissions);
        
        response.users_read = await userHasPermission('users_read', userPermissions);
        response.users_write = await userHasPermission('users_write', userPermissions);
        
        response.user_roles_read = await userHasPermission('user_roles_read', userPermissions);
        response.user_roles_write = await userHasPermission('user_roles_write', userPermissions);
        
        response.settings_read = await userHasPermission('settings_read', userPermissions);
        response.settings_write = await userHasPermission('settings_write', userPermissions);

        response.receive_products = await userHasPermission('receive_products', userPermissions);

        response.suppliers_read = await userHasPermission('suppliers_read', userPermissions);
        response.suppliers_write = await userHasPermission('suppliers_write', userPermissions);
        
        response.product_dispatch = await userHasPermission('product_dispatch', userPermissions);
        response.available_products_view = await userHasPermission('available_products_view', userPermissions);
        response.all_products_view = await userHasPermission('all_products_view', userPermissions);
        response.vendor_products_view = await userHasPermission('vendor_products_view', userPermissions);

        response.cashier_read = await userHasPermission('cashier_read', userPermissions);
        response.cashier_write = await userHasPermission('cashier_write', userPermissions);

        response.deposit_read = await userHasPermission('deposit_read', userPermissions);
        response.deposit_write = await userHasPermission('deposit_write', userPermissions);

        response.approval_access = await userHasPermission('approval_access', userPermissions);
        response.bank_deposit_approval = await userHasPermission('bank_deposit_approval', userPermissions);

        response.vendor_switch = await userHasPermission('vendor_switch', userPermissions);

        
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};